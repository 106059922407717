import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CampaignModel } from '@models/marketing-campaign.model';

export const FEATURE_KEY = 'marketingCampaign';
export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error: string | null;
  currentCampaign: CampaignModel | null;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  currentCampaign: null,
});
