import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, noop, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { KEY_USER_LOGGED_IN } from '@core/constants/keys';
import { Router } from '@angular/router';
import { PATH_AUTH, PATH_LOGIN } from '@core/constants/path';
import { Environment } from '@models/auth-session.model';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this.authService.redirectToLogin(err);
        return throwError(err);
      })
    );
  }
}
