import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { AuthHeaderInterceptor } from './auth.interceptor';
import { LanguageInterceptor } from './language.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { NoCacheHeadersInterceptor } from './no-cache-headers.interceptor';
import { BackendConnectionInterceptor } from '@core/interceptors/backend-connection.interceptor';
import { EnvironmentActivateGuard } from '@core/guards/environment-activate-guard.service';
import { BadRequestInterceptor } from '@core/interceptors/bad.request.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders: any = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BackendConnectionInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BadRequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NoCacheHeadersInterceptor,
    multi: true,
  },
  EnvironmentActivateGuard,
];
