import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import {
  TemplateModel,
  TemplateTransactionsByIbanRequestModel,
} from '@models/template.model';

export class LoadTemplatesByIbanRequestAction implements Action {
  readonly type = ActionTypes.LOAD_TEMPLATES_BY_IBAN_REQUEST;
  constructor(public payload: TemplateTransactionsByIbanRequestModel) {}
}

export class LoadTemplatesByIbanSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TEMPLATES_BY_IBAN_SUCCESS;
  constructor(public payload: TemplateModel[]) {}
}

export class LoadTemplatesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_TEMPLATES_REQUEST;
  constructor(public payload: string | undefined = undefined) {}
}

export class LoadTemplatesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TEMPLATES_SUCCESS;

  constructor(public payload: TemplateModel[]) {}
}

export class DeleteTemplateRequestAction implements Action {
  readonly type = ActionTypes.DELETE_TEMPLATE_REQUEST;
  constructor(public templateId: string) {}
}

export class DeleteTemplateSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_TEMPLATE_SUCCESS;

  constructor(public payload: any) {}
}

export class EditTemplateRequestAction implements Action {
  readonly type = ActionTypes.EDIT_TEMPLATE_REQUEST;
  constructor(public payload: TemplateModel) {}
}

export class EditTemplateSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_TEMPLATE_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateNewTemplateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_NEW_TEMPLATE_REQUEST;
  constructor(public payload: TemplateModel) {}
}

export class CreateNewTemplateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_NEW_TEMPLATE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class SetTemplateData {
  readonly type = ActionTypes.SET_TEMPLATE_DATA;

  constructor(public payload: TemplateModel | undefined) {}
}

export class ClearErrorRequestAction implements Action {
  readonly type = ActionTypes.CLEAR_ERROR;
}
