import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'wmp-web-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss'],
})
export class StyleGuideComponent implements AfterViewInit {
  public links: { title: string; path: string }[] = [
    { title: 'Double Dragon', path: '/' },
    { title: 'Street Fighter II', path: '/#street-fighter-ii' },
    { title: 'Mortal Kombat', path: '/#mortal-kombat' },
  ];

  public demoInputFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public ngAfterViewInit(): void {
    this.demoInputFormControl.markAsTouched();
  }
}
