export const SET_LANGUAGE_REQUEST = '[Navigation] Set Language Request';
export const SET_LANGUAGE_SUCCESS = '[Navigation] Set Language Success';
export const SET_FAILURE = '[Navigation] Set Failure';
export const LOAD_MASTER_NUMBERS_REQUEST =
  '[Navigation] Load Master Number Request';
export const LOAD_MASTER_NUMBERS_SUCCESS =
  '[Navigation] Load Master Number Success';
export const SET_MASTER_NUMBER_REQUEST =
  '[Navigation] Set Master Number Request';
export const SET_MASTER_NUMBER_SUCCESS =
  '[Navigation] Set Master Number Success';
export const IS_DEMO_USER_REQUEST = '[Navigation] Is Demo User Request';
export const IS_DEMO_USER_SUCCESS = '[Navigation] Is Demo User Success';
export const GET_SELECTED_MASTER_NUMBER_REQUEST =
  '[Navigation] Get Selected Master Number Request';
export const GET_SELECTED_MASTER_NUMBER_SUCCESS =
  '[Navigation] Get Selected Master Number Success';
export const GET_SELECTED_LANGUAGE_REQUEST =
  '[Navigation] Get Selected Language Request';
export const GET_SELECTED_LANGUAGE_SUCCESS =
  '[Navigation] Get Selected Language Success';
export const LOAD_ANNOUNCEMENTS_REQUEST =
  '[Navigation] Load Announcements Request';
export const LOAD_ANNOUNCEMENTS_FAILURE =
  '[Navigation] Load Announcements Failure';
export const LOAD_ANNOUNCEMENTS_SUCCESS =
  '[Navigation] Load Announcements Success';
