<ul>
  <li *ngFor="let language of languages">
    <button
      class="tertiary"
      [id]="'languageLabel_' + language.label"
      [attr.data-test-id]="'switchLanguage_' + language.label"
      [ngClass]="getButtonClass(language.locale)"
      (click)="setLanguage(language.locale)"
    >
      {{ language.label }}
    </button>
  </li>
</ul>
