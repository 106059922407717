import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  minDate: any = { year: 1900, month: 1, day: 1 };
  maxDate: any;
  today: NgbDateStruct = this.getFormattedDate(new Date());

  constructor(public datePipe: DatePipe) {}

  public getFormattedDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  public isTheCurrentDateInFirstQuarter() {
    return new Date().getMonth() < 3;
  }

  public getDocumentDateFormat(date: any) {
    return this.datePipe.transform(date, 'yyyyMMdd');
  }
}
