export const LOAD_REQUEST = '[Documents] Load Request';
export const LOAD_FAILURE = '[Documents] Load Failure';
export const LOAD_SUCCESS = '[Documents] Load Success';
export const LOAD_MORE_REQUEST = '[Documents] Load More Request';
export const LOAD_MORE_SUCCESS = '[Documents] Load More Success';
export const LOAD_SIGNING_REQUEST = '[Documents] Load Signing Request';
export const LOAD_SIGNING_FAILURE = '[Documents] Load Signing Failure';
export const LOAD_SIGNING_SUCCESS = '[Documents] Load Signing Success';
export const LOAD_MORE_SIGNING_REQUEST =
  '[Documents] Load More Signing Request';
export const LOAD_MORE_SIGNING_SUCCESS =
  '[Documents] Load More Signing Success';
export const UPDATE_DOCUMENTS = '[Documents] Update';
export const LOAD_UPLOAD_HISTORY_REQUEST =
  '[Documents] Load Upload History Request';
export const LOAD_UPLOAD_HISTORY_SUCCESS =
  '[Documents] Load Upload History Success';
export const LOAD_MORE_UPLOAD_HISTORY_REQUEST =
  '[Documents] Load More Upload History Request';
export const LOAD_MORE_UPLOAD_HISTORY_SUCCESS =
  '[Documents] Load More Upload History Success';
export const UPDATE_FILTER = '[Documents] Update Filter';
export const SET_ITEMS_PER_PAGE = '[Documents] Set Items per Page';
export const SET_FILTER = '[Documents] Set Filter';
export const REQUEST_UNREAD_COUNTER = '[Documents] Request unread counter';
export const UPDATE_UNREAD_COUNTER = '[Documents] Update unread counter';
export const FAILURE_UNREAD_COUNTER = '[Documents] Failure unread counter';
export const MARK_AS_READ = '[Documents] Mark as read';
export const MARK_AS_READ_SUCCESS = '[Documents] Mark as read Success';
export const MARK_AS_READ_FAILURE = '[Documents] Mark as read Failure';
export const DOWNLOAD = '[Documents] Download';
export const DOWNLOAD_SUCCESS = '[Documents] Download Success';
export const DOWNLOAD_FAILURE = '[Documents] Download Failure';
export const DOWNLOAD_SIGNING = '[Documents] Download Signing';
export const DOWNLOAD_SIGNING_SUCCESS = '[Documents] Download Signing Success';
export const DOWNLOAD_SIGNING_FAILURE = '[Documents] Download Signing Failure';
export const SIGN_DOCUMENT_REQUEST = '[Documents] Sign Document';
export const SIGN_DOCUMENT_SUCCESS = '[Documents] Sign Document Success';
export const SIGN_DOCUMENT_FAILURE = '[Documents] Sign Document Failure';
export const DECLINE_DOCUMENT_REQUEST = '[Documents] Decline Document';
export const DECLINE_DOCUMENT_SUCCESS = '[Documents] Decline Document Success';
export const DECLINE_DOCUMENT_FAILURE = '[Documents] Decline Document Failure';
export const MERGE = '[Documents] Merge';
export const RESET_DOCUMENTS = '[Documents] Reset';
export const GET_SEC_SIGN_ID_FOR_DOCUMENT_SIGNING_REQUEST =
  '[Documents] Get SecSign Id For Document Signing Request';
export const SET_SEC_SIGN_STATUS = '[Documents] Set SecSign Status';
export const PROCESS_DOCUMENT_SIGNING_ACTION_SUCCESS =
  '[Documents] Processing Document Signing Request Success';
export const NEW_SIGNING_REQUESTS_COUNT_REQUEST =
  '[Documents] New Signing Requests Count Request';
export const NEW_SIGNING_REQUESTS_COUNT_SUCCESS =
  '[Documents] New Signing Requests Count Success';
export const LOAD_DOCUMENTS_ACCOUNTS_REQUEST =
  '[Documents] Load Documents Accounts Request';
export const LOAD_DOCUMENTS_ACCOUNTS_SUCCESS =
  '[Documents] Load Documents Accounts Success';
