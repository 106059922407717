import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AGGREGATED_PORTFOLIO_CODE } from '@core/constants/defaults';
import { PortfolioAdapterService } from '@core/services/portfolio/portfolio-adapter.service';
import { PerformanceDateRange } from '@models/performance.model';
import { AllocationPortfolioDto } from '@models/portfolio/allocation.model';
import {
  OverviewData,
  OverviewPortfoliosDto,
  PerformancePortfolioDto,
} from '@models/portfolio/portfolio.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureService } from '../feature.service';

@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  constructor(
    private http: HttpClient,
    private adapter: PortfolioAdapterService,
    private featureService: FeatureService
  ) {}

  private _stateOverView: OverviewData = <OverviewData>{};
  public getOverViewState() {
    return this._stateOverView;
  }

  public setOverViewState(data: OverviewData) {
    this._stateOverView = data;
  }

  getOverViewData(param: PerformanceDateRange): Observable<OverviewData> {
    return forkJoin({
      overviewData: this.getPortfolioList(),
      performceData: this.getAggregationPerformances(param),
      allocationData: this.getAggregationAllocationAssets(),
    });
  }

  getPortfolioList(): Observable<OverviewPortfoliosDto> {
    return this.http.get<OverviewPortfoliosDto>('/api/v1-legacy/portfolios');
  }

  getAggregationData(): Observable<any> {
    return this.http.get<any>(`/public/api/v1/portfoliosummary/aggregation`);
  }

  getAggregationAllocationAssets(): Observable<AllocationPortfolioDto> {
    return this.http.get<AllocationPortfolioDto>(
      `/api/v1-legacy/portfolios/${AGGREGATED_PORTFOLIO_CODE}/allocations`
    );
  }

  getAggregationPerformances(
    param: PerformanceDateRange
  ): Observable<PerformancePortfolioDto[]> {
    return this.http.get<PerformancePortfolioDto[]>(
      `/api/v1-legacy/portfolios/${AGGREGATED_PORTFOLIO_CODE}/performance?date-from=${param.dateFrom}&date-to=${param.dateTo}`
    );
  }

  getAllPortfoliosAllocationData(): Observable<any> {
    return this.http.get<any>(
      `/public/api/v1/portfoliosummary?orderBy=userOrder`
    );
  }

  getAggregationRegions(): Observable<any> {
    return this.http
      .get<any>(
        `/public/api/v1/portfoliosummary/aggregation/allocations-region`
      )
      .pipe(
        map((list) => {
          return list.allocations;
        })
      );
  }

  getAggregationCurrencies(): Observable<any> {
    return this.http
      .get<any>(
        `/public/api/v1/portfoliosummary/aggregation/allocations-currency`
      )
      .pipe(
        map((list) => {
          return list.allocations.map(this.adapter.mapData);
        })
      );
  }

  getAggregationSectors(): Observable<any> {
    return this.http
      .get<any>(
        `/public/api/v1/portfoliosummary/aggregation/allocations-sectors`
      )
      .pipe(
        map((list) => {
          return list.allocations.map(this.adapter.mapData);
        })
      );
  }

  getAggregationTransactions(payload: any): Observable<any> {
    let params = '';
    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.from) {
      const parameter = `from=${payload.from}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `fromDate=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `toDate=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountFrom) {
      const parameter = `fromAmount=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `toAmount=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.type) {
      const parameter = `type=${payload.type}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get<any>(
      `/public/api/v1/portfoliosummary/aggregation/transactions${params}`
    );
  }

  getAggregationTransactionsForExport(payload: any): Observable<any> {
    let params = '';
    if (payload.timePeriodFrom) {
      const parameter = `timePeriodFrom=${payload.timePeriodFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.timePeriodTo) {
      const parameter = `timePeriodTo=${payload.timePeriodTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }

    return this.http.get(
      `/public/api/v1/portfoliosummary/aggregation/portfolio-transaction-export${params}`,
      {
        responseType: 'text',
      }
    );
  }

  getTransactionDetails(param: any): Observable<any> {
    return this.http.get<any>(
      `/public/api/v1/portfoliosummary/aggregation/transactions/${param.transactionId}`
    );
  }

  getAggregationPositions(payload: any): Observable<any> {
    let params = '';
    if (payload) {
      if (payload.search) {
        const parameter = `search=${payload.search}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      }
    }
    return this.http.get<any>(
      `/public/api/v1/portfoliosummary/aggregation/positions${params}`
    );
  }

  getAggregatedValuation(payload: any): Observable<any> {
    let params = '';
    if (payload.fromDate) {
      const parameter = `fromDate=${payload.fromDate}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.toDate) {
      const parameter = `toDate=${payload.toDate}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.granularity) {
      const parameter = `granularity=${payload.granularity}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get(
      `/public/api/v1/portfoliosummary/aggregation/valuation-chart${params}`
    );
  }
}
