import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wmp-2fa-confirm-operation-modal',
  templateUrl: './2fa-confirm-operation-modal.component.html',
  styleUrls: ['./2fa-confirm-operation-modal.component.scss'],
})
export class TwoFaConfirmOperationModalComponent {
  @Output()
  public responseEmitter: EventEmitter<boolean> = new EventEmitter();

  public cancelAuthSession(): void {
    this.responseEmitter.emit(false);
  }
}
