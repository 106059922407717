export const KEY_CURRENT_LANGUAGE = 'currentLanguage';
export const KEY_USER_LOGGED_IN = 'userLoggedIn';
export const KEY_DEFAULT_AMOUNT = 'default_amount';
export const KEY_LAST_CLOSED_CAMPAIGN_ID = 'lastClosedCampaignId';
export const KEY_HTTP_LANGUAGE_HEADER = 'x-bego-locale';
export const KEY_IS_TIMEOUT = 'isTimeout';
export const KEY_ALL_PORTFOLIOS = 'all';
export const KEY_ONBOARDING_NAME = 'onboardingName';
export const KEY_ONBOARDING_PASS_CHANGE = 'onboardingPassChange';
export const KEY_SECSIGN_SESSION = 'sessionId';
