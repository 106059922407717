import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectionCheckService } from '@core/services/connection-check.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss'],
})
export class NoConnectionComponent implements OnInit, OnDestroy {
  @Input()
  public connectedBackend = true;

  @Input()
  public connectedInternet = true;

  private backendSubscription?: Subscription;
  private internetSubscription?: Subscription;

  constructor(private connectionCheckService: ConnectionCheckService) {}

  ngOnInit(): void {
    this.backendSubscription =
      this.connectionCheckService.connectedBackendServices$.subscribe(
        (connected: boolean) => {
          this.connectedBackend = connected;
        }
      );

    this.internetSubscription =
      this.connectionCheckService.connectedInternet$.subscribe(
        (isConnected: boolean) => {
          this.connectedInternet = isConnected;
        }
      );
  }

  ngOnDestroy(): void {
    this.backendSubscription?.unsubscribe();
    this.internetSubscription?.unsubscribe();
  }
}
