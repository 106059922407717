import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PATH_AUTH, PATH_LOGIN } from '@core/constants/path';
import { AuthService } from '@core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return true;
    // As long as the auth service is showing to portfolio summary and not fip, this has to be commented out.
    // const isAllowed = this.authService.getUserLoggedIn() || this.toBoolean(sessionStorage.getItem('isRmpLoginSuccessful'));
    // if(!isAllowed) {
    //   this.router.navigateByUrl(PATH_AUTH + PATH_LOGIN);
    // }
    // return isAllowed;
  }

  toBoolean(value: any): boolean {
    return value ? value.toString().toLowerCase().trim() === 'true' : false;
  }
}
