import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { RmpClientModel, RmpDocument } from '@models/rmp.model';

export class LoadClientsRequest implements Action {
  readonly type = ActionTypes.LOAD_CLIENTS_REQUEST;
  constructor(public payload: { search: string; username: string }) {}
}

export class LoadClientsSuccess implements Action {
  readonly type = ActionTypes.LOAD_CLIENTS_SUCCESS;
  constructor(public payload: RmpClientModel[]) {}
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadRmpDocumentsRequest implements Action {
  readonly type = ActionTypes.LOAD_RMP_DOCUMENTS_REQUEST;
}

export class LoadRmpDocumentsSuccess implements Action {
  readonly type = ActionTypes.LOAD_RMP_DOCUMENTS_SUCCESS;
  constructor(public payload: RmpDocument[]) {}
}
