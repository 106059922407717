import { Injectable, ElementRef, Input, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SecureLayoutService {
  @Input()
  public navigationElementRef?: ElementRef;

  @Input()
  public routerOutletElementRef?: ElementRef;

  public get sectionHeights(): {
    navigationHeight: number;
    navigationOffsetTop: number;
    routerComponentHeight: number;
    routerComponentOffsetTop: number;
  } {
    return {
      navigationHeight: this.navigationElementRef
        ? this.navigationElementRef.nativeElement.clientHeight
        : -1,
      navigationOffsetTop: this.navigationElementRef
        ? this.navigationElementRef.nativeElement.offsetTop
        : -1,
      routerComponentHeight: this.routerOutletElementRef
        ? this.routerOutletElementRef.nativeElement.nextSibling.clientHeight
        : -1,
      routerComponentOffsetTop: this.routerOutletElementRef
        ? this.routerOutletElementRef.nativeElement.nextSibling.offsetTop
        : -1,
    };
  }

  public equaliseNavigationAndCardHeights(
    renderer: Renderer2,
    noResultsElementRef?: ElementRef
  ): void {
    if (noResultsElementRef) {
      renderer.setStyle(noResultsElementRef.nativeElement, 'height', 'auto');
      const sectionHeights = this.sectionHeights;
      const normalisedRouterHeight =
        sectionHeights.routerComponentHeight +
        sectionHeights.routerComponentOffsetTop -
        sectionHeights.navigationOffsetTop;
      if (normalisedRouterHeight < sectionHeights.navigationHeight) {
        const difference =
          sectionHeights.navigationHeight -
          sectionHeights.routerComponentHeight -
          (sectionHeights.routerComponentOffsetTop -
            sectionHeights.navigationOffsetTop);
        const naturalHeight = noResultsElementRef?.nativeElement.clientHeight;
        const targetHeight = `${naturalHeight + difference}px`;
        renderer.setStyle(
          noResultsElementRef.nativeElement,
          'height',
          targetHeight
        );
      }
    }
  }
}
