<ng-container
  *ngIf="newInformationDocumentCount$ | async as newInformationDocumentCount"
>
  <div
    *ngIf="!isRMP && newInformationDocumentCount > 0"
    class="alert alert-primary"
    role="alert"
    (click)="navigateSigningRequestPage()"
  >
    {{
      (newInformationDocumentCount === 1
        ? 'new.information.document.message'
        : 'new.information.documents.message'
      ) | translate
    }}
  </div>
</ng-container>
