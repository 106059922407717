//TODO unify with the AmountDto and make sure to use the right data type for the amount

// This should be done after removing the old backend models that may also use AmountDto
export interface MoneyDto {
  amount: string;
  ccyIso: string;
}

export interface AccountOverviewDto {
  total: MoneyDto;
  accountGroups: Array<AccountGroupDto>;
}

export interface AccountGroupDto {
  accountCategory: AccountCategory;
  nameDe: string;
  nameEn: string;
  totalValue: MoneyDto;
  accounts: Array<AccountDto>;
}

export interface AccountDto {
  id?: string;
  accountNo: string;
  currentValues: AccountCurrentDto;
  name: string;
  iban?: string;
  ccyIsoCode: string;
  limit?: MoneyDto;
  powerOfAttorneyType?: PowerOfAttorneyType;
  paymentAllowed?: boolean; //TODO the field is optional for compatibility with the old backend
  hasDetails: boolean;
}

export enum AccountCategory {
  Others = 'OTHERS',
  Savings = 'SAVINGS',
  Credit = 'CREDIT',
  Cash = 'CASH',
  Timedeposit = 'TIMEDEPOSIT',
  Securities = 'SECURITIES',
}

export interface AccountCurrentDto {
  bookedBalance: MoneyDto;
}

export enum PowerOfAttorneyType {
  SinglePOA = 'SINGLEPOA',
  JointPOA = 'JOINTPOA',
  InfoPOA = 'INFOPOA',
}

export interface CashAccountTransactionView {
  pendingPayments: Array<CashAccountTransactionDto>;
  transactions: Array<CashAccountTransactionDto>;
}

export interface CashAccountTransactionDto {
  id: string;
  transactionCode: Number;
  cashAccountId: string;
  amount: MoneyDto;
  purposeCode: CashAccountTransactionPurposeCodeDto;
  bookingDate: string;
  valueDate: string;
  descriptionLines: Array<string>;
  counterPartyNameLines: Array<string>;
  counterPartyBic: string;
  counterPartyIban: string;
  instructedAmount: MoneyDto;
  ultimateDebtor: string;
  ultimateCreditor: string;
  endToEndId: string;
  currencyExchangeRate: string;
}

export interface CashAccountTransactionFilterOptionsDto {
  purposeCodes: Array<CashAccountTransactionPurposeCodeDto>;
  turnOverTypes: TransactionTurnOverType;
}

export enum TransactionTurnOverType {
  Inflow = 'INFLOW',
  Outflow = 'OUTFLOW',
}

export interface CashAccountTransactionPurposeCodeDto {
  id: string;
  code: string;
  descriptionDe: string;
  descriptionEn: string;
}

export enum PaymentOrderStatus {
  New = 'NEW',
  ChangeNew = 'CHANGE_NEW',
  DeleteNew = 'DELETE_NEW',
  Approved = 'APPROVED',
  DeleteApproved = 'DELETE_APPROVED',
  Scheduled = 'SCHEDULED',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
}
