import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE_CODE_DE, LOCALE_CODES } from '@core/constants/defaults';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'mediumDate',
  pure: false,
})
export class MediumDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, withWeekDay = false): any {
    const pattern = withWeekDay ? 'EEEE, dd. MMM yyyy' : 'dd. MMM yyyy';
    const datePipe: DatePipe = new DatePipe(
      LOCALE_CODES.get(this.translateService.currentLang)!
    );
    let formattedDate = datePipe.transform(value, pattern);
    if (this.translateService.currentLang === LANGUAGE_CODE_DE) {
      const position = this.getPosition(formattedDate, '.', 2);
      if (formattedDate && position !== -1) {
        formattedDate =
          formattedDate.slice(0, position) + formattedDate.slice(position + 1);
      }
    }
    return formattedDate;
  }

  getPosition(text: any, character: string, index: number): number {
    return text ? text.split(character, index).join(index).length : -1;
  }
}
