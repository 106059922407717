import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from '@core/guards/auth.guard';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { PublicLayoutComponent } from './layout/public/layout.component';
import { SecureLayoutComponent } from './layout/secure/layout.component';
import { EnvironmentActivateGuard } from '@core/guards/environment-activate-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'style-guide', component: StyleGuideComponent },
  {
    path: '',
    component: SecureLayoutComponent,
    children: [
      {
        path: 'rmp',
        loadChildren: () => {
          return import('./modules/rmp/rmp.module').then((m) => m.RmpModule);
        },
      },
      {
        path: 'kaia',
        loadChildren: () => {
          return import('./modules/kaia/kaia.module').then((m) => m.KaiaModule);
        },
      },
      {
        path: 'fip',
        loadChildren: () => {
          return import('./modules/fip/fip.module').then((m) => m.FipModule);
        },
      },
      {
        path: 'portfolio',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/portfolio/portfolio.module').then(
            (m) => m.PortfolioModule
          );
        },
      },
      {
        path: 'payments',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/payments/payments.module').then(
            (m) => m.PaymentsModule
          );
        },
      },
      {
        path: 'account',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/account/account.module').then(
            (m) => m.AccountModule
          );
        },
      },
      {
        path: 'postbox',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/postbox/postbox.module').then(
            (m) => m.PostboxModule
          );
        },
      },
      {
        path: 'faq',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/faq/faq.module').then((m) => m.FaqModule);
        },
      },
      {
        path: 'contacts',
        canActivate: [AuthorizationGuard],
        loadChildren: () => {
          return import('./modules/contacts/contacts.module').then(
            (m) => m.ContactsModule
          );
        },
      },
    ],
  },
  {
    path: 'auth',
    component: PublicLayoutComponent,
    canActivate: [EnvironmentActivateGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
    }),
  ], // enableTracing: true just for debugging at development
  exports: [RouterModule],
})
export class AppRoutingModule {}
