import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { AuthSessionType } from '@models/auth-session.model';
import { Observable, Subscription } from 'rxjs';
import { TwoFactorAuthService } from '@core/services/twoFactorAuth/two-factor-auth.service';
import { STATE_TYPE } from '@models/authentication/authentication.model';

@Component({
  selector: 'wmp-two-factor-modal',
  templateUrl: './two-factor-modal.component.html',
  styleUrls: ['./two-factor-modal.component.scss'],
})
export class TwoFactorModalComponent implements OnDestroy {
  @Input()
  public twoFactorStatus!: any;
  @Input()
  public isLogin = false;
  @Input()
  public sessionId: string = '';
  public AuthSessionType = AuthSessionType;
  public isPolling2fa = false;
  private authSubscription?: Subscription;

  @Output()
  public confirmationEmitter: EventEmitter<boolean> = new EventEmitter();

  private getState: Observable<any> | undefined;

  constructor(private twoFactorAuthService: TwoFactorAuthService) {}

  public cancelAuthSession(): void {
    if (this.isLogin) {
      this.confirmationEmitter.emit(false);
    } else {
      this.authSubscription = this.twoFactorAuthService
        .cancelAuthSession(this.sessionId)
        .subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.twoFactorStatus = undefined;
    this.isLogin = false;
  }

  public isConfirmationScreenPending(): boolean {
    return this.twoFactorStatus
      ? this.twoFactorStatus.secSignStatus !== STATE_TYPE.CANCELED &&
          this.twoFactorStatus.secSignStatus !== STATE_TYPE.AUTHENTICATED
      : true;
  }
}
