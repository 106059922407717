import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as FeatureActions from './actions';
import { throwError } from 'rxjs';
import * as ActionTypes from './actionTypes';
import { DocumentService } from '@core/services/document/document.service';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DocumentModel, DocumentsAccount } from '@models/document.model';
import { PostboxUtilService } from '@modules/postbox/postbox.service';

@Injectable()
export class DocumentEffects {
  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_REQUEST),
      mergeMap((action: FeatureActions.LoadRequestAction) =>
        this.service.fetchDocuments(action.payload).pipe(
          map((result) => new FeatureActions.LoadSuccessAction(result)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadMoreDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_MORE_REQUEST),
      mergeMap((action: FeatureActions.LoadMoreRequestAction) =>
        this.service.fetchDocuments(action.payload).pipe(
          map((result) => new FeatureActions.LoadMoreSuccessAction(result)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadDocumentsForSigning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_SIGNING_REQUEST),
      mergeMap((action: FeatureActions.LoadMoreSigningRequestAction) =>
        this.service.fetchDocumentsForSigning(action.payload).pipe(
          map((result) => new FeatureActions.LoadSigningSuccessAction(result)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadSigningFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadMoreDocumentsForSigning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_MORE_SIGNING_REQUEST),
      mergeMap((action: FeatureActions.LoadMoreSigningRequestAction) =>
        this.service.fetchDocumentsForSigning(action.payload).pipe(
          map(
            (result) => new FeatureActions.LoadMoreSigningSuccessAction(result)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadSigningFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  downloadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DOWNLOAD),
      mergeMap((action: FeatureActions.Download) =>
        this.service.download(action.payload).pipe(
          tap((result) =>
            this.service.handleFileDownload(result, action.payload)
          ),
          mergeMap((result) => {
            const { documentIds } = action.payload;
            const updates = this.createUpdatesCollection(documentIds);
            return [
              new FeatureActions.DownloadSuccess(result),
              new FeatureActions.UpdateDocuments(updates),
            ];
          }),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.DownloadFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  downloadSigningDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DOWNLOAD_SIGNING),
      mergeMap((action: FeatureActions.DownloadSigning) =>
        this.service.downloadSigning(action.payload).pipe(
          tap((result) =>
            this.service.handleFileDownload(result, action.payload)
          ),
          mergeMap((result) => {
            const { documentIds } = action.payload;
            const updates = this.createUpdatesCollection(documentIds);
            return [
              new FeatureActions.DownloadSigningSuccess(result),
              new FeatureActions.UpdateDocuments(updates),
            ];
          }),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.DownloadSigningFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  mergeDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MERGE),
      mergeMap((action: FeatureActions.Merge) =>
        this.service.merge(action.payload).pipe(
          tap((result) =>
            this.service.handleFileDownload(result, action.payload)
          ),
          mergeMap((result) => {
            const { documentIds } = action.payload;
            const updates = this.createUpdatesCollection(documentIds);
            return [
              new FeatureActions.DownloadSuccess(result),
              new FeatureActions.UpdateDocuments(updates),
            ];
          }),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.DownloadFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MARK_AS_READ),
      mergeMap((action: FeatureActions.MarkAsRead) =>
        this.service.markAsRead(action.payload).pipe(
          mergeMap(() => {
            const { documentIds } = action.payload;
            const updates = this.createUpdatesCollection(documentIds);
            return [
              new FeatureActions.MarkAsReadSuccess(updates),
              new FeatureActions.UpdateDocuments(updates),
            ];
          }),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.MarkAsReadFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  getUnreadCounter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REQUEST_UNREAD_COUNTER),
      mergeMap(() => {
        return this.service
          .getUnreadCounter()
          .pipe(
            map((data: number) => new FeatureActions.UpdateUnreadCounter(data))
          );
      })
    );
  });

  getNewSigningRequestsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.NEW_SIGNING_REQUESTS_COUNT_REQUEST),
      mergeMap(() => {
        return this.service
          .getNewSigningRequestsCount()
          .pipe(
            map(
              (data: number) =>
                new FeatureActions.NewSigningRequestsCountSuccess(data)
            )
          );
      })
    );
  });

  getDocumentsAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_DOCUMENTS_ACCOUNTS_REQUEST),
      mergeMap(() => {
        return this.service.getDocumentsAccounts().pipe(
          map(
            (data: DocumentsAccount) =>
              new FeatureActions.LoadDocumentsAccountsSuccessAction(data)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  signDocument2Fa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_SEC_SIGN_ID_FOR_DOCUMENT_SIGNING_REQUEST),
      mergeMap(
        (action: FeatureActions.GetSecSignIdForDocumentSigningRequestAction) =>
          this.service
            .getSecSignIdForDocumentSigningRequest(action.payload)
            .pipe(
              map((response) => {
                const documentId = action.payload.documentIds[0];
                return this.processDocumentSigningResult(response, documentId);
              }),
              catchError((error) => {
                this.store$.dispatch(
                  new FeatureActions.LoadFailureAction({
                    error: error.message ? error.message : error,
                  })
                );
                return throwError(error);
              })
            )
      )
    );
  });

  private processDocumentSigningResult(response: any, documentId: string) {
    if (response) {
      const serviceResult = this.service.getSecSignState(documentId);
      this.postboxUtilService.proceedOperation(serviceResult, documentId);
      return new FeatureActions.ProcessingDocumentSigningSuccessAction(
        response
      );
    } else {
      return new FeatureActions.LoadFailureAction(response);
    }
  }

  signDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SIGN_DOCUMENT_REQUEST),
      mergeMap((action: FeatureActions.SignDocumentRequest) =>
        this.service.signDocument(action.payload).pipe(
          map(() => new FeatureActions.SignDocumentSuccess(action.payload)),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.SignDocumentFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  declineDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DECLINE_DOCUMENT_REQUEST),
      mergeMap((action: FeatureActions.DeclineDocumentRequest) =>
        this.service.declineDocument(action.payload).pipe(
          map(() => {
            this.store$.dispatch(
              new FeatureActions.NewSigningRequestsCountRequest()
            );
            return new FeatureActions.DeclineDocumentSuccess(action.payload);
          }),
          catchError((error) => {
            this.store$.dispatch(new FeatureActions.DeclineDocumentFailure());
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private service: DocumentService,
    private postboxUtilService: PostboxUtilService
  ) {}

  private createUpdatesCollection(
    documentIds: string[] | number[]
  ): Update<DocumentModel>[] {
    const updates: Update<DocumentModel>[] = [];
    documentIds.forEach((id: string | number) => {
      updates.push({
        id: id as string,
        changes: {
          isRead: true,
        },
      });
    });
    return updates;
  }

  loadDocumentsUploadForHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_UPLOAD_HISTORY_REQUEST),
      mergeMap((action: FeatureActions.LoadUploadHistoryRequestAction) =>
        this.service.fetchDocumentUploadHistory(action.payload).pipe(
          map(
            (result) =>
              new FeatureActions.LoadUploadHistorySuccessAction(result)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadMoreDocumentsUploadForHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_MORE_UPLOAD_HISTORY_REQUEST),
      mergeMap((action: FeatureActions.LoadMoreUploadHistoryRequestAction) =>
        this.service.fetchDocumentUploadHistory(action.payload).pipe(
          map(
            (result) =>
              new FeatureActions.LoadMoreUploadHistorySuccessAction(result)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });
}
