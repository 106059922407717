import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BackendErrorService } from '@core/services/backend-error.service';

@Injectable()
export class BadRequestInterceptor implements HttpInterceptor {
  constructor(private backendErrorService: BackendErrorService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((item) => {}),
      catchError((err) => {
        if ([400].includes(err.status)) {
          this.backendErrorService.badRequestCatch.next(true);
        }
        return throwError(err);
      })
    );
  }
}
