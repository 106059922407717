import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  PaymentAccountsModel,
  PaymentSortCodeModel,
  ProductSummaryModel,
} from '@models/account.model';
import {
  AccountInfoResponse,
  CountryModel,
  CurrencyModel,
  FeesModel,
  PaymentApprovalModel,
  PaymentFormModel,
  StandingOrdersModel,
} from '@models/payment.model';
import {
  PaymentSecSignSessionType,
  SecSignStatusRejectedReason,
} from '@models/auth-session.model';

export const FEATURE_KEY = 'payment';

export const featureAdapter: EntityAdapter<ProductSummaryModel> =
  createEntityAdapter<ProductSummaryModel>({});

export interface State extends EntityState<any> {
  error?: any;
  isLoading: boolean;
  currencies: CurrencyModel[];
  fees: FeesModel | null;
  countries: CountryModel[];
  accountInfo: AccountInfoResponse | null;
  selectedPaymentAccount?: PaymentAccountsModel;
  formData?: PaymentFormModel;
  selectedStandingOrder?: StandingOrdersModel;
  sortCodeData?: PaymentSortCodeModel;
  secSignStatus: PaymentSecSignSessionType;
  secSignRejectedReason: SecSignStatusRejectedReason | null;
  standingOrders?: StandingOrdersModel[];
  paymentApprovals?: PaymentApprovalModel[];
  standingOrderDeleteResult?: PaymentSecSignSessionType;
  isDeclinePaymentSuccessful: boolean | null;
  isApprovedPaymentSuccessful: boolean | null;
  attorneyCode: string;
}

export const initialState: State = featureAdapter.getInitialState({
  error: null,
  isLoading: false,
  currencies: [],
  countries: [],
  accountInfo: null,
  attorneyCode: '',
  fees: null,
  secSignStatus: PaymentSecSignSessionType.NO_STATE,
  isDeclinePaymentSuccessful: null,
  isApprovedPaymentSuccessful: null,
  secSignRejectedReason: null,
});
