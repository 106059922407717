import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { FEATURE_KEY, featureAdapter, State } from './state';
import {
  DocumentsFilterModel,
  DocumentUploadHistoryModel,
} from '@models/document.model';
import { DocumentSigningSecSignSessionType } from '@models/auth-session.model';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getIsUpdating = (state: State): boolean =>
  !!(state.isLoading && state.ids.length);
export const getSecSignStatus = (
  state: State
): DocumentSigningSecSignSessionType => state.secSignStatus;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectById = (id: string) =>
  createSelector(selectEntities, (allElements) => {
    return allElements[id];
  });

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);
export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
export const selectIsUpdating: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsUpdating);
export const selectItemsPerPage = createSelector(
  selectState,
  (state: State): number => state.itemsPerPage
);
export const selectFilter = createSelector(
  selectState,
  (state: State): DocumentsFilterModel => state.filter
);
export const selectUnreadItems = createSelector(
  selectState,
  (state: State): number => state.unreadCounter
);
export const selectNewSigningRequestsCount = createSelector(
  selectState,
  (state: State): number => state.newSigningRequestCount
);
export const selectNewInformationDocumentCount = createSelector(
  selectState,
  (state: State): number => state.newInformationDocumentCount
);
export const selectReadDocuments = createSelector(
  selectState,
  (state: State): string[] => state.readDocuments
);
export const selectRejectedDocuments = createSelector(
  selectState,
  (state: State): string[] => state.rejectedDocuments
);
export const selectSignedDocuments = createSelector(
  selectState,
  (state: State): string[] => state.signedDocuments
);
export const selectDocumentsAccounts = createSelector(
  selectState,
  (state: State): string[] => state.documentsAccounts
);
export const selectUploadedDocumentHistory = createSelector(
  selectState,
  (state: State): DocumentUploadHistoryModel[] => state.uploadedDocumentHistory
);
export const selectFilterAndPage = createSelector(
  selectItemsPerPage,
  selectFilter,
  (itemsPerPage, filter) => {
    return {
      itemsPerPage,
      filter,
    };
  }
);
export const selectSecSignStatus: MemoizedSelector<
  object,
  DocumentSigningSecSignSessionType
> = createSelector(selectState, getSecSignStatus);
