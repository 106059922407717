<ul>
  <li *ngIf="isRmp()">
    <a routerLink="/rmp" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/rmp.svg" /></i>
      <span>RMP</span>
    </a>
  </li>
  <li
    *ngIf="isRmp()"
    [ngClass]="{ disabled: !isKaiaEnabled() || isAClientSelected() }"
  >
    <a routerLink="/kaia" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/chat-bot.svg" /></i>
      <span>KAIA</span>
    </a>
  </li>
  <li [ngClass]="{ disabled: !isAClientSelected() }">
    <a id="menuPortfolios" routerLink="/portfolio" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/donut-chart.svg" /></i>
      <span>{{ 'nav.portfolios' | translate }}</span>
    </a>
  </li>
  <li [ngClass]="{ disabled: !isAClientSelected() }">
    <a id="menuAccounts" routerLink="/account" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/reverse-bullet-list.svg" /></i>
      <span>{{ 'nav.accounts' | translate }}</span>
    </a>
  </li>
  <li [ngClass]="{ disabled: !isAClientSelected() }">
    <a id="menuPayments" routerLink="/payments" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/hand-money-outline.svg" /></i>
      <span>{{ 'nav.payments' | translate }}</span>
    </a>
  </li>
  <li [ngClass]="{ disabled: !isAClientSelected() }">
    <a id="menuPostbox" routerLink="/postbox" routerLinkActive="active">
      <i
        ><img src="../../../assets/images/icons/file-document-outline.svg"
      /></i>
      <span>{{ 'nav.postbox' | translate }}</span>
      <ng-container *ngIf="isRmp() ? isAClientSelected() : true">
        <wmp-unread-counter></wmp-unread-counter>
      </ng-container>
    </a>
  </li>
  <li [ngClass]="{ disabled: !isAClientSelected() }">
    <a id="menuFaq" routerLink="/faq" routerLinkActive="active">
      <i><img src="../../../assets/images/icons/help-circle-outline.svg" /></i>
      <span>{{ 'nav.faq' | translate }}</span>
    </a>
  </li>
</ul>
<ng-container *ngIf="isAClientSelected()">
  <div
    class="relationship-manager"
    *ngIf="relationshipInfo$ | async as relationshipInfo"
  >
    <p>{{ 'nav.contactPerson' | translate }}</p>
    <img alt="ContactPerson" *ngIf="this.rmPicture" [src]="this.rmPicture" />
    <p>
      <strong id="menuRMName">{{ relationshipInfo?.managerName }}</strong>
    </p>
    <p>{{ relationshipInfo?.phonenumber }}</p>
    <p>
      <a href="mailto:{{ relationshipInfo?.emailAddress }}">{{
        'nav.relationshipmanager.email' | translate
      }}</a>
    </p>
  </div>

  <div class="other-contacts">
    <a id="menuOtherContacts" routerLink="/contacts" routerLinkActive="active">
      <span>{{ 'nav.otherContacts' | translate }}</span>
      <i>
        <img src="../../../assets/images/icons/menu.svg" />
        <img src="../../../assets/images/icons/chevron-right.svg" />
      </i>
    </a>
  </div>
</ng-container>
