import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, delay, repeat } from 'rxjs/operators';
import { DocumentSigningSecSignSessionType } from '@models/auth-session.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TwoFaConfirmOperationModalComponent } from './components/signing-requests/2fa-confirm-operation-modal/2fa-confirm-operation-modal.component';
import { DocumentActions, DocumentSelectors } from '@store/document-store';
import { DocumentActionParams } from '@models/document.model';
import * as FeatureActions from '@store/document-store/actions';

@Injectable({
  providedIn: 'root',
})
export class PostboxUtilService {
  private pollingSubscription?: Subscription;
  private secSignStatusSubscription?: Subscription;

  constructor(private store$: Store, private dialog: MatDialog) {}

  pollSecSignResponse(serviceResult: Observable<any>): void {
    this.pollingSubscription = serviceResult
      .pipe(
        delay(1000),
        catchError((error) => {
          this.pollingSubscription?.unsubscribe();
          this.store$.dispatch(
            new DocumentActions.SetSecSignStatus(
              DocumentSigningSecSignSessionType.CANCELLED
            )
          );
          return throwError(error);
        }),
        repeat()
      )
      .subscribe((response: any) => {
        this.store$.dispatch(
          new DocumentActions.SetSecSignStatus(response.status)
        );
      });
  }

  proceedOperation(serviceResult: Observable<any>, documentId: string) {
    this.pollSecSignResponse(serviceResult);
    const dialogRef = this.dialog.open(TwoFaConfirmOperationModalComponent);
    dialogRef.addPanelClass('generic-modal');
    dialogRef.disableClose = true;
    const responseEmitterSubscription =
      dialogRef.componentInstance.responseEmitter.subscribe((response) => {
        if (response === false) {
          this.pollingSubscription?.unsubscribe();
          responseEmitterSubscription.unsubscribe();
          dialogRef.close();
        }
      });
    this.secSignStatusSubscription = this.store$
      .select(DocumentSelectors.selectSecSignStatus)
      .subscribe((status) => {
        if (
          status !== DocumentSigningSecSignSessionType.ACCEPTED &&
          status !== DocumentSigningSecSignSessionType.NO_STATE
        ) {
          this.pollingSubscription?.unsubscribe();
          this.secSignStatusSubscription?.unsubscribe();
          const payload: DocumentActionParams = { documentIds: [documentId] };
          this.store$.dispatch(
            new DocumentActions.SignDocumentSuccess(payload)
          );
          dialogRef.close();
          this.store$.dispatch(
            new FeatureActions.NewSigningRequestsCountRequest()
          );
        }
      });
  }
}
