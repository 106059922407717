<ul>
  <li *ngFor="let link of links">
    <a
      [id]="link.path"
      [routerLink]="link.path"
      (click)="onClick(link.path)"
      [class.active]="isLinkActive(link.path)"
    >
      {{ link.title | translate }}
      <div class="info" *ngIf="link.tooltip">
        <p [innerHTML]="'payments.approval.tooltip' | translate"></p>
      </div>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
