import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State, FEATURE_KEY } from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean =>
  !!(state.isLoading && !state.ids.length);

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectById = (id: string) =>
  createSelector(selectEntities, (allElements) => {
    return allElements[id];
  });

export const selectFirst = createSelector(selectAll, (elements) => {
  return elements[0];
});

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
