import { Observable, of } from 'rxjs';
import {
  AuthenticationData,
  LoginResultModel,
  StatusData,
} from '@models/login.model';
import {
  LoginRequestModel,
  LoginResultModel as LRModel,
  ONBOARDING_STATE,
  OnboardingResponse,
  STATE_TYPE,
  UserSessionResponse,
} from '@models/authentication/authentication.model';
import { AuthSessionType } from '@models/auth-session.model';
import { State } from '@ngrx/store';

export function transformWebLoginDto(
  loginData: LoginResultModel
): Observable<LRModel> {
  var resultObj = <LRModel>{};
  if (!loginData) return of(resultObj);

  resultObj = {
    changePassword: loginData.resetpwd ? Boolean(loginData.resetpwd) : false,
    state: transformStateTypeDto(
      loginData.status
        ? loginData.status
        : AuthSessionType.SESSION_STATE_NOSTATE
    ),
    authSession: loginData.authSession,
    secSignId: loginData.idToken,
  };

  return of(resultObj);
}

export function transformStateTypeDto(statusData: string): STATE_TYPE {
  let retValue: STATE_TYPE;
  switch (statusData) {
    case AuthSessionType.SESSION_STATE_FETCHED:
      retValue = STATE_TYPE.PENDING_2FA;
      break;
    case AuthSessionType.SESSION_STATE_AUTHENTICATED:
      retValue = STATE_TYPE.AUTHENTICATED;
      break;
    case AuthSessionType.SESSION_STATE_ONBOARDING_NOT_INITED:
    case AuthSessionType.SESSION_STATE_ONBOARDING_PENDING:
      retValue = STATE_TYPE.ONBOARDING_NEEDED;
      break;
    case AuthSessionType.SESSION_STATE_CANCELED:
      retValue = STATE_TYPE.CANCELED;
      break;
    case AuthSessionType.SESSION_STATE_DENIED:
    case AuthSessionType.SESSION_STATE_INVALID:
      retValue = STATE_TYPE.FAILED;
      break;
    case AuthSessionType.SESSION_STATE_EXPIRED:
      retValue = STATE_TYPE.EXPIRED;
      break;
    default:
      retValue = STATE_TYPE.NO_STATE;
      break;
  }
  return retValue;
}

export function transformUserSessionDto(
  statusData: StatusData
): Observable<UserSessionResponse> {
  var resultObj = <UserSessionResponse>{};
  if (!statusData) return of(resultObj);

  resultObj = {
    expires: '',
    expiresIn: statusData.expires_in.toString(),
    role: '',
    state: statusData.expires_in ? STATE_TYPE.AUTHENTICATED : STATE_TYPE.FAILED,
  };

  return of(resultObj);
}

export function transformOnboardingDto(
  onboardingData: LoginResultModel
): Observable<OnboardingResponse> {
  var resultObj = <OnboardingResponse>{};
  if (!onboardingData) return of(resultObj);

  resultObj = {
    changePwd: toBoolean(onboardingData.resetpwd),
    onboardingState: transformOnboardingStateTypeDto(
      onboardingData.status
        ? onboardingData.status
        : AuthSessionType.SESSION_STATE_NOSTATE
    ),
  };

  return of(resultObj);
}

export function transformOnboardingStateTypeDto(
  statusData: string
): ONBOARDING_STATE {
  let retValue: ONBOARDING_STATE;
  switch (statusData) {
    case AuthSessionType.SESSION_STATE_ONBOARDING_FINISHED:
      retValue = ONBOARDING_STATE.ONBOARDING_FINISHED;
      break;
    case AuthSessionType.SESSION_STATE_ONBOARDING_PENDING:
      retValue = ONBOARDING_STATE.ONBOARDING_NEEDED;
      break;
    case AuthSessionType.SESSION_STATE_ONBOARDING_CHANGE_PASSWORD_INVALID:
      retValue = ONBOARDING_STATE.INIT_ONBOARDING_FAILED;
      break;
    default:
      retValue = ONBOARDING_STATE.NO_STATE;
      break;
  }
  return retValue;
}

export function toBoolean(value: any): boolean {
  return value ? value.toString().toLowerCase().trim() === 'true' : false;
}
