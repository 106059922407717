import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortfolioSpecificationsDto } from '@models/specification.model';
import { Observable } from 'rxjs';
import { FeatureService } from '../feature.service';
@Injectable({
  providedIn: 'root',
})
export class SpecificationService {
  constructor(
    private http: HttpClient,
    private featureService: FeatureService
  ) {}

  fetchSpecifications(
    portfolioCode: string
  ): Observable<PortfolioSpecificationsDto> {
    return this.fetchSpecificationsNew(portfolioCode);
  }

  private fetchSpecificationsNew(
    portfolioCode: string
  ): Observable<PortfolioSpecificationsDto> {
    return this.http.get<PortfolioSpecificationsDto>(
      `/api/v1-legacy/portfolios/${portfolioCode}/specifications`
    );
  }
}
