import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './state';
import { templateReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { TemplateEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, templateReducer),
    EffectsModule.forFeature([TemplateEffects]),
  ],
})
export class TemplateStoreModule {}
