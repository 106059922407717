import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';
import { LoginResultModel } from '@models/login.model';
import { ServiceResult } from '@models/serviceResult.model';
import { OnboardingStatusResultModel } from '@models/onboarding.model';

export class LoginUsernameAndPasswordAction implements Action {
  readonly type = ActionTypes.LOGIN_USERNAME_AND_PASSWORD;
  constructor(public payload: { userName: string; password: string }) {}
}

export class LoginAttemptFailureAction implements Action {
  readonly type = ActionTypes.LOGIN_ATTEMPT_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoginAttemptSuccessAction implements Action {
  readonly type = ActionTypes.LOGIN_ATTEMPT_SUCCESS;
  constructor(public payload: LoginResultModel, public isLoggedIn: any) {}
}

export class Poll2FaAction implements Action {
  readonly type = ActionTypes.POLL_2FA;
}

export class RefreshToken implements Action {
  readonly type = ActionTypes.REFRESH_TOKEN;
  constructor(public refreshToken: string) {}
}

export class RefreshTokenSuccessAction implements Action {
  readonly type = ActionTypes.REFRESH_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}

export class GetEnvironment implements Action {
  readonly type = ActionTypes.GET_ENVIRONMENT;
}

export class GetEnvironmentSuccessAction implements Action {
  readonly type = ActionTypes.GET_ENVIRONMENT_SUCCESS;
  constructor(public payload: string) {}
}

export class CancelAuthSession implements Action {
  readonly type = ActionTypes.CANCEL_AUTH_SESSION;
  constructor(public session: any) {}
}

export class CancelLoginAuthSession implements Action {
  readonly type = ActionTypes.CANCEL_LOGIN_AUTH_SESSION;
  constructor(public session: any) {}
}

export class CancelAttemptSuccessAction implements Action {
  readonly type = ActionTypes.CANCEL_ATTEMPT_SUCCESS;
  constructor(public payload: ServiceResult<boolean>) {}
}

export class CancelAttemptErrorAction implements Action {
  readonly type = ActionTypes.LOGIN_ATTEMPT_SUCCESS;
  constructor(public payload: { error: any }) {}
}

export class SetSecSignStatus implements Action {
  readonly type = ActionTypes.SET_SEC_SIGN_STATUS;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = ActionTypes.LOGOUT;
}

export class OnboardingChangePasswordAction implements Action {
  readonly type = ActionTypes.ONBOARDING_CHANGE_PASSWORD;
  constructor(
    public payload: { userName: string; password: string; oldPassword: string }
  ) {}
}

export class OnboardingChangePasswordSuccessAction implements Action {
  readonly type = ActionTypes.ONBOARDING_CHANGE_PASSWORD_SUCCESS;
  constructor(public payload: LoginResultModel) {}
}

export class OnboardingChangePasswordFailureAction implements Action {
  readonly type = ActionTypes.ONBOARDING_CHANGE_PASSWORD_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class GetOnboardingStatusAction implements Action {
  readonly type = ActionTypes.GET_ONBOARDING_STATUS;
  constructor(public payload: { userName: string }) {}
}

export class GetOnboardingStatusSuccessAction implements Action {
  readonly type = ActionTypes.GET_ONBOARDING_STATUS_SUCCESS;
  constructor(public payload: OnboardingStatusResultModel) {}
}

export class GetOnboardingStatusFailureAction implements Action {
  readonly type = ActionTypes.GET_ONBOARDING_STATUS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class PollOnboardingStatusAction implements Action {
  readonly type = ActionTypes.POLL_ONBOARDING_STATUS;
}

export class GetUserRoles implements Action {
  readonly type = ActionTypes.GET_USER_ROLES_REQUEST;
}

export class GetUserRolesSuccess implements Action {
  readonly type = ActionTypes.GET_USER_ROLES_SUCCESS;
  constructor(public payload: any) {}
}
