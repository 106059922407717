import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY } from './state';
import { Data, Params } from '@angular/router';

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState<any>>(FEATURE_KEY);

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const selectRouteNestedParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let params: Params = {};
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = {
        ...params,
        ...currentRoute.params,
        ...currentRoute.queryParams,
      };
    }
    return params;
  }
);

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

export const selectNestedDataFromRoute = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let data: Data = {};
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
      data = {
        ...data,
        ...currentRoute.data,
      };
    }
    return data;
  }
);

export const selectNestedDataFromRouteByKey = (key: string) =>
  createSelector(selectNestedDataFromRoute, (params) => params && params[key]);
