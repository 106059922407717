import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './state';
import { marketingCampaignReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { MarketingCampaignEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, marketingCampaignReducer),
    EffectsModule.forFeature([MarketingCampaignEffects]),
  ],
})
export class MarketingCampaignStoreModule {}
