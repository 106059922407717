<p class="descriptor">
  <strong>Typography</strong><br />
  Stylesheet: scss/ui/_typography.scss
</p>

<h1>Headline 1</h1>
<h2>Headline 2</h2>
<h3>Subhead</h3>
<p class="copy">Copy</p>
<p class="copy bold">Copy - highlighted ('bold' class applied)</p>
<p class="copy"><strong>Copy - highlighted ('strong' tag applied)</strong></p>
<p class="overline">Tabs / Menu / Overline</p>
<p class="infotext">Info text</p>

<p class="descriptor">
  <strong>Colours</strong><br />
  Stylesheet: scss/ui/_color.scss
</p>

<div class="colors">
  <div class="color brown-50">
    <p>brown-50</p>
    <p>"primary" / "equities"</p>
    <p>#c2542b</p>
  </div>
  <div class="color brown-40">
    <p>brown-40</p>
    <p>"primary variant"</p>
    <p>#a54724</p>
  </div>
  <div class="color brown-60">
    <p>brown-60</p>
    <p>"accent"</p>
    <p>#e7bbaa</p>
  </div>
  <div class="color green-50">
    <p>green-50</p>
    <p>"positive"</p>
    <p>#00843c</p>
  </div>
  <div class="color green-60">
    <p>green-60</p>
    <p>"alternative investment"</p>
    <p>#559175</p>
  </div>
  <div class="color red-50">
    <p>red-50</p>
    <p>"negative" / "warning variant"</p>
    <p>#e60000</p>
  </div>
  <div class="color red-60">
    <p>red-60</p>
    <p>"warning"</p>
    <p>#f27373</p>
  </div>
  <div class="color white">
    <p>white</p>
    <p>"content background"</p>
    <p>#ffffff</p>
  </div>
  <div class="color grey-20">
    <p>grey-20</p>
    <p>"grey box"</p>
    <p>#f5f5f5</p>
  </div>
  <div class="color grey-30">
    <p>grey-30</p>
    <p>"outline"</p>
    <p>#e7e7e7</p>
  </div>
  <div class="color grey-40">
    <p>grey-40</p>
    <p>"neutral" / "disabled"</p>
    <p>#aeaeae</p>
  </div>
  <div class="color grey-50">
    <p>grey-50</p>
    <p>"mixed funds"</p>
    <p>#9e9a97</p>
  </div>
  <div class="color grey-60">
    <p>grey-60</p>
    <p>"secondary"</p>
    <p>#495057</p>
  </div>
  <div class="color grey-70">
    <p>grey-70</p>
    <p>"standard"</p>
    <p>#262626</p>
  </div>
  <div class="color grey-80">
    <p>grey-80</p>
    <p>"header background"</p>
    <p>#080808</p>
  </div>
  <div class="color blue-40">
    <p>blue-40</p>
    <p>"fixed income"</p>
    <p>#576a85</p>
  </div>
  <div class="color yellow-50">
    <p>yellow-50</p>
    <p>"liquidity"</p>
    <p>#f8c019</p>
  </div>
</div>

<p class="descriptor">
  <strong>Buttons</strong><br />
  Stylesheet: scss/ui/_button.scss
</p>

<div class="buttons">
  <button class="primary"><span>Primary</span></button>
  <button class="primary" disabled="disabled"><span>Primary</span></button>
  <button class="secondary"><span>Secondary</span></button>
  <button class="secondary" disabled="disabled"><span>Secondary</span></button>
  <button class="tertiary">Tertiary</button>
  <button class="tertiary" disabled="disabled">Tertiary</button>
</div>

<p class="descriptor">
  <strong>Checkboxes</strong><br />
  Based off Angular Material UI<br />
  Stylesheet: scss/overrides/material/_checkbox.scss
</p>

<mat-checkbox>Othello</mat-checkbox>
<mat-checkbox>Prospero</mat-checkbox>
<mat-checkbox [disabled]="true">Desdemona</mat-checkbox>

<p class="descriptor">
  <strong>Radio buttons</strong><br />
  Based off Angular Material UI<br />
  Stylesheet: scss/overrides/material/_radio.scss
</p>

<mat-radio-group>
  <mat-radio-button value="1">Calliope</mat-radio-button>
  <mat-radio-button value="2">Orpheus</mat-radio-button>
  <mat-radio-button value="3" [disabled]="true">Titania</mat-radio-button>
</mat-radio-group>

<p class="descriptor">
  <strong>Tab nav</strong><br />
  Note: Component contains its own ng-output.<br />
  Stylesheet: Component stylesheet.
</p>

<wmp-tab-nav [links]="links"></wmp-tab-nav>

<p class="descriptor">
  <strong>Input fields</strong><br />
  Based off Angular Material UI<br />
  Stylesheet: scss/overrides/material/_input.scss
</p>

<div class="inputs">
  <mat-form-field appearance="outline">
    <input matInput placeholder="Magic word" />
    <mat-hint>Xyzzy!</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input matInput placeholder="Magic word" />
    <i matSuffix><img src="../../../assets/images/icons/magnify.svg" /></i>
    <mat-hint>Xyzzy!</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input
      matInput
      [formControl]="demoInputFormControl"
      placeholder="Magic word"
    />
    <mat-error *ngIf="demoInputFormControl.errors"
      >You shall not pass.</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input matInput placeholder="Magic word" [disabled]="true" />
    <mat-hint>Xyzzy!</mat-hint>
  </mat-form-field>
</div>

<p class="descriptor">
  <strong>Dropdown select</strong><br />
  Based off Angular Material UI<br />
  Stylesheet: scss/overrides/material/_select.scss<br />
  Note extensions for button-style dropdowns in forms and the customisation for
  the portfolio selector in the header.
</p>

<div class="row">
  <div class="col">
    <mat-form-field appearance="outline">
      <mat-label>Bands beginning with G</mat-label>
      <mat-select disableOptionCentering>
        <mat-option [value]="'Ghost'">Ghost</mat-option>
        <mat-option [value]="'Guns n Roses'">Guns n' Roses</mat-option>
        <mat-option [value]="'Grave'">Grave</mat-option>
      </mat-select>
      <mat-hint>Also Gorgoroth, Gehenna, and Green Jelly.</mat-hint>
    </mat-form-field>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p>Space above intentionally left blank.</p>
  </div>
  <div class="col">
    <mat-form-field appearance="outline">
      <mat-label>Bands beginning with C</mat-label>
      <mat-select disableOptionCentering [disabled]="true"></mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field appearance="outline">
      <mat-label>Multi-select!</mat-label>
      <mat-select disableOptionCentering multiple>
        <mat-option [value]="'Imperium'">Imperium</mat-option>
        <mat-option [value]="'Kaiserion'">Kaiserion</mat-option>
        <mat-option [value]="'Spillways'">Spillways</mat-option>
        <mat-option [value]="'Call Me Little Sunshine'"
          >Call Me Little Sunshine</mat-option
        >
        <mat-option [value]="'Hunters Moon'">Hunter's Moon</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field appearance="outline" class="button">
      <mat-label>Button</mat-label>
      <mat-select disableOptionCentering>
        <mat-option [value]="'Imperium'">Imperium</mat-option>
        <mat-option [value]="'Kaiserion'">Kaiserion</mat-option>
        <mat-option [value]="'Spillways'">Spillways</mat-option>
        <mat-option [value]="'Call Me Little Sunshine'"
          >Call Me Little Sunshine</mat-option
        >
        <mat-option [value]="'Hunters Moon'">Hunter's Moon</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field appearance="outline" class="button">
      <mat-label>Button</mat-label>
      <mat-select disableOptionCentering [disabled]="true">
        <mat-option [value]="'Imperium'">Imperium</mat-option>
        <mat-option [value]="'Kaiserion'">Kaiserion</mat-option>
        <mat-option [value]="'Spillways'">Spillways</mat-option>
        <mat-option [value]="'Call Me Little Sunshine'"
          >Call Me Little Sunshine</mat-option
        >
        <mat-option [value]="'Hunters Moon'">Hunter's Moon</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<p class="descriptor">
  <strong>Datepicker</strong><br />
  Based off Angular Material UI<br />
  Stylesheet: scss/overrides/material/_datepicker.scss
</p>

<div class="row">
  <div class="col">
    <mat-form-field appearance="outline">
      <input matInput [matDatepicker]="picker" />
      <mat-hint>Days, days, days.</mat-hint>
      <mat-datepicker-toggle
        matSuffix
        [disableRipple]="true"
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field appearance="outline">
      <input matInput [matDatepicker]="matDatepicker2" [disabled]="true" />
      <mat-hint>Disabled, disabled, disabled.</mat-hint>
      <mat-datepicker-toggle
        matSuffix
        [disableRipple]="true"
        [for]="matDatepicker2"
      ></mat-datepicker-toggle>
      <mat-datepicker #matDatepicker2></mat-datepicker>
    </mat-form-field>
  </div>
</div>
