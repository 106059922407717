import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CspNonceService {
  getValue(): string {
    const query = document.querySelector<HTMLElement>('wmp-root');
    if (query && query?.dataset['nonce']) return query?.dataset['nonce'];
    else return '';
  }
}
