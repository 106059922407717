import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  AccountPositionsModel,
  AccountTransactionFilterModel,
  AccountSpecificationsModel,
  PaymentAccountsModel,
  ProductModel,
  ProductSummaryModel,
  PurposeCodesModel,
} from '@models/account.model';

export const FEATURE_KEY = 'account';

export const featureAdapter: EntityAdapter<ProductSummaryModel> =
  createEntityAdapter<ProductSummaryModel>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error?: any;
  selectedAccount?: ProductModel;
  paymentAccounts: PaymentAccountsModel[];
  portfolioSummaryData?: ProductSummaryModel;
  specifications?: AccountSpecificationsModel;
  positions?: AccountPositionsModel[];
  itemsPerPage?: number;
  filter?: AccountTransactionFilterModel;
  purposeCodes?: PurposeCodesModel[];
  appliedFilters?: number;
  documentId?: string;
  documentState?: string;
  transactionsExport?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  itemsPerPage: 20,
  filter: {},
  paymentAccounts: [],
  purposeCodes: [],
  appliedFilters: 0,
  transactionsExport: null,
});
