import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CSP_NONCE,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ConfigService } from '@core/services/config/config.service';
import { CspNonceService } from '@core/services/cspNonce.service';
import { FeatureService } from '@core/services/feature.service';
import { IdleComponent } from '@modules/idle/idle.component';
import { MarketingCampaignModule } from '@modules/marketing-campaign/marketing-campaign.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IdleExpiry, SimpleExpiry } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { SharedModule } from '@shared/shared.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp } from './app.initializer';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { PublicLayoutComponent } from './layout/public/layout.component';
import { SecureHeaderComponent } from './layout/secure/components/header/header.component';
import { SecureNavigationComponent } from './layout/secure/components/navigation/navigation.component';
import { SecurityValueDateComponent } from './layout/secure/components/security-value-date/security-value-date.component';
import { SecureLayoutComponent } from './layout/secure/layout.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    SecureLayoutComponent,
    SecureHeaderComponent,
    SecureNavigationComponent,
    SecurityValueDateComponent,
    IdleComponent,
    StyleGuideComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    MarketingCampaignModule,
    NgIdleKeepaliveModule.forRoot(),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: IdleExpiry,
      useClass: SimpleExpiry,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Sentry.TraceService, ConfigService, FeatureService, HttpClient],
    },
    {
      provide: CSP_NONCE,
      useValue: CspNonceService,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
