import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';
import { PaymentSecSignSessionType } from '@models/auth-session.model';

export function paymentReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_STANDING_ORDERS_REQUEST:
    case ActionTypes.LOAD_PAYMENT_APPROVALS_REQUEST:
    case ActionTypes.LOAD_COUNTRIES_REQUEST:
    case ActionTypes.DELETE_STANDING_ORDER:
    case ActionTypes.GET_PAYMENT_ATTORNEY_CODE_REQUEST:
    case ActionTypes.DECLINE_PAYMENT_APPROVAL_REQUEST:
    case ActionTypes.LOAD_CURRENCIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.GET_FEES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        fees: null,
      };
    }
    case ActionTypes.GET_FEES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        fees: action.payload,
      };
    }
    case ActionTypes.LOAD_CURRENCIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        currencies: action.payload,
      };
    }
    case ActionTypes.LOAD_COUNTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        countries: action.payload,
      };
    }
    case ActionTypes.VALIDATE_ACCOUNT_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        accountInfo: null,
      };
    }
    case ActionTypes.VALIDATE_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        accountInfo: action.payload,
      };
    }
    case ActionTypes.GET_PAYMENT_ATTORNEY_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        attorneyCode: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_PAYMENT_ACCOUNT: {
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedPaymentAccount: action.payload,
      };
    }
    case ActionTypes.LOAD_STANDING_ORDERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        standingOrders: action.payload,
      };
    }
    case ActionTypes.LOAD_PAYMENT_APPROVALS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        paymentApprovals: action.payload,
      };
    }
    case ActionTypes.SET_CREATED_PAYMENT: {
      return {
        ...state,
        isLoading: false,
        error: null,
        formData: action.payload,
        secSignRejectedReason: null,
      };
    }

    case ActionTypes.SET_SORT_CODE_DATA: {
      return {
        ...state,
        isLoading: false,
        error: null,
        sortCodeData: action.payload,
      };
    }
    case ActionTypes.SET_SEC_SIGN_STATUS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        secSignStatus: action.payload,
      };
    }

    case ActionTypes.SET_SEC_SIGN_STATUS_REJECTED_REASON: {
      return {
        ...state,
        isLoading: false,
        error: null,
        secSignRejectedReason: action.payload.additions.reason,
        secSignStatus: action.payload.status,
      };
    }
    case ActionTypes.SET_STANDING_ORDER_DATA: {
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedStandingOrder: action.payload,
      };
    }

    case ActionTypes.SET_STANDING_ORDER_DELETE_ACTION_RESULT: {
      return {
        ...state,
        isLoading: false,
        error: null,
        standingOrderDeleteResult: action.payload,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,

        error: action.payload.error,
      };
    }
    case ActionTypes.DECLINE_PAYMENT_APPROVAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeclinePaymentSuccessful:
          action.payload.status === PaymentSecSignSessionType.ACCEPTED,
      };
    }
    case ActionTypes.APPROVED_PAYMENT_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isApprovedPaymentSuccessful: true,
      };
    }
    case ActionTypes.CLEAR_MESSAGE: {
      return {
        ...state,
        error: null,
        isDeclinePaymentSuccessful: null,
        isApprovedPaymentSuccessful: null,
        secSignRejectedReason: null,
      };
    }
    default: {
      return state;
    }
  }
}
