import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import {
  AccountInfoRequest,
  AccountInfoResponse,
  CountryModel,
  CurrencyModel,
  PaymentApprovalModel,
  StandingOrdersModel,
} from '@models/payment.model';
import {
  PaymentAccountsModel,
  PaymentSortCodeModel,
} from '@models/account.model';
import { PaymentSecSignSessionType } from '@models/auth-session.model';

export class LoadCountriesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_COUNTRIES_REQUEST;
}

export class LoadCountriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COUNTRIES_SUCCESS;

  constructor(private payload: CountryModel[]) {}
}

export class GetSecSignIdForNewPaymentRequestAction implements Action {
  readonly type = ActionTypes.GET_SEC_SIGN_ID_FOR_NEW_PAYMENT_REQUEST;

  constructor(
    public payload: any,
    public saveTemplate: boolean,
    public paymentToBeApproved: boolean = false
  ) {}
}

export class ProcessingPaymentSuccessAction implements Action {
  readonly type = ActionTypes.PROCESS_PAYMENT_ACTION_SUCCESS;

  constructor(private payload: any) {}
}

export class LoadCurrenciesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENCIES_REQUEST;
}

export class LoadCurrenciesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENCIES_SUCCESS;

  constructor(public payload: CurrencyModel[]) {}
}

export class UpdateStandingOrderRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_STANDING_ORDER;

  constructor(
    public payload: any,
    public saveTemplate: boolean,
    public orderId: string
  ) {}
}

export class DeleteStandingOrderRequestAction implements Action {
  readonly type = ActionTypes.DELETE_STANDING_ORDER;

  constructor(public orderId: string) {}
}

export class ValidateAccountInfoRequestAction implements Action {
  readonly type = ActionTypes.VALIDATE_ACCOUNT_INFO_REQUEST;

  constructor(public payload: AccountInfoRequest) {}
}

export class ValidateAccountInfoSuccessAction implements Action {
  readonly type = ActionTypes.VALIDATE_ACCOUNT_INFO_SUCCESS;

  constructor(public payload: AccountInfoResponse) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class SetSelectedPaymentAccount {
  readonly type = ActionTypes.SET_SELECTED_PAYMENT_ACCOUNT;

  constructor(public payload: PaymentAccountsModel | undefined) {}
}

export class SetCreatedPayment {
  readonly type = ActionTypes.SET_CREATED_PAYMENT;

  constructor(public payload: any) {}
}

export class SetStandingOrderData {
  readonly type = ActionTypes.SET_STANDING_ORDER_DATA;

  constructor(public payload: StandingOrdersModel | undefined) {}
}

export class SetSortCodeData {
  readonly type = ActionTypes.SET_SORT_CODE_DATA;

  constructor(public payload: PaymentSortCodeModel) {}
}

export class SetSecSignStatus implements Action {
  readonly type = ActionTypes.SET_SEC_SIGN_STATUS;

  constructor(public payload: any) {}
}

export class SetSecSignStatusRejectedReason implements Action {
  readonly type = ActionTypes.SET_SEC_SIGN_STATUS_REJECTED_REASON;

  constructor(public payload: any) {}
}

export class SetStandingOrderDeleteActionResult implements Action {
  readonly type = ActionTypes.SET_STANDING_ORDER_DELETE_ACTION_RESULT;

  constructor(public payload: PaymentSecSignSessionType) {}
}

export class LoadStandingOrdersRequestAction implements Action {
  readonly type = ActionTypes.LOAD_STANDING_ORDERS_REQUEST;

  constructor(public payload: string) {}
}

export class LoadStandingOrdersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_STANDING_ORDERS_SUCCESS;

  constructor(private payload: StandingOrdersModel[]) {}
}

export class LoadPaymentApprovalsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PAYMENT_APPROVALS_REQUEST;

  constructor(public payload: string) {}
}

export class LoadPaymentApprovalsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PAYMENT_APPROVALS_SUCCESS;

  constructor(private payload: PaymentApprovalModel[]) {}
}

export class DeclinePaymentApprovalRequestAction implements Action {
  readonly type = ActionTypes.DECLINE_PAYMENT_APPROVAL_REQUEST;

  constructor(public accountId: string, public orderId: string) {}
}

export class DeclinePaymentApprovalSuccessAction implements Action {
  readonly type = ActionTypes.DECLINE_PAYMENT_APPROVAL_SUCCESS;

  constructor(public payload: any) {}
}

export class GetPaymentAttorneyCodeRequestAction implements Action {
  readonly type = ActionTypes.GET_PAYMENT_ATTORNEY_CODE_REQUEST;

  constructor(public accountId: string) {}
}

export class GetPaymentAttorneyCodeSuccessAction implements Action {
  readonly type = ActionTypes.GET_PAYMENT_ATTORNEY_CODE_SUCCESS;

  constructor(public payload: string) {}
}

export class CompletePaymentApproveAction implements Action {
  readonly type = ActionTypes.APPROVED_PAYMENT_ACTION_SUCCESS;
}

export class ClearMessageRequestAction implements Action {
  readonly type = ActionTypes.CLEAR_MESSAGE;
}

export class GetFeesRequestAction implements Action {
  readonly type = ActionTypes.GET_FEES_REQUEST;

  constructor(public payload: any) {}
}

export class GetFeesSuccessAction implements Action {
  readonly type = ActionTypes.GET_FEES_SUCCESS;

  constructor(private payload: any) {}
}
