import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { SecureLayoutService } from '@core/services/secure-layout.service';
import { SecureNavigationComponent } from './components/navigation/navigation.component';

@Component({
  selector: 'wmp-layout-secure',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class SecureLayoutComponent implements AfterViewInit {
  @ViewChild(SecureNavigationComponent, { read: ElementRef })
  public navigationElementRef?: ElementRef;

  @ViewChild('routerOutlet')
  public routerOutletElementRef?: ElementRef;

  constructor(private secureLayoutService: SecureLayoutService) {}

  public ngAfterViewInit(): void {
    if (this.navigationElementRef && this.routerOutletElementRef) {
      this.secureLayoutService.navigationElementRef = this.navigationElementRef;
      this.secureLayoutService.routerOutletElementRef =
        this.routerOutletElementRef;
    }
  }
}
