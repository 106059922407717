import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FaqCategory } from '@models/faq.model';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private http: HttpClient) {}

  public getCategories(): Observable<FaqCategory[]> {
    return this.http.get<FaqCategory[]>('/public/api/v1/faq/categories');
  }

  public getPublic(): Observable<FaqCategory[]> {
    return this.http.get<FaqCategory[]>('/public/api/v1/faq/public');
  }
}
