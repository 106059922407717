import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConnectionCheckService } from '@core/services/connection-check.service';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class BackendConnectionInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private connectionCheckService: ConnectionCheckService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((item) => {
        if (item instanceof HttpResponse) {
          this.connectionCheckService.connectedBackendServices.next(true);
        }
      }),
      catchError((err) => {
        this.authService.redirectToLogin(err);
        if ([503, 302, 0].includes(err.status)) {
          this.connectionCheckService.connectedBackendServices.next(false);
        }
        return throwError(err);
      })
    );
  }
}
