import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'totalAmount',
  pure: false,
})
export class TotalAmountPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value?: any, digitsInfo = '1.2-2'): any {
    if (!value) {
      return '';
    }
    const currentLang = this.translateService.currentLang ?? 'en';
    return new DecimalPipe(currentLang).transform(
      value,
      digitsInfo,
      currentLang
    );
  }
}
