<ng-container
  *ngIf="newSigningRequestsCount$ | async as newSigningRequestsCount"
>
  <div
    *ngIf="!isRMP"
    class="alert alert-primary"
    role="alert"
    (click)="navigateSigningRequestPage()"
  >
    {{
      (newSigningRequestsCount === 1
        ? 'new.signature.request.message'
        : 'new.signature.requests.message'
      ) | translate
    }}
  </div>
</ng-container>
