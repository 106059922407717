import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function contentBannerReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.GET_CONTENT_BANNER_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.GET_CONTENT_BANNER_SUCCESS: {
      return {
        ...state,
        content: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.GET_CONTENT_BANNER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
