import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  NavigationActions,
  NavigationSelectors,
} from '@store/navigation/navigation-store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'wmp-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
  public announcements$?: Observable<string[]>;

  private isAnnouncementView = false;
  private translateServiceSubscription?: Subscription;
  private routerSubscription?: Subscription;

  constructor(
    private store$: Store,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // It makes more sense to put the announcements component in the portfolio summary component,
        // but it's a requirement that announcements appear at the top of the notices stack.
        this.isAnnouncementView =
          event.url === '/portfolio/summary' || event.url === '/portfolio';
      }
    });
  }

  public ngOnInit(): void {
    this.announcements$ = this.store$.select(
      NavigationSelectors.selectAnnouncements
    );
    this.store$.dispatch(new NavigationActions.LoadAnnouncementsRequest());
    this.translateServiceSubscription =
      this.translateService.onLangChange.subscribe(() => {
        this.store$.dispatch(new NavigationActions.LoadAnnouncementsRequest());
      });
  }

  public ngOnDestroy(): void {
    this.translateServiceSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  public getIsAnnouncementView(): boolean {
    return this.isAnnouncementView;
  }
}
