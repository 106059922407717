import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Environment } from '@models/auth-session.model';

@Injectable()
export class EnvironmentActivateGuard {
  constructor() {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): any {
    if (this.isRmp()) {
      return this.isRmpLoginSuccessful();
    }

    return true;
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  public isRmpLoginSuccessful() {
    return sessionStorage.getItem('isRmpLoginSuccessful') == 'true';
  }
}
