import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResult } from '@models/serviceResult.model';
import {
  CatsTypes,
  DocumentActionParams,
  DocumentForUpload,
  DocumentsAccount,
  FilterCategoriesResponse,
  PoaUsers,
} from '@models/document.model';
import { DocumentAdapterService } from '@core/services/document/document-adapter.service';
import { FileSaverService } from 'ngx-filesaver';
import { map } from 'rxjs/operators';
import { DateService } from '@core/services/date.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private http: HttpClient,
    private adapter: DocumentAdapterService,
    private fileSaverService: FileSaverService,
    private dateService: DateService
  ) {}

  fetchDocuments(param: any): Observable<any> {
    const querystring = this.querystringBuilder(param);
    return this.http.get<any>(`/public/api/v2/documents${querystring}`).pipe(
      map((list) => {
        return list.map(this.adapter.addMissingId);
      })
    );
  }

  fetchDocumentsForSigning(param: any): Observable<any> {
    let querystring = '';
    if (param.pageSize) {
      querystring = this.appendToQuerystring(
        querystring,
        `size=${param.pageSize}`
      );
    }
    if (param.description) {
      querystring = this.appendToQuerystring(
        querystring,
        `description=${param.description}`
      );
    }
    return this.http
      .get<any>(`/public/api/v2/documents/documents-for-signing${querystring}`)
      .pipe(
        map((list) => {
          return list.map(this.adapter.addDocumentId);
        })
      );
  }

  fetchDocumentUploadHistory(param: any): Observable<any> {
    let querystring = '';
    if (param.pageSize) {
      querystring = this.appendToQuerystring(
        querystring,
        `size=${param.pageSize}`
      );
    }
    if (param.pageNumber) {
      querystring = this.appendToQuerystring(
        querystring,
        `from=${param.pageNumber}`
      );
    }
    if (param.search) {
      querystring = this.appendToQuerystring(
        querystring,
        `search=${param.search}`
      );
    }
    return this.http
      .get<any>(
        `/public/api/v2/documents/documents-upload-history${querystring}`
      )
      .pipe(
        map((list) => {
          return list.map(this.adapter.addDocumentId);
        })
      );
  }

  private querystringBuilder(param: any): string {
    let querystring = '';

    if (param.pageNumber) {
      querystring = this.appendToQuerystring(
        querystring,
        `from=${param.pageNumber}`
      );
    }

    if (param.pageSize) {
      querystring = this.appendToQuerystring(
        querystring,
        `size=${param.pageSize}`
      );
    }

    if (param.search) {
      querystring = this.appendToQuerystring(
        querystring,
        `description=${param.search}`
      );
    }

    if (param.categories && param.categories.length) {
      querystring = this.appendToQuerystring(
        querystring,
        `category=${param.categories}`
      );
    }

    if (param.status) {
      querystring = this.appendToQuerystring(
        querystring,
        `status=${param.status}`
      );
    }

    if (param.dateFrom) {
      querystring = this.appendToQuerystring(
        querystring,
        `fromDate=${param.dateFrom}`
      );
    }

    if (param.dateTo) {
      querystring = this.appendToQuerystring(
        querystring,
        `toDate=${param.dateTo}`
      );
    }

    if (param.accountId) {
      querystring = this.appendToQuerystring(
        querystring,
        `accountId=${param.accountId}`
      );
    }

    return querystring;
  }

  private appendToQuerystring(querystring: string, parameter: string) {
    return (querystring = querystring.length
      ? `${querystring}&${parameter}`
      : `?${parameter}`);
  }

  public handleFileDownload(data: any, payload: any): void {
    let fileName: string = payload.documentNames?.length
      ? payload.documentNames[0]
      : data.headers.get('content-disposition').split('filename=')[1];
    const type =
      payload.documentIds.length > 1 ? 'application/zip' : 'application/pdf';
    const blob = new Blob([data.body], { type });
    if (!fileName.includes('.pdf') && !fileName.includes('.zip')) {
      fileName = fileName.split('.').join('');
    }
    if (payload.documentDates?.length) {
      const documentDate = this.dateService.getDocumentDateFormat(
        payload.documentDates[0]
      );
      fileName = documentDate + '_' + fileName;
    }

    this.fileSaverService.save(blob, fileName);
  }

  download(params: DocumentActionParams): Observable<any> {
    const { documentIds } = params;
    const [documentId] = documentIds;
    if (documentIds.length > 1) {
      return this.downloadZip(params);
    }
    return this.http.request<Blob>(
      'post',
      '/public/api/v2/documents/single-download',
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        body: {
          documentId: documentId,
        },
      }
    );
  }

  downloadSigning(params: DocumentActionParams): Observable<any> {
    const [documentId] = params.documentIds;
    if (params.documentIds.length > 1) {
      return this.downloadZip(params);
    }
    return this.http.request<Blob>(
      'post',
      '/public/api/v2/documents/dig-download',
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        body: {
          documentId: documentId,
        },
      }
    );
  }

  merge(params: DocumentActionParams): Observable<any> {
    return this.http.request<Blob>('post', '/public/api/v2/documents/merge', {
      observe: 'response',
      responseType: 'blob' as 'json',
      body: params.documentIds,
    });
  }

  downloadZip(params: DocumentActionParams): Observable<any> {
    return this.http.request<Blob>(
      'post',
      '/public/api/v2/documents/bulk-download',
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        body: params.documentIds,
      }
    );
  }

  markAsRead(params: DocumentActionParams): Observable<ServiceResult<number>> {
    return this.http.post<ServiceResult<number>>(
      '/public/api/v2/documents/read',
      params.documentIds
    );
  }

  getUnreadCounter(): Observable<number> {
    return this.http.get<number>('/public/api/v2/documents/unread-count');
  }

  signDocument(
    params: DocumentActionParams,
    isMobile = false
  ): Observable<ServiceResult<number>> {
    const { documentIds } = params;
    const [documentId] = documentIds;
    return this.http.post<ServiceResult<number>>(
      `/public/api/v2/documents/sign-document?mobile=${isMobile}`,
      {
        documentId: documentId,
      }
    );
  }

  declineDocument(
    params: DocumentActionParams,
    isMobile = false
  ): Observable<ServiceResult<number>> {
    const { documentIds } = params;
    const [documentId] = documentIds;
    return this.http.post<ServiceResult<number>>(
      `/public/api/v2/documents/deny-document?mobile=${isMobile}`,
      {
        documentId: documentId,
      }
    );
  }

  getSecSignIdForDocumentSigningRequest(
    params: DocumentActionParams,
    isMobile = false
  ): Observable<any> {
    const { documentIds } = params;
    const [documentId] = documentIds;
    return this.http.post<ServiceResult<number>>(
      `/public/api/v2/documents/sign-document?mobile=${isMobile}`,
      {
        documentId: documentId,
      }
    );
  }

  getSecSignState(documentId: string): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/documents/document-state/${documentId}`
    );
  }

  getNewSigningRequestsCount() {
    return this.http.get<number>(
      '/public/api/v2/documents/document-for-signing-count'
    );
  }

  deleteDocForSigning(documentId: string): Observable<any> {
    return this.http.request<Blob>(
      'delete',
      `/public/api/v2/documents/delete-document/${documentId}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        body: {
          documentId: documentId,
        },
      }
    );
  }

  getDigitalDocumentUsers(
    masternumber: any,
    documentTypeId: string
  ): Observable<any> {
    const uri = `/public/api/v2/documents/get-poa-users?masternumber=${masternumber}&documentTypeId=${documentTypeId}`;
    return this.http.request<PoaUsers>('get', uri, {
      observe: 'response',
      responseType: 'json',
      withCredentials: false,
    });
  }

  getCatsTypes(): Observable<any> {
    const uri = `/public/api/v2/documents/get-cats-request-types`;
    return this.http.request<CatsTypes>('get', uri, {
      observe: 'response',
      responseType: 'json',
      withCredentials: false,
    });
  }

  getDocumentsAccounts(): Observable<DocumentsAccount> {
    return this.http.get<DocumentsAccount>('/public/api/v2/documents/accounts');
  }

  uploadDocumentForSignatureFormData(
    uploadData: DocumentForUpload,
    document: Blob
  ): Observable<HttpResponse<any>> {
    const uri = `/public/api/v2/documents/dig-upload-multipart`;
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'text/plain',
      }),
    };

    const formData: any = new FormData();
    formData.append(
      'documentDto',
      new Blob([JSON.stringify(uploadData)], {
        type: 'application/json',
      }),
      'documentDto'
    );
    formData.append('document', document, 'document');

    return this.http.post<any>(uri, formData, httpOptions);
  }

  uploadDocumentInformationFormData(
    uploadData: DocumentForUpload,
    document: Blob
  ): Observable<HttpResponse<any>> {
    const uri = `/public/api/v2/documents/document-info-upload-multipart`;
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'application/json',
      }),
    };

    const formData: any = new FormData();
    formData.append(
      'documentDto',
      new Blob([JSON.stringify(uploadData)], {
        type: 'application/json',
      }),
      'documentDto'
    );
    formData.append('document', document, 'document');

    return this.http.post<any>(uri, formData, httpOptions);
  }

  fetchDocumentCategories(): Observable<FilterCategoriesResponse> {
    return this.http.get<FilterCategoriesResponse>(
      '/public/api/v2/documents/categories'
    );
  }
}
