import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MasterNumberModel } from '@models/navigation.model';

export const FEATURE_KEY = 'navigation';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error?: any;
  language: string;
  masterNumbers: MasterNumberModel[];
  selectedMasterNumber?: MasterNumberModel;
  selectedLanguage?: string;
  isDemoUser: boolean;
  announcements: string[];
  isNewMasterNumberSet: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  isDemoUser: false,
  error: null,
  language: '',
  masterNumbers: [],
  announcements: [],
  isNewMasterNumberSet: false,
});
