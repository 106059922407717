import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { PortfolioSpecificationsDto } from '@models/specification.model';
import { FEATURE_KEY, featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getSpecifications = (state: State): any => state.specifications;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectSpecifications: MemoizedSelector<
  object,
  PortfolioSpecificationsDto
> = createSelector(selectState, getSpecifications);
