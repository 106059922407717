import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { PaymentAccountsModel, PurposeCodesModel } from '@models/account.model';
import { FEATURE_KEY, featureAdapter, State } from './state';
import { PortfolioTransactionModel } from '@models/transactions.model';

export const getError = (state: State): any => state.error;
export const getPortfolioSummaryData = (state: State): any =>
  state.portfolioSummaryData;
export const getSpecifications = (state: State): any => state.specifications;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getPaymentAccounts = (state: State): any => state.paymentAccounts;
export const getPurposeCodes = (state: State): any => state.purposeCodes;
export const getAppliedFilters = (state: State): any => state.appliedFilters;
export const getTransactionsExport = (state: State): any =>
  state.transactionsExport;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectPortfolioSummaryData: MemoizedSelector<object, any> =
  createSelector(selectState, getPortfolioSummaryData);

export const selectPaymentAccounts: MemoizedSelector<
  object,
  PaymentAccountsModel[]
> = createSelector(selectState, getPaymentAccounts);

export const selectPurposeCodes: MemoizedSelector<object, PurposeCodesModel[]> =
  createSelector(selectState, getPurposeCodes);

export const selectTransactionExport: MemoizedSelector<
  object,
  PortfolioTransactionModel[]
> = createSelector(selectState, getTransactionsExport);

export const selectSpecifications: MemoizedSelector<object, any> =
  createSelector(selectState, getSpecifications);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectItemsPerPage = createSelector(
  selectState,
  (state: State): any => state.itemsPerPage
);

export const selectFilter = createSelector(
  selectState,
  (state: State): any => state.filter
);

export const selectAppliedFilters = createSelector(
  selectState,
  (state: State): any => state.appliedFilters
);

export const getSelectedAccount = createSelector(
  selectState,
  (state: State): any => state.selectedAccount
);

export const selectAccountPositions = createSelector(
  selectState,
  (state: State): any => state.positions
);

export const selectFilterAndPage = createSelector(
  selectItemsPerPage,
  selectFilter,
  (itemsPerPage, filter) => {
    return {
      itemsPerPage,
      filter,
    };
  }
);

export const selectDocumentState = createSelector(
  selectState,
  (state: State): any => state.documentState
);

export const selectDocumentId = createSelector(
  selectState,
  (state: State): any => state.documentId
);

export const selectTransactionPrintStatus = createSelector(
  selectDocumentState,
  selectDocumentId,
  (documentState, documentId) => {
    return {
      documentState,
      documentId,
    };
  }
);

export const selectPaymentAccountsWithLoading = createSelector(
  selectPaymentAccounts,
  selectIsLoading,
  (paymentAccounts: PaymentAccountsModel[], isLoading: boolean) => {
    return {
      paymentAccounts,
      isLoading,
    };
  }
);
