export interface LoginRequestModel {
  login: string;
  pwd: string;
}

export interface LoginResultModel {
  state: STATE_TYPE;
  changePassword: boolean;
  secSignId: string;
  authSession: string;
}

export enum STATE_TYPE {
  AUTHENTICATED = 'AUTHENTICATED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  PENDING_2FA = 'PENDING_2FA',
  FAILED = 'FAILED',
  ONBOARDING_NEEDED = 'ONBOARDING_NEEDED',
  NO_STATE = 'NO_STATE',
}

export interface UserSessionResponse {
  state: STATE_TYPE;
  expires: string;
  expiresIn: string;
  role: string;
}

export interface OnboardingResponse {
  onboardingState: ONBOARDING_STATE;
  changePwd: boolean;
}

export enum ONBOARDING_STATE {
  ONBOARDING_NEEDED = 'ONBOARDING_NEEDED',
  ONBOARDING_FINISHED = 'ONBOARDING_FINISHED',
  INIT_ONBOARDING_FAILED = 'INIT_ONBOARDING_FAILED',
  NO_STATE = 'NO_STATE',
}
