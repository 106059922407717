import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AuthSessionType,
  PaymentSecSignSessionType,
} from '@models/auth-session.model';
import { PaymentOperation } from '@models/payment.model';

@Component({
  selector: 'wmp-2fa-confirm-operation-modal',
  templateUrl: './2fa-confirm-operation-modal.component.html',
  styleUrls: ['./2fa-confirm-operation-modal.component.scss'],
})
export class TwoFaConfirmOperationModalComponent implements OnDestroy {
  @Input()
  public operationPendingTitleI18nKey!: string;
  @Input()
  public operationPendingTextI18nKey!: string;
  @Input()
  public operationConfirmedTitleI18nKey!: string;
  @Input()
  public operationConfirmedTextI18nKey!: string;

  @Input()
  public confirmButtonI18nKey = 'general.button.yes';
  @Input()
  public cancelButtonI18nKey = 'general.button.no';

  @Output()
  public confirmationEmitter: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public twoFactorStatus!: any;
  @Input()
  public paymentOperation!: PaymentOperation;

  public AuthSessionType = AuthSessionType;
  public PaymentOperation = PaymentOperation;

  public ngOnDestroy(): void {
    this.twoFactorStatus = undefined;
  }

  public confirm(): void {
    this.confirmationEmitter.emit(true);
  }

  public cancel(): void {
    this.confirmationEmitter.emit(this.isOperationConfirmed());
  }

  public isOperationConfirmed() {
    return this.twoFactorStatus === PaymentSecSignSessionType.PROCESSED;
  }
}
