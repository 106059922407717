import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionCheckService {
  constructor() {
    this.monitorInternetConnection();
  }
  connectedBackendServices = new BehaviorSubject<boolean>(true);
  connectedBackendServices$: any = this.connectedBackendServices.asObservable();

  connectedInternet = new BehaviorSubject<boolean>(true);
  connectedInternet$: any = this.connectedInternet.asObservable();

  createOnline$() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  private monitorInternetConnection() {
    this.createOnline$().subscribe((isOnline) => {
      this.connectedInternet.next(isOnline);
    });
  }
}
