import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { FaqService } from '@core/services/faq/faq.service';
import * as FeatureActions from './actions';
import * as ActionTypes from './actionTypes';

@Injectable()
export class FaqEffects {
  loadCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_CATEGORIES_REQUEST),
      mergeMap(() =>
        this.service.getCategories().pipe(
          map((data) => new FeatureActions.LoadCategoriesSuccess(data)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadCategoriesFailure({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadPublic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_PUBLIC_REQUEST),
      mergeMap(() =>
        this.service.getPublic().pipe(
          map((data) => new FeatureActions.LoadPublicSuccess(data)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadPublicFailure({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private service: FaqService
  ) {}
}
