import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FEATURE_KEY, State } from './state';
import { CampaignModel } from '@models/marketing-campaign.model';

export const getError = (state: State): string | null => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getCurrentCampaign = (state: State): CampaignModel | null =>
  state.currentCampaign;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);
export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
export const selectCurrentCampaign: MemoizedSelector<
  object,
  CampaignModel | null
> = createSelector(selectState, getCurrentCampaign);
