import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects';
import { AuthReducer } from './reducer';
import { FEATURE_KEY } from './state';
import { metaReducers } from '@store/_meta-reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, AuthReducer, {
      metaReducers,
    }),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthStoreModule {}
