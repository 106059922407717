import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as FeatureActions from './actions';
import { throwError } from 'rxjs';
import * as ActionTypes from './actionTypes';
import { Store } from '@ngrx/store';
import { RelationshipInfoService } from '@core/services/relationshipinfo/relationship-info.service';

@Injectable()
export class RelationshipinfoEffects {
  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_REQUEST),
      mergeMap(() => {
        return this.service.fetchRelationshipInfo().pipe(
          map((result) => new FeatureActions.LoadSuccessAction(result)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private service: RelationshipInfoService
  ) {}
}
