import { AuthSessionType } from '@models/auth-session.model';

export const FEATURE_KEY = 'auth';

export interface State {
  isPolling2fa: boolean;
  isLoggedIn: any;
  error: any;
  secSignStatus?: AuthSessionType;
  resetpwd: boolean;
  isLoading: boolean;
  roles: string[];
  environment: string;
}

export const initialState: State = {
  isPolling2fa: false,
  isLoggedIn: null,
  error: null,
  secSignStatus: AuthSessionType.SESSION_STATE_NOSTATE,
  resetpwd: false,
  isLoading: false,
  roles: [],
  environment: '',
};

export const MockAuthState: State = {
  isPolling2fa: true,
  isLoggedIn: true,
  error: '',
  secSignStatus: AuthSessionType.SESSION_STATE_AUTHENTICATED,
  resetpwd: false,
  isLoading: false,
  roles: [],
  environment: '',
};
