<ng-container *ngIf="isConfirmationScreenPending()">
  <h1 id="TwoFaTitle">{{ 'two.factor.modal.title' | translate }}</h1>
  <p id="TwoFaSubTitle">{{ 'two.factor.modal.content' | translate }}</p>
  <wmp-loading-indicator></wmp-loading-indicator>
  <button
    aria-label="Cancel"
    class="cancel"
    (click)="cancelAuthSession()"
  ></button>
</ng-container>
