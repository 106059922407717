export const LOAD_TEMPLATES_REQUEST = '[Template] Load Templates Request';
export const LOAD_TEMPLATES_SUCCESS = '[Template] Load Templates Success';
export const DELETE_TEMPLATE_REQUEST = '[Template] Delete Template Request';
export const DELETE_TEMPLATE_SUCCESS = '[Template] Delete Template Success';
export const EDIT_TEMPLATE_REQUEST = '[Template] Edit Template Request';
export const EDIT_TEMPLATE_SUCCESS = '[Template] Edit Template Success';
export const CREATE_NEW_TEMPLATE_REQUEST =
  '[Template] Create New Template Request';
export const CREATE_NEW_TEMPLATE_SUCCESS =
  '[Template] Create New Template Success';
export const LOAD_TEMPLATES_BY_IBAN_REQUEST =
  '[Template] Load Templates By Iban Request';
export const LOAD_TEMPLATES_BY_IBAN_SUCCESS =
  '[Template] Load Templates By Iban Success';
export const SET_TEMPLATE_DATA = '[Template] Set Template Data';
export const CLEAR_ERROR = '[Template] Clear Error';
export const LOAD_FAILURE = '[Template] Load Failure';
