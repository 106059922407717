import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PortfolioAdapterService {
  mapData(item: any): any {
    return {
      name: item.name,
      y: item.allocationPct,
    };
  }
}
