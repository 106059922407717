import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './state';
import { rmpReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { RmpEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, rmpReducer),
    EffectsModule.forFeature([RmpEffects]),
  ],
})
export class RmpStoreModule {}
