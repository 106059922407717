import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountInfoResponse,
  CountryResponse,
  CurrencyResponse,
} from '@models/payment.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OldPaymentService {
  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  getCurrencies(): Observable<CurrencyResponse> {
    return this.http.get<any>('/public/api/v2/currencies');
  }

  getCountries(): Observable<CountryResponse> {
    return this.http.get<any>('/public/api/v2/countries');
  }

  validateAccountInfo(accountInfo: any): Observable<AccountInfoResponse> {
    const queryParams = new HttpParams({ fromObject: accountInfo });
    return this.http.get<any>('/public/api/v2/validate-account-info', {
      params: queryParams,
    });
  }

  getSecSignIdForNewPayment(
    payload: any,
    saveTemplate = false,
    paymentToBeApproved = false
  ): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/payment-orders?template=${saveTemplate}&paymentToBeApproved=${paymentToBeApproved}`,
      payload
    );
  }

  getFees(payload: any): Observable<any> {
    return this.http.post<any>(`/public/api/v2/payment-orders/fees`, payload);
  }

  getSecSignState(
    sessionId: string,
    paymentToBeApproved = false
  ): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/payment-order-state?id=${sessionId}&paymentForApproval=${paymentToBeApproved}`
    );
  }

  getStandingOrders(accountId: string) {
    return this.http.get<any>(
      `/public/api/v2/payment-orders/account/${accountId}`
    );
  }

  updateStandingOrder(
    standingOrder: any,
    saveTemplate: boolean,
    orderId: string
  ): Observable<any> {
    return this.http.put<any>(
      `/public/api/v2/payment-orders/${orderId}?template=${saveTemplate}`,
      standingOrder
    );
  }

  deleteStandingOrder(paymentOrderId: string): Observable<boolean> {
    return this.http.delete<any>(
      `/public/api/v2/payment-orders/${paymentOrderId}`
    );
  }

  getPaymentApprovals(accountId: string) {
    return this.http.get<any>(
      `/public/api/v2/payment-approval-orders/${accountId}`
    );
  }

  declinePaymentApproval(accountId: string, orderId: string) {
    return this.http.put<any>(
      `/public/api/v2/payment-approval-orders/${orderId}?accountId=${accountId}`,
      {}
    );
  }

  getPaymentAttorneyCode(accountId: string) {
    return this.http.get<any>(`/public/api/v2/attorneycode/${accountId}`, {
      responseType: 'text' as 'json',
    });
  }
}
