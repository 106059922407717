import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

const STORE_KEY = 'userLastAction';

@Injectable({
  providedIn: 'root',
})
export class NgIdleService implements OnDestroy {
  public static runTimer: boolean;
  public static runSecondTimer: boolean;
  public USER_IDLE_TIMER_VALUE_IN_MIN!: number;
  public FINAL_LEVEL_TIMER_VALUE_IN_MIN!: number;
  public userIdlenessChecker = new BehaviorSubject<string>('');
  public secondLevelUserIdleChecker = new BehaviorSubject<string>('');

  private sessionForIdle!: Observable<number>;
  private userActivityChangeCallback!: ($event: any) => void;

  public clockForIdle!: Observable<number>;

  public diffIdle = -1;

  constructor(private zone: NgZone) {
    if (this.userIdlenessChecker.value == '') {
      this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    }

    if (this.secondLevelUserIdleChecker.value == '') {
      this.secondLevelUserIdleChecker = new BehaviorSubject<string>(
        'INITIATE_SECOND_TIMER'
      );
    }
  }

  public initilizeSessionTimeout(): void {
    NgIdleService.runTimer = true;

    if (this.USER_IDLE_TIMER_VALUE_IN_MIN === 0) {
      this.userIdlenessChecker.thrownError(
        'Please provide USER_IDLE_TIMER_VALUE in minute'
      );
      return;
    }

    this.initLastAction();
    this.reset();
    this.initListener();
    this.initInterval();
  }

  initLastAction() {
    this.lastAction = Date.now();
  }

  public get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY) as string, 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  public initDiffIdle() {
    this.diffIdle = -1;
  }

  public getDiffIdle() {
    return this.diffIdle;
  }

  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = ($event) =>
        this.handleUserActiveState($event);
      this.addEvents();
    });
  }

  private addEvents(): void {
    window.document.addEventListener(
      'keydown',
      this.userActivityChangeCallback.bind(this),
      true
    );
    window.document.addEventListener(
      'keypress',
      this.userActivityChangeCallback.bind(this),
      true
    );
    window.document.addEventListener(
      'scroll',
      this.userActivityChangeCallback.bind(this),
      true
    );
    window.document.addEventListener(
      'click',
      this.userActivityChangeCallback.bind(this),
      true
    );
  }

  handleUserActiveState(event: any): void {
    this.reset();
  }

  public reset(): void {
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
  }

  private initInterval(): void {
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => NgIdleService.runTimer)
    );

    this.check();
  }

  private check(): void {
    this.sessionForIdle.subscribe(() => {
      const now = Date.now();
      const timeleft =
        this.lastAction + this.USER_IDLE_TIMER_VALUE_IN_MIN * 60 * 1000;
      const diff = timeleft - now;
      this.diffIdle = diff;
      const isTimeout = diff < 0;

      this.userIdlenessChecker.next(`${diff}`);

      if (isTimeout) {
        this.removeEvents();
        this.zone.run(() => {
          if (this.userIdlenessChecker) {
            this.userIdlenessChecker.next('STOPPED_TIMER');

            if (this.FINAL_LEVEL_TIMER_VALUE_IN_MIN > 0) {
              this.secondLevelUserIdleChecker.next('SECOND_TIMER_STARTED');
              this.executeFinalTimer();
            }
          }
          NgIdleService.runTimer = false;
        });
      }
    });
  }

  public removeEvents(): void {
    window.document.removeEventListener(
      'keydown',
      this.userActivityChangeCallback,
      true
    );
    window.document.removeEventListener(
      'keypress',
      this.userActivityChangeCallback,
      true
    );
    window.document.removeEventListener(
      'scroll',
      this.userActivityChangeCallback,
      true
    );
    window.document.removeEventListener(
      'click',
      this.userActivityChangeCallback,
      true
    );
  }

  public removeActionFromStore(): void {
    localStorage.removeItem(STORE_KEY);
  }

  private executeFinalTimer = () => {
    NgIdleService.runSecondTimer = true;
    this.initializeFinalTimer();
  };

  private initializeFinalTimer(): void {
    const intervalDuration = 1000;
    this.clockForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => NgIdleService.runSecondTimer)
    );

    this.checkUserActionTime();
  }

  private checkUserActionTime(): void {
    let timeInSecond = 60;
    let timeInMin = this.FINAL_LEVEL_TIMER_VALUE_IN_MIN - 1;
    this.clockForIdle.subscribe(() => {
      if (--timeInSecond === 0) {
        if (--timeInMin === 0) {
          timeInMin = timeInMin > 0 ? timeInMin - 1 : 0;
        }
        if (timeInMin === -1 && timeInSecond === 0) {
          NgIdleService.runSecondTimer = false;

          if (this.secondLevelUserIdleChecker) {
            this.secondLevelUserIdleChecker.next('SECOND_TIMER_STOPPED');
          }
        }
        if (timeInMin < 0) {
          timeInMin = 0;
          setTimeout(() => {
            timeInSecond = 60;
          }, 800);
        } else {
          timeInSecond = 60;
        }
      }

      this.secondLevelUserIdleChecker.next(`${timeInMin}:${timeInSecond}`);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeBehavioural();
  }

  public unsubscribeBehavioural() {
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.unsubscribe();
      this.userIdlenessChecker = new BehaviorSubject<string>('');
    }

    if (this.secondLevelUserIdleChecker) {
      this.secondLevelUserIdleChecker.unsubscribe();
      this.secondLevelUserIdleChecker = new BehaviorSubject<string>('');
    }
  }
}
