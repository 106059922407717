export const GENERAL_DATA_PROTECTION_EN =
  'https://www.berenberg.de/en/data-protection/';
export const GENERAL_DATA_PROTECTION_DE =
  'https://www.berenberg.de/datenschutz/';

export const DATA_PROTECTION_EN = 'https://berenberg.de/en/privacy-policy.pdf';
export const DATA_PROTECTION_DE =
  'https://berenberg.de/datenschutzerklaerung.pdf';

export const SECURITY_INFORMATION_EN = 'https://www.berenberg.de/en/security/';
export const SECURITY_INFORMATION_DE = 'https://www.berenberg.de/sicherheit/';

export const LEGAL_NOTICE_EN = 'https://www.berenberg.de/en/legal-notice/';
export const LEGAL_NOTICE_DE = 'https://www.berenberg.de/rechtliche-hinweise/';

//App & Play Store Links
export const APP_STORE_LINK =
  'https://apps.apple.com/de/app/berenberg-wealth-management/id1486153194';
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=de.berenberg.banking_app.prod';

export const MAINTENANCE_WMP = 'https://maintenancewmp.azurewebsites.net';
