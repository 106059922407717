import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function templateReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_TEMPLATES_REQUEST:
    case ActionTypes.DELETE_TEMPLATE_REQUEST:
    case ActionTypes.EDIT_TEMPLATE_REQUEST:
    case ActionTypes.CREATE_NEW_TEMPLATE_REQUEST:
    case ActionTypes.LOAD_TEMPLATES_BY_IBAN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_TEMPLATES_BY_IBAN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        transactions: action.payload,
      };
    }
    case ActionTypes.LOAD_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        templates: action.payload,
      };
    }

    case ActionTypes.DELETE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeletionSuccessful: true,
      };
    }

    case ActionTypes.EDIT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isEditingSuccessful: true,
      };
    }

    case ActionTypes.CREATE_NEW_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isCreatingSuccessful: true,
      };
    }

    case ActionTypes.SET_TEMPLATE_DATA: {
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedTemplate: action.payload,
      };
    }
    case ActionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
        isLoading: false,
        isDeletionSuccessful: null,
        isEditingSuccessful: null,
        isCreatingSuccessful: null,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
