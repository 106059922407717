import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RmpClientModel, RmpDocument } from '@models/rmp.model';

@Injectable({
  providedIn: 'root',
})
export class RmpDataService {
  constructor(private http: HttpClient) {}

  public getClients(payload: {
    search: string;
    username: string;
  }): Observable<RmpClientModel[]> {
    return this.http.get<RmpClientModel[]>(
      `/public/api/v1/rmp/clients?ntUsername=${payload.username}&search=${payload.search}`
    );
  }

  public getRmpDocuments(): Observable<RmpDocument[]> {
    return this.http.get<RmpDocument[]>('/public/api/v1/rmp/documents');
  }
}
