import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DocumentActions, DocumentSelectors } from '@store/document-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-unread-counter',
  templateUrl: './wmp-unread-counter.component.html',
  styleUrls: ['./wmp-unread-counter.component.scss'],
})
export class WmpUnreadCounterComponent implements OnInit, OnDestroy {
  public value = 0;
  public tooltipTextCode = '';
  private unreadSubcription?: Subscription;

  constructor(private store$: Store, private translate: TranslateService) {}

  ngOnInit(): void {
    this.unreadSubcription = this.store$
      .select(DocumentSelectors.selectUnreadItems)
      .subscribe((unread) => {
        this.value = unread;
        this.tooltipTextCode = this.getTooltipTextCode();
      });
    this.store$.dispatch(new DocumentActions.RequestUnreadCounter());
  }

  ngOnDestroy(): void {
    this.unreadSubcription?.unsubscribe();
  }

  getTooltipTextCode(): string {
    return this.value > 1
      ? this.translate.instant('documents.unreadCounterTooltip.plural')
      : this.translate.instant('documents.unreadCounterTooltip.singular');
  }
}
