import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import {
  RelationshipinfoAction,
  relationshipinfoSelectors,
} from '@store/navigation/relationshipinfo-store';
import { RelationshipInfo } from '@models/relationshipinfo.model';
import { SessionStorageService } from '@core/services/session-storage-util.service';
import { Environment } from '@models/auth-session.model';
import { FeatureService } from '@core/services/feature.service';

@Component({
  selector: 'wmp-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class SecureNavigationComponent implements OnInit, OnDestroy {
  relationshipInfo$: Observable<RelationshipInfo | undefined> | undefined;
  rmPicture: any;

  private relationshipInfoSubscription?: Subscription;

  constructor(
    private store$: Store,
    private domSanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.relationshipInfo$ = this.store$.select(
      relationshipinfoSelectors.selectFirst
    );
    this.store$.dispatch(new RelationshipinfoAction.LoadRequestAction());
    this.relationshipInfoSubscription = this.relationshipInfo$.subscribe(
      (relationshipInfo: any) => {
        const data = `data: image/jpeg;base64,${relationshipInfo?.managerProfilePicture}`;
        this.rmPicture = this.domSanitizer.bypassSecurityTrustResourceUrl(data);
      }
    );
  }

  ngOnDestroy(): void {
    this.relationshipInfoSubscription?.unsubscribe();
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  public isAClientSelected() {
    return this.isRmp() ? this.sessionStorageService.isAClientSelected() : true;
  }

  public isKaiaEnabled(): boolean {
    return this.featureService.getFeatureToggleByKey('ENABLE_KAIA');
  }
}
