import * as ActionTypes from './actionTypes';
import { featureAdapter, initialState, State } from './state';

export function relationshipInfoReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return featureAdapter.setOne(action.payload, {
        ...state,
        metadata: action.payload,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.RESET_DATA: {
      return featureAdapter.removeAll({ ...state, metadata: null });
    }
    default: {
      return state;
    }
  }
}
