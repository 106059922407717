import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  LOCAL_EN_DATE_FORMAT,
  LOCAL_DE_DATE_FORMAT,
  LANGUAGE_CODE_DE,
  LANGUAGE_CODE_EN,
} from '@core/constants/defaults';

@Pipe({
  name: 'localeDateFormat',
  pure: false,
})
export class LocaleDateFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    if (!this.isValidDate(value)) return null;
    if (this.translateService.currentLang === LANGUAGE_CODE_DE)
      return new DatePipe(LANGUAGE_CODE_DE).transform(
        value,
        LOCAL_DE_DATE_FORMAT
      );
    else
      return new DatePipe(LANGUAGE_CODE_EN).transform(
        value,
        LOCAL_EN_DATE_FORMAT
      );
  }

  isValidDate(date: any) {
    const dateWrapper = new Date(date);
    return (
      date &&
      Object.prototype.toString.call(dateWrapper) === '[object Date]' &&
      !isNaN(dateWrapper.getTime())
    );
  }
}
