export const LOAD_COUNTRIES_REQUEST = '[Payment] Load Countries Request';
export const LOAD_COUNTRIES_SUCCESS = '[Payment] Load Countries Success';
export const LOAD_STANDING_ORDERS_REQUEST =
  '[Payment] Load Standing Orders Request';
export const LOAD_STANDING_ORDERS_SUCCESS =
  '[Payment] Load Standing Orders Success';
export const LOAD_PAYMENT_APPROVALS_REQUEST =
  '[Payment] Load Payment Approvals Request';
export const LOAD_PAYMENT_APPROVALS_SUCCESS =
  '[Payment] Load Payment Approvals Success';
export const LOAD_CURRENCIES_REQUEST = '[Payment] Load Currencies Request';
export const LOAD_CURRENCIES_SUCCESS = '[Payment] Load Currencies Success';
export const VALIDATE_ACCOUNT_INFO_REQUEST =
  '[Payment] Validate Account Info Request';
export const VALIDATE_ACCOUNT_INFO_SUCCESS =
  '[Payment] Validate Account Info Success';
export const SET_SELECTED_PAYMENT_ACCOUNT =
  '[Payment] Set Selected Payment Account';
export const SET_SORT_CODE_DATA = '[Payment] Set Sort Code Data';
export const SET_CREATED_PAYMENT = '[Payment] Set Created Payment';
export const GET_SEC_SIGN_ID_FOR_NEW_PAYMENT_REQUEST =
  '[Payment] Get SecSign Id For New Payment Request';
export const PROCESS_PAYMENT_ACTION_SUCCESS =
  '[Payment] Process Payment Action Success';
export const SET_SEC_SIGN_STATUS = '[Payment] Set SecSign Status';
export const SET_SEC_SIGN_STATUS_REJECTED_REASON =
  '[Payment] Set SecSign Status Rejected Reason';
export const SET_STANDING_ORDER_DATA = '[Payment] Set Standing Order Data';
export const SET_STANDING_ORDER_DELETE_ACTION_RESULT =
  '[Payment] Set Standing Order Delete Action Result';
export const UPDATE_STANDING_ORDER = '[Payment] Update Standing Order';
export const DELETE_STANDING_ORDER = '[Payment] Delete Standing Order';
export const DECLINE_PAYMENT_APPROVAL_REQUEST =
  '[Payment] Decline Payment Approval Request';
export const DECLINE_PAYMENT_APPROVAL_SUCCESS =
  '[Payment] Decline Payment Approval Success';
export const APPROVED_PAYMENT_ACTION_SUCCESS =
  '[Payment] Approved Payment Action Success';
export const GET_PAYMENT_ATTORNEY_CODE_REQUEST =
  '[Payment] Get Payment Attorney Code Request';
export const GET_PAYMENT_ATTORNEY_CODE_SUCCESS =
  '[Payment] Get Payment Attorney Code Success';
export const GET_FEES_REQUEST = '[Payment] Get Fees Request';
export const GET_FEES_SUCCESS = '[Payment] Get Fees Success';
export const CLEAR_MESSAGE = '[Payment] Clear Message';
export const LOAD_FAILURE = '[Payment] Load Failure';
