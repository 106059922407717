import { RelationshipInfo } from '@models/relationshipinfo.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'relationshipinfo';

export const featureAdapter: EntityAdapter<RelationshipInfo> =
  createEntityAdapter<RelationshipInfo>({
    selectId: (e) => e.emailAddress,
  });

export interface State extends EntityState<RelationshipInfo> {
  isLoading?: boolean;
  error?: any;
  metadata?: any;
  managerName: string;
  phonenumber: string;
  emailAddress: string;
  managerProfilePicture: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  metadata: null,
  managerName: '-',
  phonenumber: '-',
  emailAddress: '-',
  managerProfilePicture: '',
});
