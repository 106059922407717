import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientDto, ClientsDto } from '@models/entitlements/client.model';
import { MasterNumberModel } from '@models/navigation.model';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureService } from '../feature.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private http: HttpClient,
    private featureService: FeatureService,
    private cookieService: CookieService
  ) {}

  fetchMasterNumbers(): Observable<any[]> {
    const retValue: MasterNumberModel[] = [];
    return this.http
      .get<ClientsDto>('/api/v1-legacy/entitlements/clients')
      .pipe(
        map((result) => {
          result.clients.forEach((client) => {
            retValue.push(this.transformClientDto(client, false));
          });
          return retValue;
        })
      );
  }

  setSelectedMasterNumber(masterNumber: string): Observable<any> {
    return this.http.put<any>(
      `/api/v1-legacy/entitlements/selected-client/${masterNumber}`,
      {}
    );
  }

  getSelectedMasterNumber(): Observable<any> {
    return this.http
      .get<ClientsDto>('/api/v1-legacy/entitlements/clients')
      .pipe(
        map((result) => {
          return this.transformClientDto(result.selectedClient, true);
        })
      );
  }

  private transformClientDto(
    client: ClientDto,
    selected: boolean
  ): MasterNumberModel {
    const retValue: MasterNumberModel = {
      id: client.id,
      masterNumber: client.masterNo,
      defaultNumber: false,
      language: '',
      name: client.name,
      selected: selected,
    };
    return retValue;
  }

  getSelectedLanguage(): Observable<any> {
    return this.http.get<any>('/api/v1-legacy/entitlements/selected-language');
  }

  setLanguage(language: string): Observable<any> {
    return this.http.put<any>(
      `/api/v1-legacy/entitlements/selected-language?language=${language}`,
      {
        language: language,
      }
    );
  }

  isDemoUser(): Observable<boolean> {
    return this.http.get<boolean>(`/public/api/v1/navigation/demo-user`);
  }

  getAnnouncements(): Observable<string[]> {
    return this.http.get<string[]>(`/public/api/v1/navigation/announcements`);
  }

  logMarketingCampaignClosed() {
    const body = {
      message: 'banner_close',
      category: 'marketing_content',
      session: '',
      plattform: 'Desktop',
      logLevel: 'INFO',
      clientTimestamp: new Date(),
    };
    return this.http
      .post<any>(`/public/api/v1/navigation/client-event`, body)
      .subscribe();
  }

  logMarketingCampaignClicked() {
    const body = {
      message: 'banner_open_link',
      category: 'marketing_content',
      session: '',
      plattform: 'Desktop',
      logLevel: 'INFO',
      clientTimestamp: new Date(),
    };
    return this.http
      .post(`/public/api/v1/navigation/client-event`, body)
      .subscribe();
  }
}
