import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DocumentAdapterService {
  addMissingId(item: any): any {
    if (item.id) {
      return item;
    }
    return {
      ...item,
      id: uuid(),
    };
  }

  addDocumentId(item: any): any {
    if (item.id) {
      return item;
    }
    return {
      ...item,
      id: item.documentId,
    };
  }
}
