import { Component, OnInit } from '@angular/core';
import { DocumentActions, DocumentSelectors } from '@store/document-store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Environment } from '@models/auth-session.model';

@Component({
  selector: 'wmp-new-signature-request',
  templateUrl: './new-signature-request.component.html',
  styleUrls: ['./new-signature-request.component.scss'],
})
export class NewSignatureRequestComponent implements OnInit {
  isRMP = false;

  public newSigningRequestsCount$: Observable<number> = of(0);

  constructor(private store$: Store, private router: Router) {}

  public ngOnInit(): void {
    this.isRMP = this.isRmp();
    this.newSigningRequestsCount$ = this.store$.select(
      DocumentSelectors.selectNewSigningRequestsCount
    );
    this.store$.dispatch(new DocumentActions.NewSigningRequestsCountRequest());
  }

  public navigateSigningRequestPage() {
    this.router.navigate(['/postbox/signing-requests']);
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }
}
