import { Component, OnDestroy } from '@angular/core';
import { IdleService } from '@modules/idle/idle.services';

@Component({
  selector: 'wmp-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss'],
})
export class IdleComponent implements OnDestroy {
  constructor(private idleService: IdleService) {}

  stay(): void {
    this.idleService.stay();
  }

  getIdleState(): string {
    return this.idleService.getIdleState();
  }

  ngOnDestroy(): void {
    this.idleService.onDestroy();
  }
}
