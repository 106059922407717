import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { MasterNumberModel } from '@models/navigation.model';

export class SetLanguageRequestAction implements Action {
  readonly type = ActionTypes.SET_LANGUAGE_REQUEST;
  constructor(public payload: string) {}
}

export class SetLanguageSuccessAction implements Action {
  readonly type = ActionTypes.SET_LANGUAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadMasterNumbersRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MASTER_NUMBERS_REQUEST;
}

export class LoadMasterNumbersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MASTER_NUMBERS_SUCCESS;
  constructor(public payload: MasterNumberModel[]) {}
}

export class SetMasterNumberRequestAction implements Action {
  readonly type = ActionTypes.SET_MASTER_NUMBER_REQUEST;
  constructor(public payload: string) {}
}

export class SetMasterNumberSuccessAction implements Action {
  readonly type = ActionTypes.SET_MASTER_NUMBER_SUCCESS;
}

export class GetSelectedMasterNumberRequestAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_MASTER_NUMBER_REQUEST;
}

export class GetSelectedMasterNumberSuccessAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_MASTER_NUMBER_SUCCESS;
  constructor(public payload: MasterNumberModel) {}
}

export class GetSelectedLanguageRequestAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_LANGUAGE_REQUEST;
}

export class GetSelectedLanguageSuccessAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_LANGUAGE_SUCCESS;
  constructor(public payload: string) {}
}

export class IsDemoUserRequestAction implements Action {
  readonly type = ActionTypes.IS_DEMO_USER_REQUEST;
}

export class IsDemoUserSuccessAction implements Action {
  readonly type = ActionTypes.IS_DEMO_USER_SUCCESS;
  constructor(public payload: boolean) {}
}

export class FailureAction implements Action {
  readonly type = ActionTypes.SET_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadAnnouncementsRequest implements Action {
  readonly type = ActionTypes.LOAD_ANNOUNCEMENTS_REQUEST;
}

export class LoadAnnouncementsSuccess implements Action {
  readonly type = ActionTypes.LOAD_ANNOUNCEMENTS_SUCCESS;
  constructor(public payload: string[]) {}
}

export class LoadAnnouncementsFailure implements Action {
  readonly type = ActionTypes.LOAD_ANNOUNCEMENTS_FAILURE;
  constructor(public payload: { error: string }) {}
}
