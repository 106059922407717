<ng-container *ngIf="!connectedInternet">
  <div class="alert alert-error" role="alert">
    <b>{{ 'no.internet.connection.message' | translate }}</b
    ><br />
    {{ 'no.internet.connection.check.message' | translate }}
  </div>
</ng-container>
<ng-container *ngIf="!connectedBackend && connectedInternet">
  <div class="alert alert-error" role="alert">
    <b>{{ 'no.backend.connection.message' | translate }}</b
    ><br />
    {{ 'no.backend.connection.helpdesk.message' | translate }}
  </div>
</ng-container>
