<section class="header-container">
  <wmp-header></wmp-header>
</section>
<section class="view-container">
  <section class="navigation-container">
    <wmp-navigation></wmp-navigation>
  </section>
  <section class="router-outlet-container">
    <wmp-no-connection></wmp-no-connection>
    <wmp-bad-request></wmp-bad-request>
    <wmp-announcements></wmp-announcements>
    <wmp-demo-user></wmp-demo-user>
    <wmp-new-signature-request></wmp-new-signature-request>
    <wmp-new-information-document></wmp-new-information-document>
    <router-outlet #routerOutlet></router-outlet>
  </section>
</section>
