import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import {
  DocumentActionParams,
  DocumentModel,
  DocumentRequestParams,
  DocumentsAccount,
  DocumentsFilterModel,
  DocumentUploadHistoryModel,
  WmpDocumentModel,
} from '@models/document.model';
import { ServiceResult } from '@models/serviceResult.model';
import { Update } from '@ngrx/entity';

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: ServiceResult<WmpDocumentModel[]>) {}
}

export class LoadMoreRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadMoreSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_SUCCESS;
  constructor(public payload: ServiceResult<WmpDocumentModel[]>) {}
}

export class LoadSigningRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SIGNING_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadSigningFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SIGNING_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSigningSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SIGNING_SUCCESS;
  constructor(public payload: ServiceResult<WmpDocumentModel[]>) {}
}

export class LoadMoreSigningRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_SIGNING_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadMoreSigningSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_SIGNING_SUCCESS;
  constructor(public payload: ServiceResult<WmpDocumentModel[]>) {}
}

export class LoadUploadHistoryRequestAction implements Action {
  readonly type = ActionTypes.LOAD_UPLOAD_HISTORY_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadUploadHistorySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_UPLOAD_HISTORY_SUCCESS;
  constructor(public payload: DocumentUploadHistoryModel[]) {}
}

export class LoadMoreUploadHistoryRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_UPLOAD_HISTORY_REQUEST;
  constructor(public payload: DocumentRequestParams) {}
}

export class LoadMoreUploadHistorySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_UPLOAD_HISTORY_SUCCESS;
  constructor(public payload: DocumentUploadHistoryModel[]) {}
}

export class SetItemsPerPage implements Action {
  readonly type = ActionTypes.SET_ITEMS_PER_PAGE;
  constructor(public payload: number) {}
}

export class SetFilter implements Action {
  readonly type = ActionTypes.SET_FILTER;
  constructor(public payload: DocumentsFilterModel) {}
}

export class RequestUnreadCounter implements Action {
  readonly type = ActionTypes.REQUEST_UNREAD_COUNTER;
}

export class UpdateUnreadCounter implements Action {
  readonly type = ActionTypes.UPDATE_UNREAD_COUNTER;
  constructor(public payload: number) {}
}

export class UnreadCounterFailure implements Action {
  readonly type = ActionTypes.FAILURE_UNREAD_COUNTER;
}

export class MarkAsRead implements Action {
  readonly type = ActionTypes.MARK_AS_READ;
  constructor(public payload: DocumentActionParams) {}
}

export class MarkAsReadSuccess implements Action {
  readonly type = ActionTypes.MARK_AS_READ_SUCCESS;
  constructor(public payload: Update<DocumentModel>[]) {}
}

export class MarkAsReadFailure implements Action {
  readonly type = ActionTypes.MARK_AS_READ_FAILURE;
}

export class Download implements Action {
  readonly type = ActionTypes.DOWNLOAD;
  constructor(public payload: DocumentActionParams) {}
}

export class DownloadSuccess implements Action {
  readonly type = ActionTypes.DOWNLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class DownloadFailure implements Action {
  readonly type = ActionTypes.DOWNLOAD_FAILURE;
}

export class DownloadSigning implements Action {
  readonly type = ActionTypes.DOWNLOAD_SIGNING;
  constructor(public payload: DocumentActionParams) {}
}

export class DownloadSigningSuccess implements Action {
  readonly type = ActionTypes.DOWNLOAD_SIGNING_SUCCESS;
  constructor(public payload: any) {}
}

export class DownloadSigningFailure implements Action {
  readonly type = ActionTypes.DOWNLOAD_SIGNING_FAILURE;
}

export class SignDocumentRequest implements Action {
  readonly type = ActionTypes.SIGN_DOCUMENT_REQUEST;
  constructor(public payload: DocumentActionParams) {}
}

export class SignDocumentSuccess implements Action {
  readonly type = ActionTypes.SIGN_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class SignDocumentFailure implements Action {
  readonly type = ActionTypes.SIGN_DOCUMENT_FAILURE;
}

export class DeclineDocumentRequest implements Action {
  readonly type = ActionTypes.DECLINE_DOCUMENT_REQUEST;
  constructor(public payload: DocumentActionParams) {}
}

export class DeclineDocumentSuccess implements Action {
  readonly type = ActionTypes.DECLINE_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class DeclineDocumentFailure implements Action {
  readonly type = ActionTypes.DECLINE_DOCUMENT_FAILURE;
}

export class Merge implements Action {
  readonly type = ActionTypes.MERGE;
  constructor(public payload: DocumentActionParams) {}
}

export class UpdateDocuments implements Action {
  readonly type = ActionTypes.UPDATE_DOCUMENTS;
  constructor(public payload: Update<DocumentModel>[]) {}
}

export class ResetDocuments implements Action {
  readonly type = ActionTypes.RESET_DOCUMENTS;
}

export class GetSecSignIdForDocumentSigningRequestAction implements Action {
  readonly type = ActionTypes.GET_SEC_SIGN_ID_FOR_DOCUMENT_SIGNING_REQUEST;
  constructor(public payload: any) {}
}

export class SetSecSignStatus implements Action {
  readonly type = ActionTypes.SET_SEC_SIGN_STATUS;
  constructor(public payload: string) {}
}

export class ProcessingDocumentSigningSuccessAction implements Action {
  readonly type = ActionTypes.PROCESS_DOCUMENT_SIGNING_ACTION_SUCCESS;
  constructor(private payload: any) {}
}

export class NewSigningRequestsCountRequest implements Action {
  readonly type = ActionTypes.NEW_SIGNING_REQUESTS_COUNT_REQUEST;
}

export class NewSigningRequestsCountSuccess implements Action {
  readonly type = ActionTypes.NEW_SIGNING_REQUESTS_COUNT_SUCCESS;
  constructor(public payload: number) {}
}

export class LoadDocumentsAccountsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DOCUMENTS_ACCOUNTS_REQUEST;
}

export class LoadDocumentsAccountsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DOCUMENTS_ACCOUNTS_SUCCESS;
  constructor(public payload: DocumentsAccount) {}
}
