import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackendErrorService {
  badRequestCatch = new BehaviorSubject<boolean>(false);
  badRequestCatch$: Observable<boolean> = this.badRequestCatch.asObservable();
}
