import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ContentBannerModel,
  ContentBannerResponseModel,
} from '@models/content-banner.model';
import { LanguageService } from '@core/services/language.service';
import { LANGUAGE_CODE_DE } from '@core/constants/defaults';

@Injectable({
  providedIn: 'root',
})
export class ContentBannerService {
  constructor(
    private http: HttpClient,
    private languageService: LanguageService
  ) {}

  getContentBanner(): Observable<ContentBannerResponseModel> {
    return this.http.get<ContentBannerResponseModel>(
      `/public/api/v1/content-banner`
    );
  }

  getMappedContentBanner(
    content: ContentBannerResponseModel
  ): ContentBannerModel {
    const isSelectedLanguageGerman =
      this.languageService.getCurrentLanguage() === LANGUAGE_CODE_DE;
    return {
      header: isSelectedLanguageGerman ? content.headerDe : content.headerEn,
      body: isSelectedLanguageGerman ? content.bodyDe : content.bodyEn,
      footer: isSelectedLanguageGerman ? content.footerDe : content.footerEn,
      footerLink: isSelectedLanguageGerman
        ? content.footerLinkDe
        : content.footerLinkEn,
    };
  }
}
