import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NavigationActions,
  NavigationSelectors,
} from '@store/navigation/navigation-store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'wmp-demo-user',
  templateUrl: './demo-user.component.html',
  styleUrls: ['./demo-user.component.scss'],
})
export class DemoUserComponent implements OnInit {
  public isDemoUser$: Observable<boolean> = of(false);

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.store$.dispatch(new NavigationActions.IsDemoUserRequestAction());
    this.isDemoUser$ = this.store$.select(NavigationSelectors.selectIsDemoUser);
  }
}
