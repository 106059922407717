import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';
import { AuthSessionType } from '@models/auth-session.model';

export function AuthReducer(
  state: State = initialState,
  action: any = {}
): State {
  const stateWithoutError = {
    ...state,
    error: null,
  };

  switch (action.type) {
    case ActionTypes.LOGIN_USERNAME_AND_PASSWORD: {
      return stateWithoutError;
    }

    case ActionTypes.LOGIN_ATTEMPT_SUCCESS: {
      return {
        ...state,
        secSignStatus: action.payload.status,
        resetpwd: action.payload.resetpwd,
        isLoggedIn: action.isLoggedIn,
      };
    }

    case ActionTypes.CANCEL_ATTEMPT_SUCCESS: {
      return {
        ...state,
        secSignStatus: AuthSessionType.SESSION_STATE_CANCELED,
        isLoggedIn: false,
      };
    }

    case ActionTypes.LOGIN_ATTEMPT_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        secSignStatus: AuthSessionType.SESSION_STATE_CANCELED,
        error: action.payload.error,
      };
    }

    case ActionTypes.POLL_2FA: {
      return {
        ...state,
        isPolling2fa: true,
      };
    }

    case ActionTypes.LOGIN_CHECK_AUTH_SESSION: {
      return stateWithoutError;
    }

    case ActionTypes.REFRESH_TOKEN: {
      return stateWithoutError;
    }

    case ActionTypes.SET_SEC_SIGN_STATUS: {
      return {
        ...state,
        isPolling2fa: false,
        secSignStatus: action.payload,
      };
    }

    case ActionTypes.GET_ENVIRONMENT: {
      return stateWithoutError;
    }

    case ActionTypes.GET_ENVIRONMENT_SUCCESS: {
      return {
        ...state,
        environment: action.payload,
      };
    }

    case ActionTypes.SET_SEC_SIGN_PROCESSING_STATUS: {
      return {
        ...state,
      };
    }

    case ActionTypes.RESET_AUTH_STORE: {
      return {
        ...state,
        secSignStatus: AuthSessionType.SESSION_STATE_NOSTATE,
        isLoggedIn: null,
      };
    }

    case ActionTypes.GET_ONBOARDING_STATUS_SUCCESS: {
      return {
        ...state,
        secSignStatus: action.payload.status,
      };
    }

    case ActionTypes.ONBOARDING_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        secSignStatus: action.payload.status,
      };
    }

    case ActionTypes.ONBOARDING_CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        secSignStatus:
          AuthSessionType.SESSION_STATE_ONBOARDING_CHANGE_PASSWORD_INVALID,
      };
    }

    case ActionTypes.GET_USER_ROLES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        roles: action.payload,
      };
    }

    case ActionTypes.GET_USER_ROLES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
