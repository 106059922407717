import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as FeatureActions from './actions';
import { throwError } from 'rxjs';
import * as ActionTypes from './actionTypes';
import { Store } from '@ngrx/store';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { LanguageService } from '@core/services/language.service';
import { MasterNumberModel } from '@models/navigation.model';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private service: NavigationService,
    public languageService: LanguageService
  ) {}

  setLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SET_LANGUAGE_REQUEST),
      mergeMap((action: FeatureActions.SetLanguageRequestAction) =>
        this.service.setLanguage(action.payload).pipe(
          map((result) => {
            const langService = action.payload.split('-')[0];
            this.languageService.setLanguage(langService);
            return new FeatureActions.SetLanguageSuccessAction(action.payload);
          }),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadMasterNumbers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_MASTER_NUMBERS_REQUEST),
      mergeMap(() => {
        return this.service.fetchMasterNumbers().pipe(
          map((result) => {
            return new FeatureActions.LoadMasterNumbersSuccessAction(result);
          }),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  setMasterNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SET_MASTER_NUMBER_REQUEST),
      mergeMap((action: FeatureActions.SetMasterNumberRequestAction) => {
        return this.service.setSelectedMasterNumber(action.payload).pipe(
          map(() => {
            sessionStorage.setItem(
              'isSettingNewMasterNumberSuccessful',
              'true'
            );
            return new FeatureActions.SetMasterNumberSuccessAction();
          }),
          catchError((error) => {
            sessionStorage.setItem(
              'isSettingNewMasterNumberSuccessful',
              'false'
            );
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  getSelectedMasterNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_SELECTED_MASTER_NUMBER_REQUEST),
      mergeMap(() => {
        return this.service.getSelectedMasterNumber().pipe(
          map((result) => {
            return new FeatureActions.GetSelectedMasterNumberSuccessAction(
              result
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  getSelectedLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_SELECTED_LANGUAGE_REQUEST),
      mergeMap(() => {
        return this.service.getSelectedLanguage().pipe(
          map(
            (result) =>
              new FeatureActions.GetSelectedLanguageSuccessAction(result)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  isDemoUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.IS_DEMO_USER_REQUEST),
      mergeMap(() => {
        return this.service.isDemoUser().pipe(
          map(
            (result: boolean) =>
              new FeatureActions.IsDemoUserSuccessAction(result)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.FailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  loadAnnouncements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_ANNOUNCEMENTS_REQUEST),
      mergeMap(() =>
        this.service.getAnnouncements().pipe(
          map((data) => new FeatureActions.LoadAnnouncementsSuccess(data)),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadAnnouncementsFailure({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });
}
