import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceResult } from '@models/serviceResult.model';
import { TwoFactorModalComponent } from '@shared/components/two-factor-modal/two-factor-modal.component';
import { noop, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { LanguageService } from '@core/services/language.service';
import { STATE_TYPE } from '@models/authentication/authentication.model';
import { FeatureService } from '../feature.service';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthService {
  private dialogOpen = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private featureService: FeatureService
  ) {}

  getSecSignStatus(
    url: string,
    authSessionId: string
  ): Observable<ServiceResult<any>> {
    sessionStorage.setItem('auth_session', authSessionId);
    return this.http.get<ServiceResult<any>>(`${url}/2fa/${authSessionId}`);
  }

  cancelAuthSession(authSessionId: string): Observable<ServiceResult<boolean>> {
    return this.http.delete<ServiceResult<any>>(
      `/public/api/v2/payment-orders/${authSessionId}/2fa`
    );
  }

  cancelLoginAuthSession(
    sessionId: string
  ): Observable<ServiceResult<boolean>> {
    const useNewAuthService = this.featureService.getFeatureToggleByKey(
      'WEB_USE_NEW_AUTH_SERVICE'
    );
    if (useNewAuthService) {
      return this.http.delete<any>(`/api/v1/auth/login/session/${sessionId}`);
    } else {
      return this.http.delete<ServiceResult<any>>(
        `/authentication/web-login/abort`
      );
    }
  }

  authenticateWithSecSign(
    url: string,
    errorPageUrl: string,
    skipLocationChange = false,
    queryParams = {}
  ): MatDialogRef<TwoFactorModalComponent> {
    const dialogRef = this.dialog.open(TwoFactorModalComponent);
    this.dialogOpen = true;
    dialogRef.addPanelClass('twofa-modal');
    dialogRef.disableClose = true;
    dialogRef
      .beforeClosed()
      .pipe(first())
      .subscribe(() => {
        sessionStorage.removeItem('auth_session');
        if (
          url &&
          dialogRef.componentInstance.twoFactorStatus ===
            STATE_TYPE.AUTHENTICATED
        ) {
          this.languageService.setSelectedLanguage();
          this.navigateTo(url, skipLocationChange, queryParams);
        } else if (
          dialogRef.componentInstance.twoFactorStatus !== STATE_TYPE.CANCELED &&
          dialogRef.componentInstance.twoFactorStatus !== STATE_TYPE.FAILED &&
          dialogRef.componentInstance.twoFactorStatus !== STATE_TYPE.EXPIRED
        ) {
          this.navigateTo(errorPageUrl, true);
        }
      });
    return dialogRef;
  }

  finishSecSignAuthentication(
    dialogRef: MatDialogRef<TwoFactorModalComponent> | null,
    response: STATE_TYPE
  ): void {
    if (dialogRef) {
      if (dialogRef.componentInstance)
        dialogRef.componentInstance.twoFactorStatus = response;
      dialogRef.close();
      this.dialogOpen = false;
      sessionStorage.removeItem('auth_session');
    }
  }

  private navigateTo(
    url: string,
    skipLocationChange = false,
    queryParams = {}
  ): void {
    this.router
      .navigate([url], {
        skipLocationChange,
        queryParams,
      })
      ?.then()
      .catch(() => noop());
  }

  public isDialogOpen(): boolean {
    return this.dialogOpen;
  }
}
