import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { State, FEATURE_KEY } from './state';

export const getIsLoading = (state: State): boolean => state.isLoading;
export const getEnvironment = (state: State): string => state.environment;
export const getUserRoles = (state: State): string[] => state.roles;

export const selectAuthState = createFeatureSelector<State>(FEATURE_KEY);
export const getError = createSelector(selectAuthState, (state) => state.error);
export const isLoggedIn = createSelector(
  selectAuthState,
  (state) => state.isLoggedIn
);
export const selectSecSignStatus = createSelector(
  selectAuthState,
  (state: State): any => state.secSignStatus
);
export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectAuthState, getIsLoading);
export const selectEnvironment: MemoizedSelector<object, string> =
  createSelector(selectAuthState, getEnvironment);
export const selectUserRoles: MemoizedSelector<object, string[]> =
  createSelector(selectAuthState, getUserRoles);
