import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as FeatureToggleActions from './actions';
import { throwError } from 'rxjs';
import * as FeatureToggleActionType from './actionTypes';
import { Store } from '@ngrx/store';
import { ContentBannerStore } from '@store/content-banner-store/index';
import { ContentBannerService } from '@core/services/content-banner.service';
import { ContentBannerResponseModel } from '@models/content-banner.model';

@Injectable()
export class ContentBannerEffects {
  _ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeatureToggleActionType.GET_CONTENT_BANNER_REQUEST),
      mergeMap(() => {
        return this.contentBannerService.getContentBanner().pipe(
          map((content: ContentBannerResponseModel) => {
            return new FeatureToggleActions.GetContentBannerSuccessAction(
              content
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureToggleActions.GetContentBannerFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        );
      })
    );
  });

  constructor(
    private store$: Store<ContentBannerStore.State>,
    private actions$: Actions,
    private contentBannerService: ContentBannerService
  ) {}
}
