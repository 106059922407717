import { HttpClient } from '@angular/common/http';
import { MAINTENANCE_WMP } from '@core/constants/links';
import { ConfigService } from '@core/services/config/config.service';
import { FeatureService } from '@core/services/feature.service';
import { Environment } from '@models/auth-session.model';
import { HeathCheckResponse } from '@models/healthcheck.model';
import * as Sentry from '@sentry/angular';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

export function initializeApp(
  traceService: Sentry.TraceService,
  configService: ConfigService,
  featureService: FeatureService,
  httpClient: HttpClient
) {
  return () =>
    configService.load().pipe(
      concatMap(() => checkHealth(httpClient)),
      concatMap(() => featureService.getFeatureToggles()),
      concatMap(() => checkWmpOrRmp(httpClient, featureService)),
      catchError((err, caught) => {
        console.error(`App initialization error ${JSON.stringify(err)}`);
        return (window.location.href = MAINTENANCE_WMP);
      })
    );
}

function checkWmpOrRmp(
  httpClient: HttpClient,
  featureService: FeatureService
): Observable<any> {
  const useNewAuthService = featureService.getFeatureToggleByKey(
    'WEB_USE_NEW_AUTH_SERVICE'
  );
  const apiUrl = featureService.getFeatureToggleByKey(
    'WEB_USE_NEW_AUTH_SERVICE'
  )
    ? '/api/v1/environment'
    : '/authentication/api/auth/environment';
  return httpClient
    .get(apiUrl, { responseType: 'text' })
    .pipe(
      tap((response) => startSsoIfRmp(response as string, useNewAuthService))
    );
}

function startSsoIfRmp(envResponse: string, useNewAuthService: boolean) {
  const envVariables = envResponse.split('-');
  if (
    envVariables[0] === Environment.RMP &&
    sessionStorage.getItem('environment') == null
  ) {
    sessionStorage.setItem('environment', envVariables[0]);
    window.location.href = useNewAuthService
      ? '/api/v1/auth/login/rmp/start-sso'
      : '/authentication/rmp/start-sso';
  }
}

/// Throws in case of an error
function checkHealth(httpClient: HttpClient): Observable<any> {
  return httpClient
    .get<HeathCheckResponse>('/public/api/monitoring/health', {
      observe: 'response',
    })
    .pipe(
      concatMap((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw 'Healthcheck Old Backend Failed';
        }

        return of<boolean>(response.body!.healthValues.newBackendActive);
      }),
      concatMap((isNewBackendActive) => {
        if (isNewBackendActive) {
          return httpClient
            .get('/api/monitoring/health/backend', {
              observe: 'response',
              responseType: 'text',
            })
            .pipe(
              map((responseNew) => {
                if (responseNew.status < 200 || responseNew.status >= 300) {
                  throw 'Healthcheck New Backend Failed';
                }
              })
            );
        }

        return of(null);
      })
    );
}
