import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false,
})
export class LocalizedNumberPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value?: any,
    minimumFractionDigits = 2,
    maximumFractionDigits: number | undefined = undefined
  ): string {
    return value
      ? Number(value).toLocaleString(this.translateService.currentLang, {
          minimumFractionDigits,
          maximumFractionDigits,
        })
      : '';
  }
}
