import { DocumentSigningSecSignSessionType } from '@models/auth-session.model';
import {
  DocumentsFilterModel,
  DocumentUploadHistoryModel,
  WmpDocumentModel,
} from '@models/document.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'document';
export const featureAdapter: EntityAdapter<WmpDocumentModel> =
  createEntityAdapter<WmpDocumentModel>({});

export interface State extends EntityState<WmpDocumentModel> {
  isLoading: boolean;
  error?: any;
  metadata?: any;
  totalCount: number;
  itemsPerPage: number;
  filter: DocumentsFilterModel;
  unreadCounter: number;
  newSigningRequestCount: number;
  newInformationDocumentCount: number;
  readDocuments: string[];
  documentsAccounts: string[];
  signedDocuments: string[];
  uploadedDocumentHistory: DocumentUploadHistoryModel[];
  rejectedDocuments: string[];
  secSignStatus: DocumentSigningSecSignSessionType;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  metadata: null,
  totalCount: 0,
  itemsPerPage: 10,
  filter: {},
  unreadCounter: 0,
  newSigningRequestCount: 0,
  newInformationDocumentCount: 0,
  readDocuments: [],
  signedDocuments: [],
  rejectedDocuments: [],
  documentsAccounts: [],
  uploadedDocumentHistory: [],
  secSignStatus: DocumentSigningSecSignSessionType.NO_STATE,
});
