import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import { KEY_CURRENT_LANGUAGE, KEY_DEFAULT_AMOUNT } from '@core/constants/keys';
import {
  DEFAULT_AMOUNT_DE,
  DEFAULT_AMOUNT_EN,
  LANGUAGE_CODE_DE,
} from '@core/constants/defaults';
import { Store } from '@ngrx/store';
import { NavigationSelectors } from '@store/navigation/navigation-store';

export interface Language {
  locale: string;
  label: string;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[] = [
    {
      locale: 'de',
      label: 'DE',
      code: 'de-DE',
    },
    {
      locale: 'en',
      label: 'EN',
      code: 'en-GB',
    },
  ];

  constructor(
    private translate: TranslateService,
    private storage: LocalStorageService,
    private store: Store
  ) {}

  public init(): void {
    // check for persisted language in local storage
    const persistedLanguage = this.storage.getItem(KEY_CURRENT_LANGUAGE);
    if (persistedLanguage) {
      this.translate.use(persistedLanguage);
    } else {
      // otherwise init with browser language or default language or first available language
      const availableLocales = this.getLocales();
      const browserLanguage = this.translate.getBrowserLang();
      const defaultLanguage = availableLocales.includes(LANGUAGE_CODE_DE)
        ? LANGUAGE_CODE_DE
        : this.languages[0]?.locale;

      this.translate.addLangs(availableLocales);
      this.translate.setDefaultLang(defaultLanguage);

      if (browserLanguage && availableLocales.includes(browserLanguage)) {
        this.translate.use(browserLanguage);
      } else {
        this.translate.use(defaultLanguage);
      }
      // persist to local storage
      this.storage.setItem(KEY_CURRENT_LANGUAGE, this.translate.currentLang);
    }

    // listen to language changes and update local storage
    this.translate.onLangChange.subscribe((data: any) => {
      this.setLanguagePreferencesToStorage(data);
    });
  }

  private setLanguagePreferencesToStorage(data: any) {
    const lang = data.lang || data;
    this.storage.setItem(KEY_CURRENT_LANGUAGE, lang);
    localStorage.setItem(
      KEY_DEFAULT_AMOUNT,
      lang === LANGUAGE_CODE_DE ? DEFAULT_AMOUNT_DE : DEFAULT_AMOUNT_EN
    );
    this.translate.use(lang);
  }

  public setLanguage(language: string): void {
    this.translate.use(language);
  }

  public getLocales(): string[] {
    return this.languages.map((it) => it.locale);
  }

  public getLanguageCodeByLocale(locale: string): string {
    return this.languages.filter((lang: Language) => lang.locale === locale)[0]
      .code;
  }

  public getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  setSelectedLanguage() {
    this.store
      .select(NavigationSelectors.getSelectedLanguage)
      .subscribe((data) => {
        if (data) {
          this.setLanguagePreferencesToStorage(data.split('-')[0]);
        }
      });
  }
}
