/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SentryLogFilter } from '@core/utils/sentry-log-filter';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('assets/sentry/sentry-config.json')
  .then((response) => {
    response.json().then((data) => {
      Sentry.init({
        dsn: 'https://28d72ce56d01afab6d6f0bd1a6d68c0c@wmp-monitoring.heybego.com/4506779296137216',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.01,
        environment: data.environment ?? 'prod',

        beforeSend: SentryLogFilter.filterSentryEvent,
        beforeBreadcrumb: SentryLogFilter.filterSentryBreadcrumb,
      });
    });
  })
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
