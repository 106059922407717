export interface CurrencyModel {
  code: string;
  name: string;
}

export interface CurrencyResponse {
  currencies: CurrencyModel[];
}

export interface CountryResponse {
  countries: CountryModel[];
}

export interface CountryModel {
  code: string;
  name: string;
  additions: {
    eea: boolean;
  };
}

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
}

export interface PaymentApprovalModel {
  additions: {
    approvalStatus: ApprovalStatus;
    initialDate: string;
    initiatorUser: string;
    isUserHaveRightToApprovePayment: string;
    lastModifiedDate: string;
    lastModifier: string;
    orderId: string;
    paymentStatus: string;
    paymentType: string;
    userCode: string;
  };
  creditTransferTransactionInformation: CreditTransferTransactionInformation[];
  debtorAccount: {
    additions: {
      accountId: string;
    };
    identification: {
      identification: string;
    };
    name: string;
  };
  paymentMode: string;
  paymentType: string;
  instructionPriority: string;
}

export interface StandingOrdersModel {
  additions: {
    orderId: string;
    paymentStatus: string;
  };
  creditTransferTransactionInformation: CreditTransferTransactionInformation[];
  debtorAccount: {
    identification: {
      identification: string;
    };
  };
  paymentMode: string;
  paymentType: string;
  schedule: {
    transferFrequency: string;
    startDate: string;
    endDate: string;
    nextExecutionDate: string;
  };
  isStandingOrderChange: boolean;
  standingOrderChangeId: string;
}

interface CreditTransferTransactionInformation {
  instructedAmount: {
    amount: string;
    currencyCode: string;
  };
  creditorBank: {
    bic: string;
  };
  creditorAccount: {
    identification: {
      identification: string;
    };
  };
  additions: {
    chargeType: string;
  };
  creditor: {
    name: string;
    postalAddress: {
      country: string;
    };
  };
  remittanceInformation: string;
}

export interface AccountInfoResponse {
  bankName: string;
  bic: string;
  bicValid: boolean;
  countryNeeded: boolean;
  formattedIban: string;
  iban: string;
  ibanNeeded: boolean;
  ibanValid: boolean;
}

export interface PaymentFormModel {
  recipient: string;
  country: CountryModel;
  accountNumber: string;
  bic: string;
  bankName: string;
  amount: number;
  currencyCode: string;
  description: string;
  charges: PaymentFees;
  interval: PaymentInterval;
  onceExecution: Date;
  firstExecution: Date;
  lastExecution: Date;
  nextExecution?: Date | string;
  paymentScheduledType: PaymentScheduleType;
}

export interface FeesModel {
  ccy: string;
  payedAmount: number;
  bookedAmount: number;
  rate: number;
  fee: number;
  charges: number;
  foreignCharges: number;
  commission: number;
}

export enum PaymentType {
  SEPA = 'SEPA',
  NON_EURO = 'NON_EURO',
}

export enum ATTORNEY_CODE {
  INFOPOA = 'INFOPOA',
  SINGLEPOA = 'SINGLEPOA',
  JOINTPOA = 'JOINTPOA',
}

export const ATTORNEY_CODE_ONLY_INFO = 'INFOPOA';
export const ATTORNEY_CODE_APPROVAL_REQUIRED = 'JOINTPOA';

export enum PaymentOperation {
  NEW_PAYMENT = 'NEW_PAYMENT',
  APPROVE_PAYMENT = 'APPROVE_PAYMENT',
  CHANGE_STANDING_ORDER = 'CHANGE_STANDING_ORDER',
  DELETE_STANDING_ORDER = 'DELETE_STANDING_ORDER',
}

export interface AccountInfoRequest {
  accountNo: string;
  ccy: string;
  bic?: string;
  convertSortCode?: boolean;
  debtorAccountIban?: string;
}

export interface StandingOrderChangeModel {
  standingOrderChange: boolean;
  standingOrderChangeId: string;
  nextExecutionDate: any;
}

export enum PaymentScheduleType {
  TRANSFER = 'TRANSFER',
  SCHEDULED_TRANSFER = 'SCHEDULED_TRANSFER',
  STANDING_ORDER = 'STANDING_ORDER',
}

export enum PaymentInterval {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
}

export enum PaymentFees {
  shared = 'shared',
  our = 'our',
  ben = 'ben',
}
