import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocalizedCurrencyPipe } from './pipes/localized-currency.pipe';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LocalizedNumberPipe } from './pipes/localized-number.pipe';
import { PillComponent } from './components/wmp-pill/wmp-pill.component';
import { WmpDatepickerComponent } from './components/wmp-datepicker/wmp-datepicker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WmpModalComponent } from './components/wmp-modal/wmp-modal.component';
import { DecimalAmountDirective } from '@shared/directives/decimal-amount.directive';
import { NumberOnlyDirective } from '@shared/directives/number-amount.directive';
import { TwoFactorModalComponent } from './components/two-factor-modal/two-factor-modal.component';
import { TotalAmountPipe } from '@shared/pipes/total-amount.pipe';
import { WmpErrorComponent } from './components/wmp-error/wmp-error.component';
import { WmpPdfViewerComponent } from './components/wmp-pdf-viewer/wmp-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MediumDatePipe } from '@shared/pipes/medium-date.pipe';
import { SwitchLanguageComponent } from '@shared/components/switch-language/switch-language.component';
import { LineHighChartComponent } from '@shared/components/line-high-chart/line-high-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { DonutHighChartComponent } from '@shared/components/donut-high-chart/donut-high-chart.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { WmpSupportComponent } from '@shared/components/wmp-support/wmp-support.component';
import { BarHighChartComponent } from '@shared/components/bar-high-chart/bar-high-chart.component';
import { DefaultToPipe } from '@shared/pipes/defaultTo.pipe';
import { WmpTabNavComponent } from './components/wmp-tab-nav/wmp-tab-nav.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { WmpAccordionTableDirective } from './directives/accordion-table.directive';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IbanPipe } from '@shared/pipes/iban.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ReplaceSpecialCharactersDirective } from '@shared/directives/replace-special-characters.directive';
import { NegativeDecimalAmountDirective } from './directives/negative-decimal-amount.directive';
import { WmpUnreadCounterComponent } from './components/wmp-unread-counter/wmp-unread-counter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { LocaleDateFormatPipe } from '@shared/pipes/locale-date.pipe';
import { LocaleDateTimeFormatPipe } from '@shared/pipes/locale-date-time.pipe';
import { DemoUserComponent } from '@shared/components/demo-user/demo-user.component';
import { NewSignatureRequestComponent } from '@shared/components/new-signature-request/new-signature-request.component';
import { NoConnectionComponent } from '@shared/components/no-connection/no-connection.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { WmpInfoComponent } from './components/wmp-info/wmp-info.component';
import { NewInformationDocumentComponent } from '@shared/components/new-information-document/new-information-document.component';
import { WmpBadRequestComponent } from '@shared/components/wmp-bad-request/wmp-bad-request.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ColumnHighChartComponent } from './components/column-high-chart/column-high-chart.component';
import { IconsModule } from '@modules/icons/icons.module';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

const PIPES = [
  LocalizedDatePipe,
  LocaleDateTimeFormatPipe,
  LocaleDateFormatPipe,
  LocalizedCurrencyPipe,
  LocalizedNumberPipe,
  TotalAmountPipe,
  MediumDatePipe,
  DefaultToPipe,
  IbanPipe,
  SafeHtmlPipe,
];

@NgModule({
  declarations: [
    ...PIPES,
    LineHighChartComponent,
    BarHighChartComponent,
    DonutHighChartComponent,
    LoadingIndicatorComponent,
    PillComponent,
    WmpDatepickerComponent,
    WmpModalComponent,
    DecimalAmountDirective,
    NegativeDecimalAmountDirective,
    NumberOnlyDirective,
    ReplaceSpecialCharactersDirective,
    TwoFactorModalComponent,
    WmpErrorComponent,
    WmpPdfViewerComponent,
    SwitchLanguageComponent,
    FooterComponent,
    NoConnectionComponent,
    DemoUserComponent,
    NewSignatureRequestComponent,
    WmpSupportComponent,
    WmpTabNavComponent,
    WmpAccordionTableDirective,
    WmpUnreadCounterComponent,
    AnnouncementsComponent,
    WmpInfoComponent,
    NewInformationDocumentComponent,
    WmpBadRequestComponent,
    ColumnHighChartComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    RouterModule,
    NgxExtendedPdfViewerModule,
    HighchartsChartModule,
    MatButtonToggleModule,
    IconsModule,
  ],
  exports: [
    ...PIPES,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LineHighChartComponent,
    DonutHighChartComponent,
    LoadingIndicatorComponent,
    PillComponent,
    TwoFactorModalComponent,
    WmpDatepickerComponent,
    DecimalAmountDirective,
    NegativeDecimalAmountDirective,
    NumberOnlyDirective,
    ReplaceSpecialCharactersDirective,
    WmpErrorComponent,
    SwitchLanguageComponent,
    FooterComponent,
    WmpSupportComponent,
    WmpTabNavComponent,
    BarHighChartComponent,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    WmpAccordionTableDirective,
    WmpUnreadCounterComponent,
    HighchartsChartModule,
    DemoUserComponent,
    NewSignatureRequestComponent,
    NoConnectionComponent,
    AnnouncementsComponent,
    WmpInfoComponent,
    NewInformationDocumentComponent,
    WmpBadRequestComponent,
    MatButtonToggleModule,
    ColumnHighChartComponent,
    IconsModule,
  ],
  providers: [...PIPES],
})
export class SharedModule {}
