import * as ActionTypes from './actionTypes';
import { featureAdapter, initialState, State } from './state';

export function documentReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST:
    case ActionTypes.LOAD_MORE_REQUEST:
    case ActionTypes.LOAD_SIGNING_REQUEST:
    case ActionTypes.LOAD_UPLOAD_HISTORY_REQUEST:
    case ActionTypes.LOAD_MORE_UPLOAD_HISTORY_REQUEST:
    case ActionTypes.LOAD_DOCUMENTS_ACCOUNTS_REQUEST:
    case ActionTypes.LOAD_MORE_SIGNING_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        metadata: action.payload,
        isLoading: false,
        error: null,
        // readDocuments: action.payload.result
        //   .filter((e: any) => e.isRead === true)
        //   .map((e: any) => {
        //     return e.id;
        //   }),
      });
    }
    case ActionTypes.LOAD_SIGNING_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        metadata: action.payload,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_MORE_SUCCESS: {
      return featureAdapter.addMany(action.payload, {
        ...state,
        metadata: { ...action.payload },
        isLoading: false,
        error: null,
        // readDocuments: state.readDocuments.concat(
        //   action.payload.result
        //     .filter((e: any) => e.isRead === true)
        //     .map((e: any) => {
        //       return e.id;
        //     })
        // ),
      });
    }
    case ActionTypes.LOAD_UPLOAD_HISTORY_SUCCESS: {
      return {
        ...state,
        uploadedDocumentHistory: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_MORE_UPLOAD_HISTORY_SUCCESS: {
      return {
        ...state,
        uploadedDocumentHistory: state.uploadedDocumentHistory.concat(
          action.payload
        ),
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_MORE_SIGNING_SUCCESS: {
      return featureAdapter.addMany(action.payload, {
        ...state,
        metadata: { ...action.payload },
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_SIGNING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_DOCUMENTS_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        documentsAccounts: action.payload.accounts,
      };
    }

    case ActionTypes.UPDATE_DOCUMENTS: {
      return featureAdapter.updateMany(action.payload, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.SET_ITEMS_PER_PAGE: {
      return {
        ...state,
        itemsPerPage: action.payload,
      };
    }
    case ActionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...action.payload },
      };
    }

    case ActionTypes.MARK_AS_READ_SUCCESS: {
      // filter documents already marked as read beforehand
      const newReadDocuments = state.readDocuments.concat(
        action.payload
          .map((e: any) => {
            return e.id;
          })
          .filter((e: any) => !state.readDocuments.includes(e))
      );
      return featureAdapter.updateMany(action.payload, {
        ...state,
        isLoading: false,
        error: null,
        unreadCounter:
          state.unreadCounter -
          (newReadDocuments.length - state.readDocuments.length),
        readDocuments: newReadDocuments,
      });
    }

    case ActionTypes.DECLINE_DOCUMENT_SUCCESS: {
      const rejectedDocuments = action.payload.documentIds.filter(
        (id: string) => !state.rejectedDocuments.includes(id)
      );
      return featureAdapter.updateMany(rejectedDocuments, {
        ...state,
        isLoading: false,
        error: null,
        rejectedDocuments: rejectedDocuments,
      });
    }

    case ActionTypes.SIGN_DOCUMENT_SUCCESS: {
      const signedDocuments = action.payload.documentIds.filter(
        (id: string) => !state.signedDocuments.includes(id)
      );
      return featureAdapter.updateMany(signedDocuments, {
        ...state,
        isLoading: false,
        error: null,
        signedDocuments: signedDocuments,
      });
    }

    case ActionTypes.UPDATE_UNREAD_COUNTER: {
      return {
        ...state,
        unreadCounter: action.payload,
      };
    }

    case ActionTypes.NEW_SIGNING_REQUESTS_COUNT_REQUEST: {
      return {
        ...state,
        newSigningRequestCount: 0,
      };
    }

    case ActionTypes.NEW_SIGNING_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        newSigningRequestCount: action.payload.unSignedDocuments,
        newInformationDocumentCount: action.payload.unReadDocumentInfo,
      };
    }

    case ActionTypes.RESET_DOCUMENTS: {
      return featureAdapter.removeAll({ ...state, metadata: null });
    }

    case ActionTypes.SET_SEC_SIGN_STATUS: {
      return {
        ...state,
        secSignStatus: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
