<ng-container *ngIf="getIsAnnouncementView()">
  <ng-container *ngIf="announcements$ | async as announcements">
    <div
      *ngFor="let announcement of announcements"
      class="alert announcement"
      role="alert"
    >
      {{ announcement }}
    </div>
  </ng-container>
</ng-container>
