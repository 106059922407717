import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { FEATURE_KEY, featureAdapter, State } from './state';
import {
  AccountInfoResponse,
  CountryModel,
  CurrencyModel,
  FeesModel,
  PaymentApprovalModel,
  PaymentFormModel,
  StandingOrdersModel,
} from '@models/payment.model';
import {
  PaymentAccountsModel,
  PaymentSortCodeModel,
} from '@models/account.model';
import {
  PaymentSecSignSessionType,
  SecSignStatusRejectedReason,
} from '@models/auth-session.model';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getCurrencies = (state: State): CurrencyModel[] =>
  state.currencies;
export const getCountries = (state: State): CountryModel[] => state.countries;
export const getFees = (state: State): FeesModel | null => state.fees;
export const getAccountInfo = (state: State): AccountInfoResponse | null =>
  state.accountInfo;
export const getSelectedAccount = (state: State): any =>
  state.selectedPaymentAccount;
export const getSortCode = (state: State): any => state.sortCodeData;
export const getFormData = (state: State): any => state.formData;
export const getSecSignStatus = (state: State): any => state.secSignStatus;
export const getSecSignStatusRejectedReason = (
  state: State
): SecSignStatusRejectedReason | null => state.secSignRejectedReason;
export const getStandingOrders = (state: State): any => state.standingOrders;
export const getPaymentApprovals = (state: State): any =>
  state.paymentApprovals;
export const getStandingOrderData = (state: State): any =>
  state.selectedStandingOrder;
export const getStandingOrderDeleteResult = (state: State): any =>
  state.standingOrderDeleteResult;
export const getIsDeclinePaymentSuccessful = (state: State): boolean | null =>
  state.isDeclinePaymentSuccessful;
export const getIsApprovedPaymentSuccessful = (state: State): boolean | null =>
  state.isApprovedPaymentSuccessful;
export const getAttorneyCode = (state: State): string => state.attorneyCode;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectCurrencies: MemoizedSelector<object, CurrencyModel[]> =
  createSelector(selectState, getCurrencies);

export const selectCountries: MemoizedSelector<object, CountryModel[]> =
  createSelector(selectState, getCountries);

export const selectAccountInfo: MemoizedSelector<
  object,
  AccountInfoResponse | null
> = createSelector(selectState, getAccountInfo);

export const getSelectedPaymentAccount: MemoizedSelector<
  object,
  PaymentAccountsModel
> = createSelector(selectState, getSelectedAccount);

export const getSortCodeData: MemoizedSelector<object, PaymentSortCodeModel> =
  createSelector(selectState, getSortCode);

export const selectCreatedPayment: MemoizedSelector<object, PaymentFormModel> =
  createSelector(selectState, getFormData);

export const selectSecSignStatus: MemoizedSelector<object, any> =
  createSelector(selectState, getSecSignStatus);

export const selectSecSignStatusRejectedReason: MemoizedSelector<
  object,
  SecSignStatusRejectedReason | null
> = createSelector(selectState, getSecSignStatusRejectedReason);

export const selectStandingOrders: MemoizedSelector<
  object,
  StandingOrdersModel[]
> = createSelector(selectState, getStandingOrders);

export const selectPaymentApprovals: MemoizedSelector<
  object,
  PaymentApprovalModel[]
> = createSelector(selectState, getPaymentApprovals);

export const getSelectedStandingOrderData: MemoizedSelector<
  object,
  StandingOrdersModel
> = createSelector(selectState, getStandingOrderData);

export const getStandingOrderDeleteActionResult: MemoizedSelector<
  object,
  PaymentSecSignSessionType
> = createSelector(selectState, getStandingOrderDeleteResult);

export interface StandingOrderDeleteActionResultWithReason {
  status: PaymentSecSignSessionType;
  reason: SecSignStatusRejectedReason | null;
}

export const getStandingOrderDeleteActionResultWithReason: MemoizedSelector<
  object,
  StandingOrderDeleteActionResultWithReason
> = createSelector(
  getStandingOrderDeleteActionResult,
  selectSecSignStatusRejectedReason,
  (status, reason) => {
    return {
      status,
      reason,
    };
  }
);

export const selectIsDeclinePaymentSuccessful: MemoizedSelector<
  object,
  boolean | null
> = createSelector(selectState, getIsDeclinePaymentSuccessful);

export const selectIsApprovedPaymentSuccessful: MemoizedSelector<
  object,
  boolean | null
> = createSelector(selectState, getIsApprovedPaymentSuccessful);

export const selectAttorneyCode: MemoizedSelector<object, string> =
  createSelector(selectState, getAttorneyCode);

export const selectFees: MemoizedSelector<object, FeesModel | null> =
  createSelector(selectState, getFees);

export const selectSecSignStatusWithRejectedReason = createSelector(
  selectSecSignStatus,
  selectSecSignStatusRejectedReason,
  (secSignStatus, secSignStatusRejectedReason) => {
    return {
      secSignStatus,
      secSignStatusRejectedReason,
    };
  }
);
