import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectChange as MatSelectChange,
} from '@angular/material/legacy-select';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { FeatureService } from '@core/services/feature.service';
import { SessionStorageService } from '@core/services/session-storage-util.service';
import { Environment } from '@models/auth-session.model';
import { MasterNumberModel } from '@models/navigation.model';
import { PaymentUtilService } from '@modules/payments/payments.service';
import { Store } from '@ngrx/store';
import {
  NavigationActions,
  NavigationSelectors,
} from '@store/navigation/navigation-store';
import { RelationshipinfoAction } from '@store/navigation/relationshipinfo-store';
import { Observable, Subscription, noop } from 'rxjs';

@Component({
  selector: 'wmp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class SecureHeaderComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked
{
  @ViewChild('masterNumberSelectorTrigger')
  public masterNumberSelectorTrigger?: ElementRef;

  @ViewChild('masterNumberSelectorDropdown')
  public masterNumberSelectorDropdown?: MatSelect;

  @ViewChild('masterNumberOptionsDropdown')
  public masterNumberOptionsDropdown?: MatOption;

  private masterNumberSelectorDropdownSelectionChange?: Subscription;
  private masterNumberSelectorDropdownOpenedChange?: Subscription;
  private masterNumbersSubscription?: Subscription;

  public masterNumbers$?: Observable<MasterNumberModel[]>;
  public allUnselectedMasterNumbers: MasterNumberModel[] = [];
  public allMasterNumbers: MasterNumberModel[] = [];
  public isWealthRoute = false;
  public pageTitle = '';
  public prevMasterNumber: MasterNumberModel[] = [];
  public payUtilSubscriptionMC: Subscription;
  public payUtilSubscriptionNP: Subscription;
  private routerSubscription: Subscription;
  private modalCancel = false;
  private noPaymentAccounts = false;
  public selectedValue: any;
  public selectedMasterNumber!: MasterNumberModel | undefined;

  constructor(
    private store$: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private authService: AuthService,
    private payUtilService: PaymentUtilService,
    private changeDetector: ChangeDetectorRef,
    private features: FeatureService
  ) {
    this.routerSubscription = router.events.subscribe(() => {
      this.setPageTitle();
    });

    this.payUtilSubscriptionMC = payUtilService.modalCancel$.subscribe(
      (cancel) => {
        this.modalCancel = cancel;
        if (this.modalCancel) {
          this.selectedValue = this.selectedMasterNumber?.masterNumber;
        }
      }
    );

    this.payUtilSubscriptionNP = payUtilService.noPaymentAccounts$.subscribe(
      (empty) => {
        this.noPaymentAccounts = empty;
      }
    );
  }

  private setPageTitle() {
    const activateRouteSnapshot = this.getDeepestActivateRouteSnapshot(
      this.activatedRoute.snapshot.root
    );
    this.isWealthRoute = activateRouteSnapshot.data.hasOwnProperty(
      'isWealthRoute'
    )
      ? activateRouteSnapshot.data.isWealthRoute
      : false;
    this.pageTitle = activateRouteSnapshot.data.hasOwnProperty('pageTitle')
      ? activateRouteSnapshot.data.pageTitle
      : 'Undefined';
  }

  private getDeepestActivateRouteSnapshot(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): ActivatedRouteSnapshot {
    return activatedRouteSnapshot.children.length
      ? this.getDeepestActivateRouteSnapshot(activatedRouteSnapshot.firstChild!)
      : activatedRouteSnapshot;
  }

  public ngOnInit(): void {
    if (sessionStorage.getItem('environment') == '') {
      let leavePoint = false;
      const intervalId = setInterval(() => {
        if (sessionStorage.getItem('environment') !== '') {
          leavePoint = true;
        }

        if (leavePoint) {
          this.setMasterNumberData();
          clearInterval(intervalId);
        }
      }, 500);
    } else {
      this.setMasterNumberData();
    }
  }

  public ngDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.payUtilSubscriptionMC.unsubscribe();
    this.payUtilSubscriptionNP.unsubscribe();
  }

  private setMasterNumberData(): void {
    if (sessionStorage.getItem('environment') === Environment.RMP) {
      this.createMasterNumberDataForRmp();
    } else {
      this.createMasterNumbersData();
    }
  }

  private createMasterNumbersData() {
    this.store$.dispatch(
      new NavigationActions.GetSelectedMasterNumberRequestAction()
    );
    this.store$.dispatch(
      new NavigationActions.LoadMasterNumbersRequestAction()
    );
    this.masterNumbers$ = this.store$.select(
      NavigationSelectors.selectMasterNumbers
    );
    this.masterNumbersSubscription = this.masterNumbers$.subscribe(
      (masterNumbers: MasterNumberModel[]) => {
        if (masterNumbers.length > 0) {
          this.allMasterNumbers = masterNumbers;
          this.allUnselectedMasterNumbers = masterNumbers.filter(
            (masterNumber: MasterNumberModel) =>
              masterNumber.masterNumber !==
              this.selectedMasterNumber?.masterNumber
          );
        }
      }
    );

    this.store$
      .select(NavigationSelectors.getSelectedMasterNumber)
      .subscribe((masterNumber: MasterNumberModel) => {
        if (masterNumber) {
          this.selectedMasterNumber = masterNumber;
          this.allUnselectedMasterNumbers = this.allMasterNumbers.filter(
            (masterNumber: MasterNumberModel) =>
              masterNumber.masterNumber !==
              this.selectedMasterNumber?.masterNumber
          );
        }
      });
  }

  public ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  public ngAfterViewInit(): void {
    this.masterNumberSelectorDropdownSelectionChange =
      this.masterNumberSelectorDropdown?.selectionChange.subscribe(
        (data: MatSelectChange) => {
          if (this.isPaymentForm() && !this.noPaymentAccounts) {
            sessionStorage.setItem('SetMasterNumberRequestAction', data.value);
            this.router
              .navigate(['/portfolio/summary'])
              ?.then()
              .catch(() => noop());
          } else {
            this.store$.dispatch(
              new NavigationActions.SetMasterNumberRequestAction(data.value)
            );
            this.store$
              .select(NavigationSelectors.selectIsNewMasterNumberSet)
              .subscribe((newMasterNumber: boolean) => {
                if (newMasterNumber) {
                  this.router
                    .navigate(['/portfolio/summary'])
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(() => noop());
                }
              });
          }
        }
      );
    this.masterNumberSelectorDropdownOpenedChange =
      this.masterNumberSelectorDropdown?.openedChange.subscribe((isOpen) => {
        if (!isOpen) {
          this.dispelMasterNumberSelectorDropdown();
        }
      });
  }

  isPaymentForm(): boolean {
    if (this.features.getFeatureToggleByKey('USE_NEW_BACKEND_FOR_PAYMENTS')) {
      return (
        this.router.url.indexOf('payments/new/initial') !== -1 ||
        this.router.url.indexOf('payments/new/review') !== -1 ||
        this.router.url.indexOf('payments/new/edit') !== -1
      );
    } else {
      return this.router.url.indexOf('payments/new') !== -1;
    }
  }

  public conjureMasterNumberSelectorDropdown(): void {
    if (this.allMasterNumbers.length > 1) {
      this.masterNumberSelectorTrigger?.nativeElement.classList.add('hide');
      this.masterNumberSelectorDropdown?.open();
    }
  }

  public dispelMasterNumberSelectorDropdown(): void {
    this.masterNumberSelectorTrigger?.nativeElement.classList.remove('hide');
    this.masterNumberSelectorDropdown?.close();
  }

  public ngOnDestroy(): void {
    this.masterNumberSelectorDropdownSelectionChange?.unsubscribe();
    this.masterNumberSelectorDropdownOpenedChange?.unsubscribe();
    this.masterNumbersSubscription?.unsubscribe();
  }

  public logout() {
    if (this.isRmp()) {
      this.router
        .navigate(['/rmp'])
        ?.then()
        .catch(() => noop());
    } else {
      this.authService.logout();
    }
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  public isAClientSelected() {
    return this.isRmp() ? this.sessionStorageService.isAClientSelected() : true;
  }

  private createMasterNumberDataForRmp() {
    this.masterNumbersSubscription = this.store$
      .select(NavigationSelectors.getSelectedMasterNumber)
      .subscribe((masterNumber: MasterNumberModel) => {
        this.selectedMasterNumber = masterNumber;
        if (masterNumber) {
          this.store$.dispatch(new RelationshipinfoAction.LoadRequestAction());
        }
      });
  }

  public getMasterNumberId(
    masterNumberArray: Array<MasterNumberModel>,
    masterNumber: MasterNumberModel
  ): string {
    return masterNumberArray
      .findIndex((element: MasterNumberModel) => element === masterNumber)
      .toString();
  }
}
