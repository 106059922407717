import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { FaqCategory } from '@models/faq.model';

export class LoadCategoriesRequest implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_REQUEST;
}

export class LoadCategoriesSuccess implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_SUCCESS;
  constructor(public payload: FaqCategory[]) {}
}

export class LoadCategoriesFailure implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPublicRequest implements Action {
  readonly type = ActionTypes.LOAD_PUBLIC_REQUEST;
}

export class LoadPublicSuccess implements Action {
  readonly type = ActionTypes.LOAD_PUBLIC_SUCCESS;
  constructor(public payload: FaqCategory[]) {}
}

export class LoadPublicFailure implements Action {
  readonly type = ActionTypes.LOAD_PUBLIC_FAILURE;
  constructor(public payload: { error: string }) {}
}
