export const LOGIN_CHECK_AUTH_SESSION = '[Auth] Login Check AuthSession';
export const REFRESH_TOKEN = '[Auth] Refresh Token';
export const REFRESH_TOKEN_SUCCESS = '[Auth] Refresh Token Success';
export const GET_ENVIRONMENT = '[Auth] Get Environment';
export const GET_ENVIRONMENT_SUCCESS = '[Auth] Get Environment Success';
export const CANCEL_AUTH_SESSION = '[Auth] Cancel Auth Session';
export const CANCEL_LOGIN_AUTH_SESSION = '[Auth] Cancel Login Auth Session';
export const LOGIN_USERNAME_AND_PASSWORD = '[Auth] Login Username and Password';
export const LOGIN_ATTEMPT_SUCCESS = '[Auth] Login Attempt Success';
export const CANCEL_ATTEMPT_SUCCESS = '[Auth] Cancel Attempt Success';
export const LOGIN_ATTEMPT_FAILURE = '[Auth] Login Attempt Failure';
export const POLL_2FA = '[Auth] Poll 2FA';
export const SET_SEC_SIGN_STATUS = '[Auth] Set SecSign Status';
export const SET_SEC_SIGN_PROCESSING_STATUS =
  '[Auth] Set SecSign Processing Status';
export const RESET_AUTH_STORE = '[Auth] Reset Auth Store';
export const LOGOUT = '[Auth] Logout';
export const ONBOARDING_CHANGE_PASSWORD = '[Auth] Onboarding Change Password';
export const ONBOARDING_CHANGE_PASSWORD_SUCCESS =
  '[Auth] Onboarding Change Password Success';
export const ONBOARDING_CHANGE_PASSWORD_FAILURE =
  '[Auth] Onboarding Change Password Failure';
export const GET_ONBOARDING_STATUS = '[Auth] Onboarding Status';
export const GET_ONBOARDING_STATUS_SUCCESS = '[Auth] Onboarding Status Success';
export const GET_ONBOARDING_STATUS_FAILURE = '[Auth] Onboarding Status Failure';
export const POLL_ONBOARDING_STATUS = '[Auth] Poll Onboarding Status';
export const GET_USER_ROLES_REQUEST = '[Auth] User Roles';
export const GET_USER_ROLES_SUCCESS = '[Auth] User Roles Success';
export const GET_USER_ROLES_FAILURE = '[Auth] User Roles Failure';
