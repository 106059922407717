import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  TemplateActions,
  TemplateActionTypes,
} from '@store/template-store/index';
import { TemplateService } from '@core/services/template/template.service';
import {
  TemplateModel,
  TemplateTransactionsByIbanResponseModel,
} from '@models/template.model';

@Injectable()
export class TemplateEffects {
  loadTemplatesByIban$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.LOAD_TEMPLATES_BY_IBAN_REQUEST),
      mergeMap((action: TemplateActions.LoadTemplatesByIbanRequestAction) =>
        this.templateService.getTemplateTransactionsByIban(action.payload).pipe(
          map((elements: TemplateTransactionsByIbanResponseModel) => {
            const elementList = elements.templates.concat(
              elements.transactions
            );
            return new TemplateActions.LoadTemplatesByIbanSuccessAction(
              elementList
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new TemplateActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.LOAD_TEMPLATES_REQUEST),
      mergeMap((action: TemplateActions.LoadTemplatesRequestAction) =>
        this.templateService.getTemplates(action.payload).pipe(
          map(
            (elements: TemplateModel[]) =>
              new TemplateActions.LoadTemplatesSuccessAction(elements)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new TemplateActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  deleteTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.DELETE_TEMPLATE_REQUEST),
      mergeMap((action: TemplateActions.DeleteTemplateRequestAction) =>
        this.templateService.deleteTemplate(action.templateId).pipe(
          map(
            (element: any) =>
              new TemplateActions.DeleteTemplateSuccessAction(element)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new TemplateActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  editTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.EDIT_TEMPLATE_REQUEST),
      mergeMap((action: TemplateActions.EditTemplateRequestAction) =>
        this.templateService.createOrEditTemplate(action.payload).pipe(
          map(
            (element: any) =>
              new TemplateActions.EditTemplateSuccessAction(element)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new TemplateActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  createNewTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.CREATE_NEW_TEMPLATE_REQUEST),
      mergeMap((action: TemplateActions.CreateNewTemplateRequestAction) =>
        this.templateService.createOrEditTemplate(action.payload).pipe(
          map(
            (element: any) =>
              new TemplateActions.CreateNewTemplateSuccessAction(element)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new TemplateActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private store$: Store,
    private actions$: Actions,
    private templateService: TemplateService
  ) {}
}
