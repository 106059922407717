import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultTo',
})
export class DefaultToPipe implements PipeTransform {
  public transform(value: any, defaultValue?: string): string {
    return value && value !== 'null'
      ? value
      : defaultValue !== undefined
      ? defaultValue
      : '-';
  }
}
