import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContentBannerResponseModel } from '@models/content-banner.model';

export const FEATURE_KEY = 'contentBanner';

export const featureAdapter: EntityAdapter<State> = createEntityAdapter();

export interface State extends EntityState<State> {
  isLoading: boolean;
  error: string | null;
  content: ContentBannerResponseModel | null;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  content: null,
});
