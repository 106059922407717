export const PATH_AUTH = '/auth/';
export const PATH_LOGIN = 'login';
export const PATH_ONBOARDING_CHANGE_PASSWORD = 'onboarding/change-password';
export const PATH_ONBOARDING_CHANGE_PASSWORD_CONFIRM =
  'onboarding/change-password-confirm';
export const PATH_ONBOARDING_INSTALL_APP = 'onboarding/install-app';
export const PATH_ONBOARDING_ACTIVATE_APP = 'onboarding/activate-app';
export const PATH_PAYMENTS = '/payments';
export const PATH_PAYMENT_NEW = '/payments/new';
export const PATH_PAYMENT_REVIEW = '/payments/new/review';
export const PATH_PORTFOLIO_SUMMARY = '/portfolio/summary';
