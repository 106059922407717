import { Action, ActionReducer, State } from '@ngrx/store';
import { LOGOUT } from '@store/auth-store/actionTypes';

export function clearState(reducer: ActionReducer<any>): any {
  return (state: State<any> | undefined, action: Action) => {
    if (action.type === LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
