export const LANGUAGE_CODE_DE = 'de';
export const LANGUAGE_CODE_EN = 'en';
export const DEFAULT_AMOUNT_DE = '0,00';
export const DEFAULT_AMOUNT_EN = '0.00';
export const DEFAULT_CURRENCY = 'EUR';
export const LOCAL_EN_DATE_FORMAT = 'dd/MM/yyyy';
export const LOCAL_DE_DATE_FORMAT = 'dd.MM.yyyy';
export const LOCAL_EN_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const LOCAL_DE_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const SHORT_DATE_FORMAT = 'yyyy-MM-dd';
export const AGGREGATED_PORTFOLIO_CODE = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

export const LOCALE_CODES: Map<string, string> = new Map<string, string>([
  [LANGUAGE_CODE_DE, 'de-DE'],
  [LANGUAGE_CODE_EN, 'en-GB'],
]);
