import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as env } from '../../../../environments/environment';
import { shareReplay, tap } from 'rxjs/operators';
import { Configuration } from './Configuration';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly apiUrl = '/assets/config.json';
  private configuration$?: Observable<Configuration>;
  private conf?: Configuration;

  constructor(private http: HttpClient) {}

  public load(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>('assets/config.json')
        .pipe(shareReplay(1))
        .pipe(tap((cfg) => (this.conf = cfg)));
    }
    return this.configuration$;
  }

  public getConfig(who: string): Configuration | undefined {
    return this.conf;
  }
}
