import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State, FEATURE_KEY, featureAdapter } from './state';
import { ContentBannerResponseModel } from '@models/content-banner.model';

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const getContent = (state: State): ContentBannerResponseModel | null =>
  state.content;
export const selectContent: MemoizedSelector<
  object,
  ContentBannerResponseModel | null
> = createSelector(selectState, getContent);
