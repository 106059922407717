import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    const ibanWithoutSpace: string = value.toUpperCase().replace(/\s/g, '');
    return this.formatIban(ibanWithoutSpace);
  }

  private formatIban(ibanWithoutSpace: string): string {
    return ibanWithoutSpace.replace(/(.{4})/g, '$1 ');
  }
}
