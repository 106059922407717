import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-support',
  templateUrl: './wmp-support.component.html',
  styleUrls: ['./wmp-support.component.scss'],
})
export class WmpSupportComponent implements OnDestroy {
  private isMenuOpen = false;
  private routerSubscription?: Subscription;

  public isViewingFaq = false;
  public href: string = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isViewingFaq = event.url.toLowerCase().includes('faq');
      }
    });
    if (this.router.url) {
      this.isViewingFaq = this.router.url.toLowerCase().includes('faq');
    }
  }

  public ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.isMenuOpen
      ? this.renderer.addClass(this.elementRef.nativeElement, 'open')
      : this.renderer.removeClass(this.elementRef.nativeElement, 'open');
  }

  public navigateToFaq(): void {
    this.router.navigateByUrl('/auth/faq');
    this.toggleMenu();
  }

  public navigateToLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }
}
