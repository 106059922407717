import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  isAClientSelected() {
    return (
      sessionStorage.getItem('isSettingNewMasterNumberSuccessful') === 'true'
    );
  }
}
