<p *ngIf="date">{{ 'securityValueDate.label' | translate }}</p>
<p *ngIf="date">
  <i>
    <img
      alt="NavigateFaq"
      src="../../../assets/images/icons/help-circle-outline.svg"
    />
    <div class="info" (click)="navigateToFaq()">
      <p [innerHTML]="'securityValueDate.info' | translate"></p>
    </div>
  </i>
  <span>{{ date | localeDateFormat }}</span>
</p>
