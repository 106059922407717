import { FaqCategory, FaqEntry } from '@models/faq.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FEATURE_KEY, featureAdapter, State } from './state';

export const getError = (state: State): string | null => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getCategories = (state: State): FaqCategory[] => state.categories;
export const getPublic = (state: State): FaqEntry[] => state.public;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);
export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
export const selectCategories: MemoizedSelector<object, FaqCategory[]> =
  createSelector(selectState, getCategories);
export const selectPublic: MemoizedSelector<object, FaqEntry[]> =
  createSelector(selectState, getPublic);
