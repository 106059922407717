import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TemplateModel } from '@models/template.model';

export const FEATURE_KEY = 'template';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  error?: any;
  isLoading: boolean;
  transactions?: TemplateModel;
  templates?: TemplateModel[];
  selectedTemplate?: TemplateModel;
  isDeletionSuccessful: boolean | null;
  isEditingSuccessful: boolean | null;
  isCreatingSuccessful: boolean | null;
}

export const initialState: State = featureAdapter.getInitialState({
  error: null,
  isLoading: false,
  templates: [],
  isDeletionSuccessful: null,
  isEditingSuccessful: null,
  isCreatingSuccessful: null,
});
