import * as ActionTypes from './actionTypes';
import { featureAdapter, initialState, State } from './state';

export function faqReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_CATEGORIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        categories: action.payload,
      };
    }

    case ActionTypes.LOAD_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_PUBLIC_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_PUBLIC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        public: action.payload,
      };
    }

    case ActionTypes.LOAD_PUBLIC_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
