import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from '@store/_meta-reducers';
import { AccountStoreModule } from '@store/account-store';
import { AuthStoreModule } from '@store/auth-store';
import { ContentBannerStoreModule } from '@store/content-banner-store';
import { DocumentStoreModule } from '@store/document-store';
import { MarketingCampaignStoreModule } from '@store/marketing-campaign-store/marketing-campaign-store.module';
import { NavigationStoreModule } from '@store/navigation/navigation-store';
import { RelationshipInfoStoreModule } from '@store/navigation/relationshipinfo-store';
import { PaymentStoreModule } from '@store/payment-store';
import { SpecificationStoreModule } from '@store/portfolio/specification-store';
import { RmpStoreModule } from '@store/rmp-store/rmp-store.module';
import { TemplateStoreModule } from '@store/template-store';
import { environment } from '../../environments/environment';
import { FaqStoreModule } from './faq-store';
import { RouterStoreModule } from './router-store/router-store.module';

export const reducers: ActionReducerMap<any> = {};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthStoreModule,
    MarketingCampaignStoreModule,
    RouterStoreModule,
    AccountStoreModule,
    NavigationStoreModule,
    PaymentStoreModule,
    TemplateStoreModule,
    RmpStoreModule,
    SpecificationStoreModule,
    DocumentStoreModule,
    FaqStoreModule,
    ContentBannerStoreModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 40, // Retains last n states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    RelationshipInfoStoreModule,
  ],
})
export class RootStoreModule {}
