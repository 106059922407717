import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { CampaignModel } from '@models/marketing-campaign.model';

export class LoadCurrentCampaignRequest implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_CAMPAIGN_REQUEST;
}

export class LoadCurrentCampaignSuccess implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_CAMPAIGN_SUCCESS;

  constructor(public payload: CampaignModel) {}
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public payload: { error: string }) {}
}
