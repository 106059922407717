import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import {
  KEY_CURRENT_LANGUAGE,
  KEY_HTTP_LANGUAGE_HEADER,
} from '../constants/keys';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the current language from the service.
    const currentLang = this.localStorage.getItem(KEY_CURRENT_LANGUAGE);

    // skip if no language is available
    if (!currentLang) {
      return next.handle(req);
    }

    // set Header
    const langReq = req.clone({
      setHeaders: { [KEY_HTTP_LANGUAGE_HEADER]: currentLang },
    });

    // send cloned request with header to the next handler.
    return next.handle(langReq);
  }
}
