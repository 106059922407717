import { Component } from '@angular/core';
import { Environment } from '@models/auth-session.model';

@Component({
  selector: 'wmp-layout-public',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class PublicLayoutComponent {
  constructor() {}

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }
}
