import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { RelationshipInfo } from '@models/relationshipinfo.model';
import { ServiceResult } from '@models/serviceResult.model';

export class Init implements Action {
  readonly type = ActionTypes.INIT;
  constructor(public payload: RelationshipInfo) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: ServiceResult<RelationshipInfo>) {}
}

export class ResetData implements Action {
  readonly type = ActionTypes.RESET_DATA;
}
