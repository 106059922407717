import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-tab-nav',
  templateUrl: './wmp-tab-nav.component.html',
  styleUrls: ['./wmp-tab-nav.component.scss'],
})
export class WmpTabNavComponent implements OnInit, OnDestroy {
  @Input() links?: { title: string; path: string; tooltip?: boolean }[];
  @Output() public tabChangeEmitter: EventEmitter<string> = new EventEmitter();
  subscription: Subscription | undefined;

  constructor(private router: Router) {}

  isLinkActive(link: string) {
    return this.router.url.includes(link);
  }

  onClick(link: string) {
    this.tabChangeEmitter.emit(link);
  }

  ngOnInit(): void {
    const url = this.router.url.split('/');
    if (url.length > 0) {
      this.tabChangeEmitter.emit(url[url.length - 1]);
    }

    this.subscription = this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.tabChangeEmitter.emit(routeChange.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
