import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CampaignModel } from '@models/marketing-campaign.model';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketingCampaignService {
  constructor(private http: HttpClient) {}

  public getCurrentCampaign(): Observable<CampaignModel> {
    return this.http.get<CampaignModel>(
      `/public/api/v1/marketing-campaigns/current`
    );
  }

  public getCurrentCampaignImage(imageId: number): Observable<any> {
    return this.http
      .get<string>(`/public/api/v1/marketing-campaigns/images/${imageId}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(switchMap((response: string) => this.readFile(response)));
  }

  private readFile(blob: any): Observable<string> {
    return Observable.create((obs: any) => {
      const reader = new FileReader();
      reader.onload = () => obs.next(reader.result);
      return reader.readAsDataURL(blob);
    });
  }
}
