import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { FEATURE_KEY, featureAdapter, State } from './state';
import { TemplateModel } from '@models/template.model';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getIsDeletionSuccessful = (state: State): boolean | null =>
  state.isDeletionSuccessful;
export const getIsEditingSuccessful = (state: State): boolean | null =>
  state.isEditingSuccessful;
export const getIsCreatingSuccessful = (state: State): boolean | null =>
  state.isCreatingSuccessful;
export const getTemplateData = (state: State): any => state.selectedTemplate;
export const getTransactions = (state: State): any => state.transactions;

export const getTemplates = (state: State): any => state.templates;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectIsDeletionSuccessful: MemoizedSelector<
  object,
  boolean | null
> = createSelector(selectState, getIsDeletionSuccessful);

export const selectIsEditingSuccessful: MemoizedSelector<
  object,
  boolean | null
> = createSelector(selectState, getIsEditingSuccessful);

export const selectIsCreatingSuccessful: MemoizedSelector<
  object,
  boolean | null
> = createSelector(selectState, getIsCreatingSuccessful);

export const selectTransactions: MemoizedSelector<object, TemplateModel[]> =
  createSelector(selectState, getTransactions);

export const getSelectedTemplateData: MemoizedSelector<object, TemplateModel> =
  createSelector(selectState, getTemplateData);

export const selectTemplates: MemoizedSelector<object, TemplateModel[]> =
  createSelector(selectState, getTemplates);
