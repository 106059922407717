import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverviewService } from '@core/services/portfolio/overview.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-security-value-date',
  templateUrl: './security-value-date.component.html',
  styleUrls: ['./security-value-date.component.scss'],
})
export class SecurityValueDateComponent implements OnInit, OnDestroy {
  private portfolioSubscription?: Subscription;

  public date?: string;

  constructor(
    private router: Router,
    private overViewService: OverviewService
  ) {}

  public ngOnInit(): void {
    this.portfolioSubscription = this.overViewService
      .getPortfolioList()
      .subscribe((result) => {
        if (result && result.portfolios?.length > 0) {
          this.date = (
            result.portfolios[0]?.currentValues?.evalDate ||
            result.aggregatedPortfolio?.currentValues?.evalDate
          ).toString();
        }
      });
  }

  public ngOnDestroy(): void {
    this.portfolioSubscription?.unsubscribe();
  }

  public navigateToFaq = (): void => {
    this.router.navigateByUrl('/faq');
  };
}
