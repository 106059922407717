import { Injectable } from '@angular/core';
import { KEY_IS_TIMEOUT } from '@core/constants/keys';
import { AppService } from '@core/services/app.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@core/services/auth.service';
import { IdleComponent } from '@modules/idle/idle.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TwoFactorAuthService } from '@core/services/twoFactorAuth/two-factor-auth.service';
import { NgIdleService } from './ng-idle.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  TOKEN_ACTIVE_TIME = 0;
  DEFAULT_TIMEOUT_TIME = 1;

  dialogRef!: MatDialogRef<IdleComponent>;
  isModalOpen = false;

  idleTimerLeft = '';
  secondTimerLeft = '';
  isRefreshToken = false;
  refreshTimeMinimum = 35000;

  isRmp = false;

  appServiceUserLoggedInSubscription?: Subscription;
  appServiceAuthStatusSubscription?: Subscription;
  appServiceRefreshTokenSubscription?: Subscription;
  ngIdleUserIdlenessCheckerSubscription?: Subscription;
  ngIdleSecondLevelUserIdleCheckerSubscription?: Subscription;

  constructor(
    private ngIdle: NgIdleService,
    private appService: AppService,
    private modalService: NgbModal,
    private authService: AuthService,
    private dialog: MatDialog,
    private twoAuthModal: TwoFactorAuthService
  ) {}

  init(): void {
    this.appServiceUserLoggedInSubscription = this.appService
      .getUserLoggedIn()
      .subscribe((userLoggedIn: any) => {
        this.isRmp = this.toBoolean(
          sessionStorage.getItem('isRmpLoginSuccessful')
        );
        if (this.toBoolean(userLoggedIn) || this.toBoolean(this.isRmp)) {
          this.initTimers();
        }
      });
  }

  initTimers() {
    this.onDestroy();
    this.appServiceAuthStatusSubscription = this.authService
      .getAuthStatus()
      .subscribe((result) => {
        this.TOKEN_ACTIVE_TIME = Number(result.expiresIn) / 60 - 1;

        this.idleTimerLeft = '';
        this.secondTimerLeft = '';

        this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = this.TOKEN_ACTIVE_TIME;
        this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = this.DEFAULT_TIMEOUT_TIME;

        this.ngIdle.initDiffIdle();

        // Watcher on timer
        this.ngIdle.initilizeSessionTimeout();
        this.ngIdleUserIdlenessCheckerSubscription =
          this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
            this.initiateFirstTimer(status);
          });

        this.ngIdleSecondLevelUserIdleCheckerSubscription =
          this.ngIdle.secondLevelUserIdleChecker.subscribe((status: string) => {
            this.initiateSecondTimer(status);
          });

        this.isRefreshToken = false;
      });
  }

  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        const timeLeft = Number(this.ngIdle.getDiffIdle());
        if (timeLeft == -1) break;
        if (
          !this.isLogoutModalOpen() &&
          !this.isRefreshToken &&
          Number(timeLeft) <= this.refreshTimeMinimum
        ) {
          this.getNewToken();
        }
        break;

      case 'STOPPED_TIMER':
        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        if (
          this.isRmp &&
          !this.isRefreshToken &&
          Number(status) <= this.refreshTimeMinimum
        ) {
          this.getNewToken();
        }
        break;
    }
  };

  initiateSecondTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':
        if (this.isRmp) {
          this.getNewToken();
        } else if (!this.isRmp) {
          this.openTimeoutModal();
        }
        break;

      case 'SECOND_TIMER_STOPPED':
        if (this.isRmp) {
          this.getNewToken();
        } else if (!this.isRmp) {
          this.modalLogout();
        }
        break;

      default:
        this.secondTimerLeft = status;
        const retValue = Number(this.secondTimerLeft.split(':')[1]) % 2;
        if (retValue == 0) {
          this.getAuthStatus();
        }
        break;
    }
  };

  getAuthStatus() {
    this.appServiceAuthStatusSubscription = this.authService
      .getAuthStatus()
      .subscribe(
        (result) => {
          if (Number(result.expiresIn) <= 0) {
            this.modalLogout();
          }
        },
        (error) => {
          this.modalLogout();
        }
      );
  }

  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        seconds -= min * 60;
      }

      return `${min}:${seconds}`;
    }

    return '';
  };

  toBoolean(value: any): boolean {
    return value ? value.toString().toLowerCase().trim() === 'true' : false;
  }

  getNewToken() {
    this.isRefreshToken = true;
    this.refreshToken();
  }

  refreshToken(): void {
    this.appServiceRefreshTokenSubscription = this.authService
      .refreshToken()
      .subscribe(
        (result) => {
          this.authService.saveTokenToSessionStorage();

          this.ngIdle.unsubscribeBehavioural();
          NgIdleService.runTimer = false;
          this.initTimers();
        },
        (error) => {
          this.modalLogout();
        }
      );
  }

  stay(): void {
    this.dialogRef.close();
    NgIdleService.runTimer = false;
    NgIdleService.runSecondTimer = false;
  }

  openTimeoutModal(): void {
    if (
      !this.isModalOpen &&
      !this.isRefreshToken &&
      !this.twoAuthModal.isDialogOpen()
    ) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef = this.dialog.open(IdleComponent, dialogConfig);
      this.dialogRef.addPanelClass('logout');
      this.isModalOpen = true;
      this.dialogRef.afterClosed().subscribe(() => {
        this.isModalOpen = false;
        this.modalService.dismissAll();
        this.refreshToken();
      });
    }
    if (this.twoAuthModal.isDialogOpen()) {
      this.refreshToken();
    }
  }

  getIdleState(): string {
    const retValue = this.secondTimerLeft.split(':')[1];
    return !retValue ? '60' : retValue;
  }

  isLogoutModalOpen(): boolean {
    return this.isModalOpen;
  }

  modalLogout() {
    if (sessionStorage.getItem(KEY_IS_TIMEOUT) != 'true') {
      // stop all timer and end the session
      NgIdleService.runTimer = false;
      NgIdleService.runSecondTimer = false;

      this.dialogRef.close();

      sessionStorage.setItem(KEY_IS_TIMEOUT, 'true');
      this.appService.setUserLoggedIn(false);

      this.authService.logout();
    }
  }

  onDestroy() {
    this.appServiceUserLoggedInSubscription?.unsubscribe();
    this.appServiceAuthStatusSubscription?.unsubscribe();
    this.appServiceRefreshTokenSubscription?.unsubscribe();
    this.ngIdleUserIdlenessCheckerSubscription?.unsubscribe();
    this.ngIdleSecondLevelUserIdleCheckerSubscription?.unsubscribe();
  }
}
