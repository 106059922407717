export enum AuthSessionType {
  SESSION_STATE_PENDING = 'SESSION_STATE_PENDING',
  SESSION_STATE_AUTHENTICATED = 'SESSION_STATE_AUTHENTICATED',
  SESSION_STATE_DENIED = 'SESSION_STATE_DENIED',
  SESSION_STATE_CANCELED = 'SESSION_STATE_CANCELED',
  SESSION_STATE_EXPIRED = 'SESSION_STATE_EXPIRED',
  SESSION_STATE_FETCHED = 'SESSION_STATE_FETCHED',
  SESSION_STATE_INVALID = 'SESSION_STATE_INVALID',
  SESSION_STATE_NOSTATE = 'SESSION_STATE_NOSTATE',
  SESSION_STATE_ONBOARDING_NOT_INITED = 'ONBOARDING_NOT_INITED',
  SESSION_STATE_ONBOARDING_PENDING = 'ONBOARDING_PENDING',
  SESSION_STATE_ONBOARDING_FINISHED = 'ONBOARDING_FINISHED',
  SESSION_STATE_ONBOARDING_CHANGE_PASSWORD_INVALID = 'ONBOARDING_CHANGE_PASSWORD_INVALID',
}

export enum PaymentSecSignSessionType {
  NO_STATE = 'NO_STATE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PAYMENT_LIMIT_EXCEEDED = 'PAYMENT_LIMIT_EXCEEDED',
  PROCESSED = 'PROCESSED',
  CANCELLED = 'CANCELLED',
}

export enum SecSignStatusRejectedReason {
  ACCOUNT_FROZEN = 'ACCOUNT_FROZEN',
  PAYMENT_STATUS_NOT_OK = 'PAYMENT_STATUS_NOT_OK',
}

export enum Environment {
  WMP = 'wmp',
  RMP = 'rmp',
}

export enum DocumentSigningSecSignSessionType {
  NO_STATE = 'NO_STATE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PROCESSED = 'PROCESSED',
  CANCELLED = 'CANCELLED',
}

export enum DocumentSigningStatusType {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  PROCESSED = 'PROCESSED',
  SIGNED = 'SIGNED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  READ = 'READ',
  DELETED = 'DELETED',
  OPEN = 'OPEN',
  UPLOADED = 'UPLOADED',
}
