import * as ActionTypes from './actionTypes';
import { Action } from '@ngrx/store';
import { ContentBannerResponseModel } from '@models/content-banner.model';

export class GetContentBannerRequestAction implements Action {
  readonly type = ActionTypes.GET_CONTENT_BANNER_REQUEST;
}

export class GetContentBannerFailureAction implements Action {
  readonly type = ActionTypes.GET_CONTENT_BANNER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class GetContentBannerSuccessAction implements Action {
  readonly type = ActionTypes.GET_CONTENT_BANNER_SUCCESS;
  constructor(public payload: ContentBannerResponseModel) {}
}
