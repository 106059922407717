import { PortfolioSpecificationsDto } from '@models/specification.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'specification';

export const featureAdapter: EntityAdapter<PortfolioSpecificationsDto> =
  createEntityAdapter<PortfolioSpecificationsDto>({});

export interface State extends EntityState<PortfolioSpecificationsDto> {
  isLoading: boolean;
  error?: any;
  specifications: PortfolioSpecificationsDto | null;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  specifications: null,
});
