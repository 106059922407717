import { FaqCategory, FaqEntry } from '@models/faq.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'faqs';
export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error: string | null;
  categories: FaqCategory[];
  public: FaqEntry[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  categories: [],
  public: [],
});
