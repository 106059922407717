import * as Sentry from '@sentry/angular';

export class SentryLogFilter {
  private static filters = {
    '##EMAIL##': new RegExp('S+@S+', 'g'),
    '##DECIMAL##': new RegExp('d+[,.]d+', 'g'),
    '##IBAN##': new RegExp(
      '[A-Z]{2}d{2}s?w{4,4}s?w{4,4}s?w{4,4}s?w{4,4}s?w{2,}',
      'g'
    ),
    '##LONG_NUMBER##': new RegExp('d{5,}', 'g'),
    '##SECSIGN_1##': new RegExp('"Description" - ".*?"', 'g'),
    '##SECSIGN_2##': new RegExp('"BIC" - ".*?"', 'g'),
    '##SECSIGN_3##': new RegExp('BIC[w*?]'),
    '##SECSIGN_4##': new RegExp('"Recipient" - ".*?"', 'g'),
  };

  static filterLogMessage(message?: string): string {
    if (!message) return '-';

    let filteredMessage = message;
    for (const [key, value] of Object.entries(SentryLogFilter.filters)) {
      filteredMessage = filteredMessage.replace(value, key);
    }

    return filteredMessage;
  }

  static filterSentryEvent(
    event: Sentry.ErrorEvent,
    hint: Sentry.EventHint
  ): Sentry.ErrorEvent {
    const filtered: Sentry.ErrorEvent = {
      ...event,
      message: SentryLogFilter.filterLogMessage(event.message),
    };

    return filtered;
  }

  static filterSentryBreadcrumb(
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint
  ): Sentry.Breadcrumb {
    const filtered: Sentry.Breadcrumb = {
      ...breadcrumb,
      message: breadcrumb.message,
    };

    if (breadcrumb.message) {
      filtered.message = SentryLogFilter.filterLogMessage(breadcrumb.message);
    }

    const url = breadcrumb.data?.['url'];
    if (url && typeof url == 'string') {
      const filteredUrl = SentryLogFilter.filterLogMessage(url);
      filtered.data = { ...filtered.data, url: filteredUrl };
    }

    return filtered;
  }
}
