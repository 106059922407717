<ul>
  <li>
    <ng-container *ngIf="isViewingFaq">
      <button id="goBack" type="button" (click)="navigateToLogin()">
        <span>{{ 'general.button.back' | translate }}</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!isViewingFaq">
      <button id="needAssistance" type="button" (click)="toggleMenu()">
        <span>{{ 'login.needAssistance' | translate }}</span>
        <i
          ><img
            alt="needAssistance"
            src="../../../../assets/images/icons/close.svg"
        /></i>
      </button>
    </ng-container>
  </li>
  <li>
    <button id="loginFaq" type="button" (click)="navigateToFaq()">
      <i
        ><img src="../../../../assets/images/icons/help-circle-outline.svg"
      /></i>
      <span>{{ 'login.faq' | translate }}</span>
    </button>
  </li>
  <li>
    <button id="phoneNumber" type="button">
      <i
        ><img
          alt="PhoneNumber"
          src="../../../../assets/images/icons/headset.svg"
      /></i>
      <span>+49 40 350 60-789</span>
    </button>
  </li>
</ul>
