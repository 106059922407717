import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as FeatureActions from './actions';
import * as ActionTypes from './actionTypes';
import { CampaignModel } from '@models/marketing-campaign.model';
import { MarketingCampaignService } from '@core/services/marketing-campaign/marketing-campaign.service';

@Injectable()
export class MarketingCampaignEffects {
  loadCurrentCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_CURRENT_CAMPAIGN_REQUEST),
      mergeMap(() =>
        this.marketingCampaignService.getCurrentCampaign().pipe(
          map(
            (data: CampaignModel) =>
              new FeatureActions.LoadCurrentCampaignSuccess(data)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailure({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private marketingCampaignService: MarketingCampaignService
  ) {}
}
