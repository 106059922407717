import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BackendErrorService } from '@core/services/backend-error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-bad-request',
  templateUrl: './wmp-bad-request.component.html',
  styleUrls: ['./wmp-bad-request.component.scss'],
})
export class WmpBadRequestComponent implements OnInit, OnDestroy {
  public badRequestError = false;
  private errorSubscription?: Subscription;

  constructor(private backendErrorService: BackendErrorService) {}

  ngOnInit(): void {
    this.errorSubscription =
      this.backendErrorService.badRequestCatch$.subscribe(
        (badRequest: boolean) => {
          this.badRequestError = badRequest;
        }
      );
  }

  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(): void {
    this.backendErrorService.badRequestCatch.next(false);
  }
}
