import {
  AccountCategory,
  AccountDto,
  AccountGroupDto,
  AccountOverviewDto,
  CashAccountTransactionDto,
  CashAccountTransactionFilterOptionsDto,
  CashAccountTransactionPurposeCodeDto,
} from './account.model.new';

export interface ProductSummaryModel {
  aggregatedBalance: AggregatedBalanceModel;
  cashAccounts: AccountModel;
  creditAccounts: AccountModel;
  others: AccountModel;
  savingsAccounts: AccountModel;
  securitiesAccounts: AccountModel;
  timeDepositAccounts: AccountModel;
}

export function convertAccountOverviewDto(
  productSummary: ProductSummaryModel
): AccountOverviewDto {
  const accountGroups: AccountGroupDto[] = [];
  if (productSummary.cashAccounts.products.length > 0) {
    accountGroups.push(
      toAccountGroup(productSummary.cashAccounts, AccountCategory.Cash)
    );
  }

  if (productSummary.timeDepositAccounts.products.length > 0) {
    accountGroups.push(
      toAccountGroup(
        productSummary.timeDepositAccounts,
        AccountCategory.Timedeposit
      )
    );
  }

  if (productSummary.savingsAccounts.products.length > 0) {
    accountGroups.push(
      toAccountGroup(productSummary.savingsAccounts, AccountCategory.Savings)
    );
  }

  if (productSummary.creditAccounts.products.length > 0) {
    accountGroups.push(
      toAccountGroup(productSummary.creditAccounts, AccountCategory.Credit)
    );
  }

  if (productSummary.securitiesAccounts.products.length > 0) {
    accountGroups.push(
      toAccountGroup(
        productSummary.securitiesAccounts,
        AccountCategory.Securities
      )
    );
  }

  if (productSummary.others.products.length > 0) {
    accountGroups.push(
      toAccountGroup(productSummary.others, AccountCategory.Others)
    );
  }

  return <AccountOverviewDto>{
    total: {
      amount: productSummary.aggregatedBalance.value,
      ccyIso: productSummary.aggregatedBalance.currency,
    },
    accountGroups: accountGroups,
  };
}

function toAccountGroup(
  accountModel: AccountModel,
  category: AccountCategory
): AccountGroupDto {
  return <AccountGroupDto>{
    accountCategory: category,
    nameDe: accountModel.name,
    nameEn: accountModel.name,
    totalValue: {
      amount: accountModel.aggregatedBalance.value,
      ccyIso: accountModel.aggregatedBalance.currency,
    },
    accounts: accountModel.products.map(
      (product) =>
        <AccountDto>{
          accountNo: product.productNumber,
          currentValues: {
            bookedBalance: {
              amount: product.currentBalance,
              ccyIso: product.currency,
            },
          },
          name: product.name,
          iban: product.IBAN,
          ccyIsoCode: product.currency,
          hasDetails:
            category === AccountCategory.Cash ||
            category === AccountCategory.Timedeposit ||
            category === AccountCategory.Savings ||
            (category === AccountCategory.Securities &&
              accountModel.products.filter(
                (item: ProductModel) =>
                  item.additions.securityAccountPositionsSize == 0
              ).length === 0),
        }
    ),
  };
}

export function convertPurposeCodeDto(
  purposeCodesModel: PurposeCodesModel[]
): CashAccountTransactionFilterOptionsDto {
  let purposeCodes = <CashAccountTransactionFilterOptionsDto>{};
  purposeCodes.purposeCodes = [];
  if (purposeCodesModel.length == 0) return purposeCodes;

  purposeCodesModel.forEach((element) => {
    let purposeCode = <CashAccountTransactionPurposeCodeDto>{};
    purposeCode = {
      id: element.id,
      code: element.id,
      descriptionDe: element.description,
      descriptionEn: element.description,
    };
    purposeCodes.purposeCodes.push(purposeCode);
  });

  return purposeCodes;
}

export function convertCashTransactionDto(
  accountTransactions: AccountTransactionModel[]
): CashAccountTransactionDto[] {
  let cashAccountObj = <CashAccountTransactionDto[]>{};
  cashAccountObj = [];
  if (accountTransactions.length == 0) return cashAccountObj;

  accountTransactions.forEach((element) => {
    let cashAccount = <CashAccountTransactionDto>{};
    cashAccount.counterPartyNameLines = [];
    cashAccount.counterPartyNameLines[0] = element.counterPartyName;
    cashAccount.descriptionLines = [];
    let transactionId = 0;
    if (element && element.externalId) {
      transactionId = splitIdFromExternalId(element.externalId);
    }

    cashAccount = {
      amount: {
        amount: element.amount.toString(),
        ccyIso: element.currency,
      },
      bookingDate: element.bookingDate,
      cashAccountId: element.externalId,
      counterPartyBic: element.counterPartyBIC,
      counterPartyIban: element.counterPartyAccountNumber,
      counterPartyNameLines: cashAccount.counterPartyNameLines,
      currencyExchangeRate: element.currencyExchangeRate,
      descriptionLines: element.description
        ? element.description.split(/\${\n}/g)
        : cashAccount.descriptionLines,
      endToEndId: element?.additions?.endToEndId,
      id: element.externalId,
      instructedAmount: {
        amount: '0',
        ccyIso: element.instructedCurrency ? element.instructedCurrency : '',
      },
      purposeCode: {
        id: '',
        code: '',
        descriptionDe: element.type,
        descriptionEn: element.type,
      },
      transactionCode: transactionId,
      ultimateCreditor: element?.additions?.ultimateCreditor,
      ultimateDebtor: element?.additions?.ultimateDebtor,
      valueDate: element.valueDate,
    };
    cashAccountObj.push(cashAccount);
  });

  return cashAccountObj;
}

export function convertPendingPaymentDto(
  paymentOrders: PendingPaymentsModel[]
): CashAccountTransactionDto[] {
  let cashAccountObj = <CashAccountTransactionDto[]>{};
  cashAccountObj = [];
  if (paymentOrders.length == 0) return cashAccountObj;

  paymentOrders.forEach((element) => {
    let cashAccount = <CashAccountTransactionDto>{};
    cashAccount.counterPartyNameLines = [];
    cashAccount.counterPartyNameLines[0] = element.counterPartyName;
    cashAccount.descriptionLines = [];
    let transactionId = 0;
    if (element && element.externalId) {
      transactionId = splitIdFromExternalId(element.externalId);
    }

    cashAccount = {
      amount: {
        amount: element.amount.toString(),
        ccyIso: element.ccyIsoCode,
      },
      bookingDate: '',
      cashAccountId: element.externalId,
      counterPartyBic: element.counterPartyBic,
      counterPartyIban: element.counterPartyAccountNumber,
      counterPartyNameLines: cashAccount.counterPartyNameLines,
      currencyExchangeRate: '',
      descriptionLines: element.description
        ? element.description.split(/\${\n}/g)
        : cashAccount.descriptionLines,
      endToEndId: '',
      id: element.externalId,
      instructedAmount: {
        amount: '0',
        ccyIso: '',
      },
      purposeCode: {
        id: '',
        code: '',
        descriptionDe: '',
        descriptionEn: '',
      },
      transactionCode: transactionId,
      ultimateCreditor: '',
      ultimateDebtor: '',
      valueDate: '',
    };
    cashAccountObj.push(cashAccount);
  });

  return cashAccountObj;
}

export function splitIdFromExternalId(externalId: string): number {
  let transactionId = 0;
  if (externalId) {
    const externalNumber = externalId.match(/[a-z]+|[^a-z]+/gi);
    if (externalNumber && externalNumber.length > 1)
      transactionId = Number(externalNumber[1]);
  }
  return transactionId;
}

export interface AggregatedBalanceModel {
  currency: string;
  value: string;
  additions: AdditionsModel;
}

export interface AdditionsModel {
  assetValueDate: string;
}

export interface AccountModel {
  name: string;
  additions: AdditionsModel;
  aggregatedBalance: AggregatedBalanceModel;
  securityAccountPositionsSize: string;
  products: ProductModel[];
}

export interface ProductModel {
  IBAN: string;
  additions: {
    transferLimitCcy: string;
    transferLimit: string;
    securityAccountPositionsSize: number;
  };
  balanceLimit: string;
  currency: string;
  currentBalance: string;
  id: string;
  name: string;
  productKindName: string;
  productNumber: string;
}

export const MockProductModel = {
  IBAN: 'DE65201200000056138001',
  additions: {
    transferLimit: '10000',
    transferLimitCcy: 'EUR',
    securityAccountPositionsSize: 0,
  },
  balanceLimit: '584130.44',
  currency: 'EUR',
  currentBalance: '601187.90',
  id: '0056138001',
  name: 'Abwicklungskonto VD',
  productKindName: 'cashAccounts',
  productNumber: '0056138001',
};

export interface AccountListModel {
  id: string;
  value: AccountModel;
}

export enum ACCOUNT_TYPE {
  CASH_ACCOUNT = 'cashAccounts',
  TIME_DEPOSIT_ACCOUNT = 'timeDepositAccounts',
  CREDIT_ACCOUNT = 'creditAccounts',
  SAVINGS_ACCOUNT = 'savingsAccount',
  SECURITIES_ACCOUNT = 'securitiesAccounts',
  OTHERS = 'others',
}

export interface AccountTransactionModel {
  additions: {
    ultimateCreditor: string;
    endToEndId: string;
    ultimateDebtor: string;
  };
  endToEndId: string;
  ultimateCreditor: string;
  ultimateDebtor: string;
  amount: number;
  bookingDate: string;
  currency: string;
  counterPartyName: string;
  counterPartyAccountNumber: string;
  counterPartyBIC: string;
  description: string;
  externalId: string;
  type: string;
  valueDate: string;
  instructedCurrency: string;
  currencyExchangeRate: string;
}

export interface AccountTransactionFilterModel {
  search?: string;
  amountFrom?: string;
  amountTo?: string;
  dateFrom?: string | null;
  dateTo?: string | null;
  purposeCode?: number[];
  totalAppliedFilters?: number;
}

export interface AccountSpecificationsModel {
  borrowingRatePct: string;
  connectedPortfolios: string;
  existingCard: string;
  initialDate: string;
  interestRatePct: string;
  transferLimit: string;
  maturity: string;
  maturityStartDate: string;
  maturityEndDate: string;
  timeDepositAccountProlongation?: boolean;
  additions: ReadonlyMap<string, string>;
}

export const AccountSpecificationsModelMock = {
  borrowingRatePct: '0',
  connectedPortfolios: '5613800',
  existingCard: 'No',
  initialDate: '2008-12-12',
  interestRatePct: '0.5',
  transferLimit: '10000',
  maturity: '',
  additions: new Map<string, string>(),
};

export interface AccountUserProlongation {
  accountId: string;
  userProlongation: boolean;
}

export interface AccountPositionsModel {
  accountId: string;
  accruedInterest: PriceModel;
  assetClassCode: string;
  additions: AccountPositionsAdditionModel;
  costPrice: PriceModel;
  exchangeRate: string;
  id: string;
  instrumentCode: string;
  instrumentCurrency: string;
  instrumentName: string;
  price: PriceModel;
  quantity: string;
  valuation: PriceModel;
}

export interface PendingPaymentsModel {
  amount: number;
  ccyIsoCode: string;
  counterPartyAccountNumber: string;
  counterPartyBic: string;
  counterPartyName: string;
  description: string;
  externalId: string;
}

export interface PriceModel {
  amount: string;
  currencyCode: string;
}

export interface PaymentSortCodeModel {
  sortCodeIban?: string;
  sortCodeBank?: string;
  sortCodeBic?: string;
}

export interface AccountPositionsAdditionModel {
  coupon: string;
  currency: string;
  currentFxRate: string;
  currentPrice: string;
  plTotalAbsAmount: string;
  plTotalAbsCurrency: string;
  plTotalPct: number;
  purchaseFxRate: string;
  purchasePrice: string;
  quantity: string;
  wkn: string;
  maturity: string;
}

export interface PaymentAccountsModel {
  additions: {
    transferLimit: any;
    transferLimitCcy: string;
  };
  IBAN: string;
  accountOpeningDate: string;
  alias: string;
  balanceLimit: string;
  crossCurrencyAllowed: true;
  currency: string;
  currentBalance: string;
  externalTransferAllowed: true;
  id: string;
  lastUpdateDate: string;
  name: string;
  productKindName: string;
  productNumber: string;
  productTypeName: string;
  visible: true;
}

export interface PurposeCodesModel {
  id: string;
  description: string;
}

export interface TransactionsActionParams {
  documentId?: string;
  documentState?: string;
  transactionId?: any;
  paymentOrderId?: any;
}

export enum PrintStatusType {
  PRINT_STATE_PENDING = 'PENDING',
  PRINT_STATE_PROCESSED = 'PROCESSED',
}

export enum PRODUCT_SUMMARY_TYPE_ORDER {
  aggregatedBalance = 0,
  cashAccounts = 1,
  timeDepositAccounts = 2,
  securitiesAccounts = 3,
  savingsAccounts = 4,
  creditAccounts = 5,
  others = 6,
}
