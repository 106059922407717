import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { KEY_USER_LOGGED_IN } from '@core/constants/keys';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  userLoggedIn: any = new BehaviorSubject<boolean>(false);

  constructor() {
    this.userLoggedIn.next(sessionStorage.getItem(KEY_USER_LOGGED_IN));
  }

  setUserLoggedIn(userLoggedIn: boolean): void {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getUserLoggedInNow(): boolean {
    return this.userLoggedIn;
  }
}
