import { NgModule } from '@angular/core';
import { MarketingCampaignComponent } from '@modules/marketing-campaign/marketing-campaign.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [MarketingCampaignComponent],
  imports: [SharedModule],
  exports: [MarketingCampaignComponent],
})
export class MarketingCampaignModule {}
