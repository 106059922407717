import { PortfolioSpecificationsDto } from '@models/specification.model';
import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;

  constructor(public payload: any) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public payload: PortfolioSpecificationsDto) {}
}
